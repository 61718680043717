/* eslint-disable */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import {
    BuyinPopup,
    CashTablePlayer,
    User,
    cashTableActions,
    convertCentToEuro,
    formatMoney,
    selectActiveTableId,
    selectBalance,
    selectBuyinPopup,
    selectCashTablePlayer,
    selectCurrentUser,
    CashTableGameRound,
    selectCashTableRound,
    RoundState,
    GameHelper,
    CashTable,
    selectCashTable,
    selectCashTableForceLeave,
    IForceLeaveData,
    IFRAME_MSG_TYPE
} from '@quanticogames/gameclient-core';
import { NumericFormatCustom } from '@quanticogames/gameclient-components';
import {
    StyledBackDrop,
    StyledBalance,
    StyledButton,
    StyledContent,
    StyledSlider,
    StyledStack,
    StyledTextField,
    StyledTitle,
    StyledValueBtn,
    StyledWarning,
} from './styled';
import ModalComponent from 'shared/components/modal';
import { IFRAME_GAME_ID } from 'constants/common';

const ModalJoinTable: React.FC = () => {
    const dispatch = useDispatch();
    const balance: number = useSelector(selectBalance);
    const tableId: number = useSelector(selectActiveTableId);
    const activeTable: CashTable = useSelector(selectCashTable);
    const round: CashTableGameRound = useSelector(selectCashTableRound(tableId));
    const buyinPopup: BuyinPopup = useSelector(selectBuyinPopup);
    const currentUser: User = useSelector(selectCurrentUser);
    const { open, seatId, loading } = buyinPopup;
    const { t } = useTranslation();
    const [value, setValue] = useState<number>(0);
    const player: CashTablePlayer = useSelector(selectCashTablePlayer(currentUser.id, tableId));
    const forceLeave: IForceLeaveData = useSelector(selectCashTableForceLeave(tableId));
    const timeOut = useRef(null);
    const isMobile = GameHelper.isMobile();

    const insufficientBalance = useMemo(
        () => balance < (activeTable?.minStake || 0),
        [balance, activeTable]
    );

    const maxStake = useMemo(
        () => Math.min(balance, activeTable?.maxStake || 0),
        [balance, activeTable]
    );

    const disableBuyin = useMemo(
        () => insufficientBalance || (value > balance),
        [insufficientBalance]
    );

    useEffect(() => {
        if (open) setValue(activeTable?.minStake || 0);
    }, [open]);

    useEffect(() => {
        if (value === 0) setValue(activeTable?.minStake || 0);
    }, [activeTable]);

    useEffect(() => {
        if (player && activeTable?.invitation > player?.balance && round?.state === RoundState.waiting) {
            timeOut.current = setTimeout(() => {
                dispatch(cashTableActions.setBuyinPopup({
                    open: true,
                    seatId: seatId,
                    rebuy: true,
                }));
            }, 1200);
        };
        return () => {
            timeOut.current && clearTimeout(timeOut.current);
        };
    }, [activeTable, player, round]);

    const onCloseModal = (_, reason = '') => {
        if (reason === 'backdropClick') return;
        if (buyinPopup.rebuy) {
            dispatch(cashTableActions.leaveTable({
                tableId,
                playerId: currentUser?.id,
            }));
            const frame = document.querySelector<HTMLObjectElement>(`#${IFRAME_GAME_ID}`);
            if (frame) frame.contentWindow.postMessage({ type: IFRAME_MSG_TYPE.FORCE_LEAVE_CASH_TABLE }, '*');
        };
        dispatch(cashTableActions.setBuyinPopup({
            open: false,
            seatId: null,
            loading: false,
            rebuy: false,
        }));
    };

    const handleSubmit = () => {
        if (buyinPopup.rebuy) return handleRebuy();
        return handleBuyIn();
    };

    const handleBuyIn = () => {
        dispatch(cashTableActions.joinTable({
            tableId,
            seatId,
            initialStake: value,
        }));
    };

    const handleRebuy = () => {
        dispatch(cashTableActions.rebuy({
            tableId,
            amount: value,
        }));
    };

    useEffect(() => {
        if (forceLeave?.ids?.length && forceLeave?.ids?.includes(currentUser?.id)) {
            dispatch(cashTableActions.setBuyinPopup({
                open: false,
                seatId: null,
                loading: false,
                rebuy: false,
            }));
        }
    }, [forceLeave]);

    return (
        <>
            <ModalComponent
                fullScreen={false}
                open={open}
                onClose={onCloseModal}
                title={t('Buy-in')}
                size='medium'
                className={`cash-join-modal ${isMobile ? 'mobile' : ''}`}
                sx={{ '&.mobile .modal-main': { padding: '8px' } }}
            >
                <StyledContent className='content-main-modal'>
                    {loading && (
                        <StyledBackDrop>
                            <img
                                src='/images/common/loading_circle.svg'
                                className='loader w-[50px]'
                            />
                        </StyledBackDrop>
                    )}
                    <p className='balance-label'>{t('Your current balance')}</p>
                    <StyledBalance children={`€ ${formatMoney(convertCentToEuro(balance))}`} />
                    <StyledTitle>{t('How much do you want to bring to the table?')}</StyledTitle>
                    {insufficientBalance ? (
                        <StyledWarning children={t('Insufficient Balance')} />
                    ) : (
                        <>
                            <StyledStack direction={'row'} gap={isMobile ? '4px' : '7px'}>
                                <div className='stake-range-label'>
                                    {t('Min')}
                                    <span>{`(€ ${formatMoney(convertCentToEuro(activeTable?.minStake || 0))})`}</span>
                                </div>
                                <Box className='flex-1 flex justify-content-center'>
                                    <StyledSlider
                                        sx={{ height: '20px', color: '#3F51B5' }}
                                        defaultValue={activeTable?.minStake || 0}
                                        value={value}
                                        min={activeTable?.minStake || 0}
                                        max={maxStake}
                                        onChange={(_, val) => setValue(val as number)}
                                    />
                                </Box>
                                <div className='stake-range-label'>
                                    {t('Max')}
                                    <span>{`(€ ${formatMoney(convertCentToEuro(maxStake))})`}</span>
                                </div>
                            </StyledStack>
                            <StyledStack direction={'row'} gap={'7px'} justifyContent={'center'}>
                                <span>{t('Buy in')}: €</span>
                                <StyledTextField
                                    variant='outlined'
                                    value={value}
                                    error={value > balance}
                                    onChange={
                                        (e: React.ChangeEvent<HTMLInputElement>) => setValue(Number(e?.target?.value))
                                    }
                                    InputProps={{
                                        inputComponent: NumericFormatCustom as any,
                                        inputProps: {
                                            min: activeTable?.minStake || 0,
                                            max: maxStake,
                                            'delayTime': 1000,
                                        },
                                    }}
                                />
                            </StyledStack>
                        </>
                    )}
                </StyledContent>

                <Stack gap={'10px'} direction={'row'} alignItems="center" width="100%" justifyContent={'center'} className='btn-section'>
                    <StyledButton
                        color='error'
                        title={t('Cancel')}
                        onClick={onCloseModal}
                        disabled={loading}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '120px !important' }}
                    />
                    <StyledButton
                        title={t('Subscribe')}
                        onClick={handleSubmit}
                        disabled={disableBuyin || loading}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '120px !important' }}
                    />

                </Stack>
            </ModalComponent>
        </>
    );
};

export default ModalJoinTable;
