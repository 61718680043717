import { getBadges } from "constants/badges";

const BadgesTournament = ({data}) => {
    const BADGES_COLLECTION = getBadges();
    return (
        <div className="badge-list">
            {data?.cost === 0 && <>{BADGES_COLLECTION['Free']?.icon}</>}
            {data?.structure === 'MT' ? (
            <div className="badge-icon">{`${data?.maxSeats}P`}</div>
            ) : (
            <>{BADGES_COLLECTION[data?.matchType]?.icon}</>
            )}
            {data?.allowToken && <>{BADGES_COLLECTION['Token_Icon']?.icon}</>}
            {BADGES_COLLECTION[data?.type]?.icon}
            {(data?.isGuaranteeSatellite || data?.parentGuaranteeCode) && BADGES_COLLECTION['Satellite']?.icon}
        </div>
    )
}
export default BadgesTournament