


import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, IconButton } from "@mui/material";
import Timeout from './timeout';
import { clubApi, selectActiveClub, selectBalance, selectCasino, selectCurrentUser, SKIN, User } from '@quanticogames/gameclient-core';
import IconLoading from 'assets/icons/Loading';
import { useSelector } from 'react-redux';
import { positionFactory } from 'pages/club/components/helpers';
import ModalClubBalance from 'pages/club/components/modal/club-balance';

interface JoinInviteProps {
    isOwner: boolean;
    onCancelInvite: () => void
}

interface SitItem {
    userName: string;
    avatar: string;
    state: number;
    userId: number;
}

const JoinTable = (props: JoinInviteProps) => {
    const { isOwner, onCancelInvite } = props;
    const activeClub: any = useSelector(selectActiveClub)
    const { t } = useTranslation('club');
    const [sits, setSits] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [ownerInfo, setOwnerInfo] = useState<SitItem | null>(null);
    const [positions, setPositions] = useState<any[]>([]);
    const skinType: string = useSelector(selectCasino) || 'quantico';
    const [displayButtonInvite, setDisplayButtonInvite] = useState<boolean>(true);
    const game4in2 = activeClub?.clubSetting?.matchType === '4in2'
    const [playerJoined, setPlayerJoined] = useState<boolean>(true);
    const currentUser: User = useSelector(selectCurrentUser);
    const balance = useSelector(selectBalance);
    const [openBalanceWarning, setOpenBalanceWarning] = useState<boolean>(false);

    useEffect(() => {
        if (activeClub) {
            const currentSeats = activeClub?.clubSetting?.maxSeats ?? 2;
            const createSit = new Array(currentSeats - 1).fill(null);
            if (activeClub?.clubSetting?.clubMembers) {
                activeClub?.clubSetting?.clubMembers.forEach(item => {
                    if (!item.isOwner) {
                        createSit[item.seatId - 1] = {
                            userName: item.userName,
                            avatar: item.avatar,
                            state: 1,
                            userId: item.userId
                        }
                    }
                });
            }
            if (game4in2) {
                setSits([...createSit, ...createSit]);
                if (activeClub?.clubSetting?.clubMembers.length > 1) {
                    setPlayerJoined(true);
                } else {
                    setPlayerJoined(false);
                }
            } else {
                
                setSits(createSit);
            }
            
            setPositions(positionFactory(createSit.length + 1));
            const owner = activeClub?.clubSetting?.clubMembers?.find(item => item.isOwner);
            const noOwnerList = activeClub?.clubSetting?.clubMembers?.filter(item => !item.isOwner);
            if (owner) {
                setOwnerInfo({
                    userName: owner.userName,
                    avatar: owner.avatar,
                    userId: owner.userId,
                    state: 1
                })
            }
            if (noOwnerList && noOwnerList.length > 0) {
                const checkPlayerCurrentInvite = noOwnerList.find(user => Number(user.status) !== Number(1) && user.userId === currentUser?.id);
                if (checkPlayerCurrentInvite) {
                    setDisplayButtonInvite(true);
                } else {
                    setDisplayButtonInvite(false);
                }
            }
        }
    }, [activeClub]);

    const handleJoinTable = async (seatId: number) => {
        if (balance < activeClub?.clubSetting?.cost) {
            setOpenBalanceWarning(true);
            return;
        }
        try {
            setLoading(true);
            await clubApi.acceptInvite({
                clubId: activeClub.id,
                seatId: seatId
            });
            setLoading(false);
            setDisplayButtonInvite(false);
        }
        catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    return (
        <div className='join-table-container'>
            {activeClub?.status !== 3 &&
                <p className='title'>
                    {isOwner ? t('3. Waiting for the invited players') : t('Select to join')}
                </p>}
            {activeClub?.status === 3 &&
                <p className='title'>
                    {t('club in progress')}
                </p>}
            <div className='join-table'>
                <div className='table-relative'>
                    {loading && <div className='loading'>
                        <IconLoading />
                    </div>}
                    <img src={`/images/club/table${game4in2 ? '-4in2' : ''}.png`} className='table-image' />
                    <div className="table-logo">
                        <img
                            src={`/images/logo/skin/logo-${SKIN[skinType]?.logoTable ?? SKIN[skinType]?.logoHeader}.webp`}
                            className='!h-[30px]'
                            style={{ filter: "brightness(0) invert(1)" }}
                        />
                    </div>
                    <div className="player player-me">
                        <div className="club-player-group">
                            <Avatar
                                className={`player-avatar`}
                                alt={'You'}
                                src={`/images/avatar/${ownerInfo?.avatar}`}
                               
                            />
                            <p className='player-name player-name-bottom'>{isOwner ? t('You') : ownerInfo?.userName}</p>
                        </div>
                    </div>
                    {game4in2 && (
                        <div className="player player-me-1">
                            <div className="club-player-group">
                                <p className='player-name player-name-top'>{isOwner ? t('You') : ownerInfo?.userName}</p>
                                <Avatar
                                    className={`player-avatar`}
                                    alt={'You'}
                                    src={`/images/avatar/${ownerInfo?.avatar}`}
                                />
                            </div>
                        </div>
                    )}
                    {sits.map((item, index) => {
                        const position = positions?.[index];
                        return (
                            <div key={index} className={`player ${game4in2 ? `player-empty-${index}` : ''}`} style={game4in2 ? null : positions?.[index]}>
                                {item?.state === 1 &&
                                    <div className="club-player-group club-other-sit">
                                        {!position?.nameBottom && <p className='player-name player-name-top'>{item?.userName}</p>}
                                        <Avatar
                                            className={`player-avatar `}
                                            alt={item.userName}
                                            src={`/images/avatar/${item.avatar}`}
                                            
                                        />
                                        {position?.nameBottom && <p className='player-name player-name-bottom'>{item?.userName}</p>}
                                    </div>}
                                {(!item && playerJoined) &&
                                    <>
                                        <IconButton
                                            className='invite-button'
                                            disabled={isOwner}
                                            onClick={() => { handleJoinTable(index + 1) }}>
                                            <span className='w-full h-full rounded-full bg-[#357966]' />
                                        </IconButton>

                                    </>
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
            {activeClub?.status !== 3 &&
                <>
                    <Timeout phase='join' isOwner={isOwner} />
                    {(activeClub?.id && displayButtonInvite) && (
                        <Button onClick={onCancelInvite} className="button-prev club-cancel-invite-btn">{t('Decline invitation')}</Button>
                    )}
                </>
            }
            <ModalClubBalance open={openBalanceWarning} onClose={() => setOpenBalanceWarning(false)} />
        </div>
    );
}

export default React.memo(JoinTable);