import { Container } from '@mui/material';
import { PhoneIcon } from 'assets/icons/PhoneIcon';
import { LocationIcon } from 'assets/icons/LocationIcon';
import { BuildingIcon } from 'assets/icons/BuildingIcon.';
import { SKIN } from 'constants/skin';
import { useSelector } from 'react-redux';
import { selectCasino, selectSessionInfo } from '@quanticogames/gameclient-core';

const FooterMenu = () => {
  const skinType: string = (useSelector(selectCasino) as string) || 'quantico';
  const sessionInfo = useSelector(selectSessionInfo) as any;

  return (
    <Container>
      <div className="footer-main">
        <img src={`/images/logo/skin/logo-${SKIN[skinType].logoFooter}.webp`} />

        <div className="footer-content">
          <div className="footer-content-top">
            <div className="footer-content-item">
              <BuildingIcon />
              {sessionInfo?.companyName ? sessionInfo?.companyName : SKIN[skinType].footerContent.company}
            </div>

            <div className="footer-content-item">
              <LocationIcon />
              {sessionInfo?.address ? sessionInfo?.address : SKIN[skinType].footerContent.location}
            </div>

            <div className="footer-content-item">
              <PhoneIcon />
              {sessionInfo?.gad ? sessionInfo?.gad : SKIN[skinType].footerContent.phone}
            </div>
          </div>

          <p>{SKIN[skinType].footerContent.text}</p>
        </div>

        <span className="footer-lable">18+</span>
      </div>
    </Container>
  );
};
export default FooterMenu;
