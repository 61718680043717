
import { SessionInfo, UserSettings, isPopup, selectConfigState, selectSessionInfo } from "@quanticogames/gameclient-core";
import { isAlreadyTourKey } from "constants/localStorage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ModalComponent from "shared/components/modal";

const ModalLockChat = () => {
    const { t } = useTranslation('common');
    const [isOpenModalLockChat, setIsOpenModalLockChat] = useState<boolean>(false);
    const isAlreadyTour = localStorage.getItem(isAlreadyTourKey) === 'true'; 
    const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;
    const userSettings: UserSettings = useSelector(selectConfigState)
    useEffect(() => {
        if (!sessionInfo || (userSettings?.showTutorial && !isAlreadyTour) || isPopup) {
            return
        }
        setIsOpenModalLockChat(sessionInfo?.isBannedChat ?? false);
    }, [sessionInfo?.isBannedChat, userSettings.showTutorial, isPopup]);
    
    const handleClose = () => { 
        setIsOpenModalLockChat(false);
    }
    return (
        <ModalComponent 
            open={isOpenModalLockChat} 
            onClose={handleClose} 
            className="modal-club-canceled"
            size='small'
            title={t("Notification banner")}
            textOk={t('OK')}
            onOk={handleClose}
            showFooter={true}
            showIconClose={false}
            
    >
            <p className='font-medium'>{t("You are banned from chat")}</p>
        </ModalComponent>
    )
}
export default ModalLockChat
