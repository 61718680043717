
import { darkMode, Sound, SoundManager } from "@quanticogames/gameclient-core";
import { useSelector } from "react-redux";
import { Toggle } from 'shared/components/toggle';
import { FormControlLabel } from '@mui/material';
import { COLORS } from "constants/colors";
import { useTranslation } from "react-i18next";

export const CustomToggle = (props) => {
    return (
        <Toggle
 
            {...props}
        />
    )
}

export const HideFullTableToggle = (props) => {
    const { t } = useTranslation();
    const { isHideFullTable, setIsHideFullTable } = props;

    const isDarkMode: boolean = useSelector(darkMode);

    const filterFullToggle = (e) => {
        SoundManager.play(Sound.clickButton);
        const checked = e.target.checked;
        setIsHideFullTable(checked);
    };
    return (
        <FormControlLabel
            control={<CustomToggle checked={isHideFullTable} />}
            label={t('Hide full tables')}
            labelPlacement="start"
            sx={{
                color: isDarkMode ? COLORS.white : COLORS.base_dark,
                marginLeft: 0,
                marginRight: 0,
                '& .MuiFormControlLabel-label': {
                    fontSize: '20px',
                    fontWeight: 600,
                    flex: 1,
                    color: "#06F",
                    whiteSpace: 'nowrap',
                    marginRight: 5,
                },
                '.Mui-checked+.MuiSwitch-track': {
                    opacity: '1 !important',
                }
            }}
            onChange={filterFullToggle}
        />
    )
}