import { Box, List, ListItem, Stack, styled } from '@mui/material';
import { CashTable, Game, cashTableActions, selectActiveGame, selectCashTable } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import ButtonComponent from 'shared/components/button';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import BadgesCash from 'shared/Badges/badges-cash';

type TournamentInfoProps = {
  onClose?: () => void;
};

export const TableInfo: React.FC<TournamentInfoProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const table: CashTable = useSelector(selectCashTable);
  const game: Game = useSelector(selectActiveGame);
  const dispatch = useDispatch();
  const renderTitle = () => {
    return `${table?.title} - ${table?.code}`;
  };

  const StyledListItem = styled(ListItem)(() => ({
    padding: 0,
    height: 30,
    marginTop: 5
  }));

  const ListTitle = styled('p')(() => ({}));

  const ListValue = styled('p')(() => ({}));

  const handlePlay = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (!table || !game) return;
    dispatch(cashTableActions.openTable({
      id: table.id,
      gameCode: game.code,
    }));
  }, [table, game]);

  return (
    <div className='tournament-info'>
      <div className='tournament-info-title'>{renderTitle()}</div>
      <List>
        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{t("ID")}</ListTitle>
          <ListValue className="tournament-info-value">{table?.id}</ListValue>
        </StyledListItem>

        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{t("Minimum entry credit")}</ListTitle>
          <ListValue className="tournament-info-value">€ {formatMoney(convertCentToEuro(table?.minStake))}</ListValue>
        </StyledListItem>

        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{t("Rake")}</ListTitle>
          <ListValue className="tournament-info-value">{table?.rake * 100} %</ListValue>
        </StyledListItem>

        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{t("Capture")}</ListTitle>
          <ListValue className="tournament-info-value">€ {formatMoney(convertCentToEuro(table?.maxStake))}</ListValue>
        </StyledListItem>

        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{t("Max players")}</ListTitle>
          <ListValue className="tournament-info-value">{table?.numberOfSeats}</ListValue>
        </StyledListItem>

        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{t("Playing time")}</ListTitle>
          <ListValue className="tournament-info-value">30 {t('second')}</ListValue>
        </StyledListItem>

        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{t("State")}</ListTitle>
          <ListValue className="tournament-info-value">
            <Stack spacing={1} direction={'row'}>
              <BadgesCash data={table} />
            </Stack>
          </ListValue>
        </StyledListItem>
      </List>

      <Stack
        direction="row"
        spacing={3}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: '5px' }}
        className="tournament-info-btn"
      >
        <ButtonComponent
          title={t('Play')}
          size='large'
          rouned='large'
          fullWidth
          onClick={handlePlay}
        />
      </Stack>
    </div>
  );
};
