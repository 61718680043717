import React, { memo } from 'react';
import { FlexBox } from 'shared/Flex';
import { CountDown } from '@quanticogames/gameclient-components';
import UserGroup from 'assets/icons/UserGroup';
import { convertCentToEuro, formatMoney } from '@quanticogames/gameclient-core';
import { StateLabel } from 'pages/tournament/component/tournament-state';
import { TOURNAMENT_STATES_TITLES } from 'pages/tournament/component/tournament-label';
import { useTranslation } from 'react-i18next';
import { numberWithDecimals } from 'utils/formatCurrency';
import moment from 'moment';
import BadgesTournament from 'shared/Badges/badges-tournament';

interface TournamentPlayerProps {
  data: {
    registered: number;
    maxPlayers: number;
  };
};

interface TournamentFavoriteProps {
  data: {
    id: number;
  };
  handleActionFavorite: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  renderFavoriteIcon: (id: number) => React.ReactNode;
};

interface TournamentStartProps {
  data: {
    startingTime: string;
  };
  duration: number;
  t?: (key: string) => string;
};

interface TournamentCostProps {
  data: {
    cost: number;
  };
  t?: (key: string) => string;
};

interface TournamentStatusProps {
  data: {
    state: string;
  };
  t?: (key: string) => string;
};


interface TournamentLineProps {
  children: React.ReactNode;
};

interface TournamentBadgeProps {
  data: {
    cost: number;
    structure: string;
    matchType: number;
    type: string;
    allowToken: boolean;
    maxPlayers: number;
    isGuaranteeSatellite: boolean;
    parentGuaranteeCode: number;
    parentGuaranteeId: number;
    maxSeats?: number;
  };
};

export const TournamentPlayer: React.FC<TournamentPlayerProps> = memo(({ data }) => (
  <FlexBox alignItems="center" gap={5} className="tournament-mobile-player">
    <UserGroup />
    {`${data.registered}/${data.maxPlayers}`}
  </FlexBox>
));

export const TournamentFavorite: React.FC<TournamentFavoriteProps> = memo(({ data, handleActionFavorite, renderFavoriteIcon }) => (
  <div className="tournament-mobile-favorite" id={`tournament-favorite-${data?.id}`} onClick={(e) => handleActionFavorite(e)}>
    {renderFavoriteIcon(data.id)}
  </div>
));

export const TournamentStart: React.FC<TournamentStartProps> = memo(({ data, duration }) => {
  const { t } = useTranslation();
  const currentTime = moment.utc();
  const startingTime = moment.utc(data?.startingTime).local();
  const durationWithMs = startingTime.diff(currentTime, 'milliseconds') / 1000;
  return (
    <div className='h-[19px]'>
      {data.startingTime && duration > 0 && (
        <p className="tournament-des-time">
          {duration > 86400 ? t('Start at') : t('Start in')}
          <span className='ml-[5px]'>
            {duration > 86400 ? (
              <span>{startingTime.format('DD/MM/YYYY HH:mm')}</span>
            ) : (
              <CountDown second={durationWithMs} format={durationWithMs > 3600 ? 'hh:mm:ss' : 'mm:ss'} />
            )}
          </span>
        </p>
      )}
    </div>
  )
});

export const TournamentCost: React.FC<TournamentCostProps> = memo(({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="tournament-mobile-cost">
      <span className="label">{t('Cost')}: </span>
      {data.cost === 0 ? t('Free') : `€ ${formatMoney(convertCentToEuro(data.cost))}`}
    </div>
  )
});

export const TournamentStatus: React.FC<TournamentStatusProps> = memo(({ data }) => {
  const { t } = useTranslation();
  return (
    <div style={{
      height: 24,
    }}>
      <StateLabel
        color={TOURNAMENT_STATES_TITLES[data?.state]?.color}
        background={TOURNAMENT_STATES_TITLES[data?.state]?.background}
      >
        {t(TOURNAMENT_STATES_TITLES[data?.state]?.title)}
      </StateLabel>
    </div>
  )
});

export const TournamentLine: React.FC<TournamentLineProps> = memo(({ children }) => (
  <FlexBox
    alignItems="center"
    width="100%"
    gap={10}
    justifyContent="space-between"
    className="tournament-mobile-line"
  >
    {children}
  </FlexBox>
));

export const TournamentBadge: React.FC<TournamentBadgeProps> = memo(({ data }) => {
  return (
    <div className="tournament-mobile-badge">
      <BadgesTournament data={data} />
    </div>
  );
});

export const TournamentPrizeUpTo: React.FC<any> = memo(({ cost }) => {
  const { t } = useTranslation();
  const winUpPrize = cost * 50000;
  return (
    <div className="tournament-mobile-prize">
      <span className="label">{t('Win up to')} </span>
      €{numberWithDecimals(convertCentToEuro(winUpPrize), 0)}
    </div>
  )
});