import { getBadges } from "constants/badges";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";

const BadgesCash = ({data}) => {
    const BADGES_COLLECTION = getBadges();
    const { t } = useTranslation();
     
    return (
      <div className="badge-list">
        {BADGES_COLLECTION['Cash_Table']?.icon}
        <Tooltip title={`${data?.numberOfSeats} ${t("max seats")}`} placement="top" arrow>
          <div className="badge-icon">{`${data?.numberOfSeats}P`}</div>
        </Tooltip>
    </div>
    )
}
export default BadgesCash