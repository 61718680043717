export const SKIN = new Proxy({
    quantico: {
        backgroundTop: '#0E345E',
        logoHeader: 'quantico',
        logoFooter: 'quantico',
        title: 'Quantico Games Client',
        borderColor: 'rgba(255,255,255, 0.2)',
        backgroundContent: '#0E345E',
        backgroundButton: '#0E345E',
        colorButton: '#fff',
        borderButton: '#fff',
        backgroundToggle: '#DCDCDC',
        colorNameUser: '#fff',
        colorIconBook: 'rgba(255, 255, 255, 0.4);',
        colorIconPlus: '#24DEDB',
        backgroundFooter: '#0E345E',
        footerContent: {
            company: 'Quantico Games S.r.l',
            location: 'Via Silvio Pellico 24, 00195 Roma',
            phone: 'Conc. GAD: 00000',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    snai: {
        backgroundTop: '#333F48',
        logoHeader: 'snai',
        logoFooter: 'snai',
        borderColor: 'rgba(255,255,255, 0.2)',
        backgroundContent: '#333F48',
        backgroundButton: '#fff',
        colorButton: '#333F48',
        borderButton: '#144B46',
        backgroundToggle: '#fff',
        colorNameUser: '#fff',
        colorIconBook: 'rgba(255, 255, 255, 0.4);',
        colorIconPlus: '#fff',
        title: 'Snaitech Client',
        widthImageFooter: 'w-[90px] !lg:w-[103px]',
        backgroundFooter: '#333F48',
        footerContent: {
            company: 'Snaitech S.p.A.',
            location: 'Piazza della Repubblica 32, 20124 Milano, Italia',
            phone: 'Concession n. 15215',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    sisal: {
        backgroundTop: '#fff',
        logoHeader: 'sisal-1',
        logoFooter: 'sisal-2',
        title: 'Sisal Client',
        colorName: '#00452B',
        borderColor: 'rgba(0, 69, 43, 0.5)',
        backgroundContent: '#005f3b',
        backgroundButton: '#005f3b',
        // borderButtonFilter: '#fff',
        colorIconButtonFilter: '#fff',
        // borderButtonSearch: '#fff',
        colorButton: '#fff',
        borderButton: '#fff',
        backgroundToggle: '#fff',
        colorNameUser: '#00452B',
        colorIconBook: '#00452B',
        colorIconPlus: '#00452B',
        colorIconDropdownArrow: '#00452B',
        widthImageFooter: '!w-[100px]',
        backgroundFooter: '#005f3b',
        footerContent: {
            company: 'Sisal Italia S.p.A',
            location: 'Via Ugo Bassi, 6 - 20159 Milano',
            phone: 'Conc. GAD: 00000',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    quinel: {
        backgroundTop: '#0E345E',
        logoHeader: 'quantico',
        logoFooter: 'quantico',
        title: 'Quinel Games Client',
        borderColor: 'rgba(255,255,255, 0.2)',
        backgroundContent: '#0E345E',
        backgroundButton: '#0E345E',
        colorButton: '#fff',
        borderButton: '#fff',
        backgroundToggle: '#DCDCDC',
        colorNameUser: '#fff',
        colorIconBook: 'rgba(255, 255, 255, 0.4);',
        colorIconPlus: '#24DEDB',
        backgroundFooter: '#0E345E',
        footerContent: {
            company: 'Quinel Games S.r.l',
            location: 'Via Silvio Pellico 24, 00195 Roma',
            phone: 'Conc. GAD: 00000',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    sks365: {
        backgroundTop: '#22262B',
        logoHeader: 'sks365',
        logoFooter: 'sks365',
        title: 'SKS365 Games Client',
        borderColor: 'rgba(255,255,255, 0.2)',
        backgroundContent: '#22262B',
        backgroundButton: '#746337 ',
        colorButton: '#F7B200',
        borderButton: '#F7B200',
        backgroundToggle: '#F7B200',
        backgroundToggleDown: '#746337',
        colorNameUser: '#fff',
        colorIconBook: 'rgba(255, 255, 255, 0.4);',
        colorIconPlus: '#F0AD02',
        widthImageFooter: '!w-[113px]',
        backgroundFooter: '#22262B',
        footerContent: {
            company: 'SKS365 Malta LTD',
            location: "Viale dell'Arte, 25, 00144, Roma",
            phone: 'Conc. GAD: 00000',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    eplay24: {
        backgroundTop: '#272727',
        logoHeader: 'eplay24',
        logoFooter: 'eplay24',
        title: 'EPLAY24 Games Client',
        borderColor: 'rgba(255,255,255, 0.2)',
        backgroundContent: '#272727',
        backgroundButton: '#DC0009',
        colorButton: '#fff',
        borderButton: '#DC0009',
        backgroundToggle: '#DC0009',
        backgroundToggleDown: 'rgba(255,255,255,.8)',
        colorNameUser: '#fff',
        colorIconBook: 'rgba(255, 255, 255, 0.4);',
        colorIconPlus: '#DC0009',
        widthImageFooter: '!w-[138.345px]',
        logoTable: 'eplay24-table',
        backgroundFooter: '#272727',
        footerContent: {
            company: 'E-PLAY24 ITA LTD ',
            location: "via Croce Rossa, 25 82100 Benevento (BN)",
            phone: 'Conc. GAD: 15232',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    betpoint: {
        backgroundTop: '#fff',
        logoHeader: 'betpoint',
        logoFooter: 'betpoint-footer',
        title: 'Betpoint Games Client',
        borderColor: 'rgba(86,88,91, 0.3)',
        backgroundContent: '#F18903',
        backgroundButton: '#fff',
        colorButton: '#F18903',
        borderButton: '#fff',
        borderButtonFilter: '#fff',
        colorIconButtonFilter: '#fff',
        backgroundButtonSearch: '#fff',
        borderButtonSearch: '#fff',
        backgroundToggle: '#fff',
        colorIconSearch: '#56585B',
        textSearch: '#56585B',
        backgroundToggleDown: 'rgba(255,255,255,0.6)',
        colorNameUser: '#56585B',
        colorName: '#56585B',
        colorIconBook: '#56585B',
        colorIconPlus: '#56585B',
        colorIconDropdownArrow: '#56585B',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: '!lg:w-[208.67px] w-[170px]',
        backgroundFooter: '#222',
        footerContent: {
            company: 'Betpoint s.r.l',
            location: "Via Roberto da Bari, 30 - 70122 Bari (BA)",
            phone: 'Conc. GAD: 12345',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    newgioco: {
        backgroundTop: '#272630',
        logoHeader: 'newgioco-new',
        logoFooter: 'newgioco-new',
        title: 'Newgioco Games Client',
        borderColor: 'rgba(255,255,255, 0.12)',
        backgroundContent: '#272630',
        backgroundButton: '#FD6620',
        colorButton: '#fff',
        borderButton: '#515058',
        borderButtonFilter: '#515058',
        backgroundButtonFilter: '#32313B',
        colorIconButtonFilter: '#fff',
        backgroundButtonSearch: '#32313B',
        borderButtonSearch: '#515058',
        textSearch: '#fff',
        backgroundToggle: '#fff',
        backgroundToggleDown: '#fff',
        colorNameUser: '#fff',
        colorName: '#fff',
        colorIconBook: '#fff',
        colorIconPlus: '#fff',
        colorIconDropdownArrow: '#fff',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: 'max-w-[150px] w-[139px]',
        backgroundFooter: '#272630',
        footerContent: {
            company: 'Multigioco S.r.l.',
            location: "VIA XXIV Maggio 73, Grottaferrata, Roma",
            phone: 'Conc. GAD: 15133',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    betsson: {
        backgroundTop: '#fff',
        logoHeader: 'betsson',
        logoFooter: 'betsson',
        typeImages: 'png',
        title: 'Betsson Games Client',
        borderColor: 'rgba(255,101,255, 0.12)',
        backgroundContent: '#39ABBF',
        backgroundButton: '#fff',
        colorButton: '#39ABBF',
        borderButton: '#fff',
        borderButtonFilter: '#fff',
        // backgroundButtonFilter: '#fff',
        colorIconButtonFilter: '#fff',
        backgroundButtonSearch: '#fff',
        borderButtonSearch: '#fff',
        colorIconSearch: '#39ABBF',
        textSearch: '#39ABBF',
        colorFilter: '#fff',
        backgroundToggle: '#fff',
        backgroundToggleDown: '#fff',
        colorNameUser: '#39ABBF',
        colorName: '#39ABBF',
        colorIconBook: '#39ABBF',
        colorIconPlus: '#39ABBF',
        colorIconDropdownArrow: '#39ABBF',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: '!w-[114.66px] !lg:w-[114.66px]',
        backgroundFooter: '#fff',
        footerTextDesktop: '#39ABBF',
        footerTextMobile: '#39ABBF',
        footerContent: {
            company: 'Betsson - BML Group Ltd',
            location: "ix-Xatt Ta' Xbiex, XBX 1027, Ta' Xbiex, Malta",
            phone: 'GAD n. 15230',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    scommettendo: {
        backgroundTop: '#19384D',
        logoHeader: 'scommettendo',
        logoFooter: 'scommettendo',
        title: 'Scommettendo Games Client',
        borderColor: 'rgba(255,255,255, 0.12)',
        backgroundContent: '#19384D',
        backgroundButton: '#FCCE0D',
        colorButton: '#000',
        borderButton: '#515058',
        borderButtonFilter: '#fff',
        backgroundButtonFilter: '#fff',
        colorFilter: "#19384D",
        colorIconSearch: '#19384D',
        colorIconButtonFilter: '#19384D',
        backgroundButtonSearch: '#fff',
        borderButtonSearch: '#fff',
        textSearch: '#19384D',
        backgroundToggle: '#fff',
        backgroundToggleDown: '#fff',
        colorNameUser: '#fff',
        colorName: '#fff',
        colorIconBook: '#fff',
        colorIconPlus: '#fff',
        colorIconDropdownArrow: '#fff',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: 'max-w-[150px] w-[139px]',
        backgroundFooter: '#19384D',
        footerContent: {
            company: 'Scommettendo SRL P.I.',
            location: "Conc. n. 15125, Via Massimo D'Azeglio, 1 San Michele Salentino 72018 (BR)",
            phone: '02037070741',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        },
    },
    foursevenplay: {
        backgroundTop: '#000000',
        logoHeader: 'foursevenplay',
        logoFooter: 'foursevenplay',
        title: '47Play Games Client',
        borderColor: 'rgba(255,255,255, 0.12)',
        backgroundContent: '#000000',
        backgroundButton: '#E96725',
        colorButton: '#ffffff',
        borderButton: '#FD6620',
        borderButtonFilter: '#202020',
        backgroundButtonFilter: '#000000',
        colorFilter: "#ffffff",
        colorIconSearch: '#ffffff',
        colorIconButtonFilter: '#ffffff',
        backgroundButtonSearch: '#000000',
        borderButtonSearch: '#202020',
        textSearch: '#ffffff',
        backgroundToggle: '#fff',
        backgroundToggleDown: '#fff',
        colorNameUser: '#fff',
        colorName: '#fff',
        colorIconBook: '#fff',
        colorIconPlus: '#fff',
        colorIconDropdownArrow: '#fff',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: 'max-w-[55px] w-[55px]',
        backgroundFooter: '#000000',
        footerContent: {
            company: 'Fourseven Play Limited',
            location: "15, Level 3, Mannarino Road, BKR9080, Birkirkara, Malta",
            phone: 'Conc. GAD: 12345',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        },
    },
    bgame: {
        backgroundTop: '#000000',
        logoHeader: 'bgame',
        logoFooter: 'bgame',
        title: 'Bgame Games Client',
        borderColor: 'rgba(255,255,255, 0.12)',
        backgroundContent: '#000000',
        backgroundButton: '#DB6122',
        colorButton: '#ffffff',
        borderButton: '#DB6122',
        borderButtonFilter: '#202020',
        backgroundButtonFilter: '#000000',
        colorFilter: "#ffffff",
        colorIconSearch: '#ffffff',
        colorIconButtonFilter: '#ffffff',
        backgroundButtonSearch: '#000000',
        borderButtonSearch: '#202020',
        textSearch: '#ffffff',
        backgroundToggle: '#fff',
        backgroundToggleDown: '#fff',
        colorNameUser: '#fff',
        colorName: '#fff',
        colorIconBook: '#fff',
        colorIconPlus: '#fff',
        colorIconDropdownArrow: '#fff',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: 'max-w-[55px] w-[55px]',
        backgroundFooter: '#000000',
        footerContent: {
            company: 'Bgame S.r.l',
            location: "Castel San Pietro Terme (BO)",
            phone: 'Whatsapp: 3277351824',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        },
    },
    sanremo: {
        backgroundTop: '#fff',
        logoHeader: 'sanremo',
        logoFooter: 'sanremo',
        title: 'Casinò di Sanremo Games Client',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        backgroundContent: '#000000',
        backgroundButton: '#ffffff',
        colorButton: '#B29049',
        borderButton: '#ffffff',
        borderButtonFilter: '#202020',
        backgroundButtonFilter: '#000000',
        colorFilter: "#ffffff",
        colorIconSearch: '#ffffff',
        colorIconButtonFilter: '#ffffff',
        backgroundButtonSearch: '#000000',
        borderButtonSearch: '#202020',
        textSearch: '#ffffff',
        backgroundToggle: '#fff',
        backgroundToggleDown: '#fff',
        colorNameUser: '#000',
        colorName: '#000',
        colorIconBook: '#000',
        colorIconPlus: '#000',
        colorIconDropdownArrow: '#000',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: 'max-w-[150px] w-[150px]',
        backgroundFooter: '#000000',
        footerContent: {
            company: 'Casinò di Sanremo S.p.A',
            location: "Sanremo",
            phone: 'Conc. GAD: 12345',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        },
    },
    macaowin: {
        backgroundTop: '#FFFFFF',
        logoHeader: 'macaowin',
        logoFooter: 'macaowin',
        sizeImageHeader: 'max-w-[250px] w-[250px]',
        title: 'Macaowin Games Client',
        borderColor: 'rgba(255,255,255, 0.12)',
        backgroundContent: '#a3c63e',
        backgroundButton: '#FFFFFF',
        colorButton: '#a3c63e',
        borderButton: '#515058',
        borderButtonFilter: '#fff',
        backgroundButtonFilter: '#fff',
        colorFilter: "#a3c63e",
        colorIconSearch: '#a3c63e',
        colorIconButtonFilter: '#a3c63e',
        backgroundButtonSearch: '#fff',
        borderButtonSearch: '#fff',
        textSearch: '#a3c63e',
        backgroundToggle: '#fff',
        backgroundToggleDown: '#a3c63e',
        colorNameUser: '#a3c63e',
        colorName: '#a3c63e',
        colorIconBook: '#a3c63e',
        colorIconPlus: '#a3c63e',
        colorIconDropdownArrow: '#a3c63e',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: 'max-w-[150px] w-[139px]',
        backgroundFooter: '#FFFFFF',
        colorTextFooter: '#a3c63e',
        footerContent: {
            company: 'Macao Phygital S.r.l.',
            location: "VIA DEL CIRCO MASSIMO N.9 - ROME (RM) - CONCESSION N° 15405",
            phone: '16739011001',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        },
    },
    casinomania: {
        backgroundTop: '#333333',
        logoHeader: 'casinomania',
        logoFooter: 'casinomania',
        title: 'Casinomania Games Client',
        borderColor: 'rgba(255,255,255, 0.12)',
        backgroundContent: '#333333',
        backgroundButton: '#DB6122',
        colorButton: '#ffffff',
        borderButton: '#DB6122',
        borderButtonFilter: '#0b0b0b',
        backgroundButtonFilter: '#0b0b0b',
        colorFilter: "#ffffff",
        colorIconSearch: '#ffffff',
        colorIconButtonFilter: '#ffffff',
        backgroundButtonSearch: '#000000',
        borderButtonSearch: '#202020',
        textSearch: '#ffffff',
        backgroundToggle: '#fff',
        backgroundToggleDown: '#fff',
        colorNameUser: '#fff',
        colorName: '#fff',
        colorIconBook: '#fff',
        colorIconPlus: '#fff',
        colorIconDropdownArrow: '#fff',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: 'max-w-[350px] w-[55px]',
        backgroundFooter: '#333333',
        footerContent: {
            company: 'Bgame S.r.l',
            location: "Castel San Pietro Terme (BO)",
            phone: 'Whatsapp: 3277351824',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        },
    },
    stanleybet: {
        backgroundTop: '#3B3C43',
        logoHeader: 'stanleybet',
        logoFooter: 'stanleybet',
        title: 'Stanleybet Games Client',
        borderColor: 'rgba(255,255,255, 0.12)',
        backgroundContent: '#333333',
        backgroundButton: '#DB6122',
        colorButton: '#ffffff',
        borderButton: '#DB6122',
        borderButtonFilter: '#0b0b0b',
        backgroundButtonFilter: '#0b0b0b',
        colorFilter: "#ffffff",
        colorIconSearch: '#ffffff',
        colorIconButtonFilter: '#ffffff',
        backgroundButtonSearch: 'transparent',
        borderButtonSearch: '#fff',
        textSearch: '#fff',
        backgroundToggle: '#fff',
        backgroundToggleDown: '#222',
        colorNameUser: '#fff',
        colorName: '#fff',
        colorIconBook: '#fff',
        colorIconPlus: '#fff',
        colorIconDropdownArrow: '#fff',
        headerMenuBackground: 'rgba(0,0,0,.1)',
        widthImageFooter: 'max-w-[350px] w-[55px]',
        backgroundFooter: '#333333',
        footerContent: {
            company: 'Stanleybet Malta Limited',
            location: "Vista Corner, Triq Il-Ballut, Mosta, MST 4000 (Malta)",
            phone: 'Whatsapp: 15247',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        },
    },
    scommesseitalia: {
        backgroundTop: '#0E345E',
        logoHeader: 'quantico',
        logoFooter: 'quantico',
        title: 'Quantico Games Client',
        borderColor: 'rgba(255,255,255, 0.2)',
        backgroundContent: '#0E345E',
        backgroundButton: '#0E345E',
        colorButton: '#fff',
        borderButton: '#fff',
        backgroundToggle: '#DCDCDC',
        colorNameUser: '#fff',
        colorIconBook: 'rgba(255, 255, 255, 0.4);',
        colorIconPlus: '#24DEDB',
        backgroundFooter: '#0E345E',
        footerContent: {
            company: 'Quantico Games S.r.l',
            location: 'Via Silvio Pellico 24, 00195 Roma',
            phone: 'Conc. GAD: 00000',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
    macaostaryes: {
        backgroundTop: '#0E345E',
        logoHeader: 'quantico',
        logoFooter: 'quantico',
        title: 'Quantico Games Client',
        borderColor: 'rgba(255,255,255, 0.2)',
        backgroundContent: '#0E345E',
        backgroundButton: '#0E345E',
        colorButton: '#fff',
        borderButton: '#fff',
        backgroundToggle: '#DCDCDC',
        colorNameUser: '#fff',
        colorIconBook: 'rgba(255, 255, 255, 0.4);',
        colorIconPlus: '#24DEDB',
        backgroundFooter: '#0E345E',
        footerContent: {
            company: 'Quantico Games S.r.l',
            location: 'Via Silvio Pellico 24, 00195 Roma',
            phone: 'Conc. GAD: 00000',
            text: 'IL GIOCO É VIETATO AI MINORI E PUO CAUSARE DIPENDENZA PATOLOGICA',
        }
    },
}, {
    get(target, prop) {
        return target[prop] ?? target.quantico;
    }
});