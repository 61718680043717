import { Sound, SoundItem, SoundManager, BackgroundMusicManager } from '@quanticogames/gameclient-core';
import { ScopaSound } from 'games/scopa';
import { ItalianPokerSound } from 'games/italianPoker'
import { Scala40Sound } from 'games/scala40'
import { BurracoSound } from 'games/burraco'
import { BriscolaSound } from 'games/briscola'

const commonSounds: SoundItem[] = [
    { category: 'alerts', id: Sound.startSession, src: '../sounds/startSession.mp3' },
    { category: 'alerts', id: Sound.endRound, src: '../sounds/endRound.mp3' },
    { category: 'alerts', id: Sound.winRound, src: '../sounds/winRound.mp3' },
    { category: 'alerts', id: Sound.endSession, src: '../sounds/endSession.mp3' },
    { category: 'alerts', id: Sound.winSession, src: '../sounds/endSession.mp3' },

    { category: 'alerts', id: Sound.revenge, src: '../sounds/revenge.mp3' },
    { category: 'alerts', id: Sound.turn, src: '../sounds/startHand.mp3' },

    { category: 'alerts', id: Sound.clickButton, src: '../sounds/clickButton.mp3' },
    { category: 'alerts', id: Sound.clickSubscribe, src: '../sounds/clickSubscribe.mp3' },
    { category: 'alerts', id: Sound.clickUnsubscribe, src: '../sounds/clickSubscribe.mp3' },

    { category: 'game', id: Sound.cardAdjustment, src: '../sounds/cardAdjustment.mp3' },
    { category: 'game', id: Sound.cardCollect, src: '../sounds/cardCollect.mp3' },
    { category: 'game', id: Sound.cardDistribution, src: '../sounds/cardDistribution.mp3' },
    { category: 'game', id: Sound.cardPlay, src: '../sounds/cardPlayed.mp3' },
    { category: 'game', id: Sound.cardSelection, src: '../sounds/cardSelection.mp3' },
    { category: 'game', id: Sound.cardShuffle, src: '../sounds/cardShuffle.mp3' },

    { category: 'game', id: Sound.knock, src: '../sounds/effetto_busso.mp3' },
    { category: 'game', id: Sound.chipsCall, src: '../sounds/chips_call.mp3' },
    { category: 'game', id: Sound.chipsBet, src: '../sounds/chips_bet.mp3' },
    { category: 'game', id: Sound.chipsRaise, src: '../sounds/chips_raise.mp3' },
    { category: 'game', id: Sound.fold, src: '../sounds/card_flop.mp3' },
    { category: 'game', id: Sound.allin, src: '../sounds/chips_raccogli_piatto.mp3' },
    { category: 'game', id: Sound.chipPlace, src: '../sounds/chipPlace.mp3' },
    { category: 'game', id: Sound.chipMerge, src: '../sounds/chipMerge.mp3' },
    { category: 'game', id: Sound.chipCollect, src: '../sounds/chipCollect.mp3' },

    { category: 'alerts', id: Sound.timeBeep, src: '../sounds/time_beep.mp3' },
    { category: 'alerts', id: Sound.turn, src: '../sounds/startHand.mp3' },
    { category: 'alerts', id: Sound.notify, src: '../sounds/notify.mp3' },
    { category: 'alerts', id: Sound.announceChoose, src: '../sounds/announce_choose.mp3' },
    { category: 'alerts', id: Sound.timerAlert, src: '../sounds/alert1.mp3' },
    { category: 'alerts', id: Sound.timerAlert2, src: '../sounds/alert2.mp3' },
    { category: 'alerts', id: Sound.timerAlert3, src: '../sounds/timer_alert3.mp3' },
    { category: 'alerts', id: Sound.timerAlert4, src: '../sounds/timer_alert4.mp3' },
    { category: 'alerts', id: Sound.countdown, src: '../sounds/countdown.mp3' },
    { category: 'alerts', id: Sound.notification, src: '../sounds/notification.wav' },

    { category: 'alerts', id: Sound.soundColpoGrosso1, src: '../sounds/sound_1.mp3' },
    { category: 'alerts', id: Sound.soundColpoGrosso2, src: '../sounds/sound_2.mp3' },
    { category: 'alerts', id: Sound.soundColpoGrosso3, src: '../sounds/sound_3.mp3' },
];

// scopa sounds
const scopaSounds: SoundItem[] = [
    { category: 'alerts', id: ScopaSound.background, src: '../sounds/backgroundScopa.mp3', loop: true },
    { category: 'alerts', id: ScopaSound.winScopa, src: '../sounds/winScopa.mp3' },
    { category: 'alerts', id: ScopaSound.winSettebello, src: '../sounds/winSettebello.mp3' },
]

// italianPoker sounds
const italianPokerSounds: SoundItem[] = [
    { category: 'alerts', id: ItalianPokerSound.pokerCheck, src: '../sounds/pokerCheck.mp3' },
    { category: 'alerts', id: ItalianPokerSound.pokerParola, src: '../sounds/pokerParola.mp3' },
]

const burracoSounds: SoundItem[] = [
    { category: 'alerts', id: BurracoSound.burraco, src: '../sounds/burraco.mp3' },
    { category: 'alerts', id: BurracoSound.pozzetto, src: '../sounds/pozzetto.mp3' },
]

// Scala40 sounds
const scala40Sounds: SoundItem[] = [
    { category: 'alerts', id: Scala40Sound.scala40Arpo, src: '../sounds/scala40Arpo.mp3' },
    { category: 'alerts', id: Scala40Sound.scala40Chiudo, src: '../sounds/scala40Chiudo.mp3' },
]

// Briscola sounds 
const briscolaSound: SoundItem[] = [
    { category: 'alerts', id: BriscolaSound.briscolaBastoni, src: '../sounds/briscolaBastoni.mp3' },
    { category: 'alerts', id: BriscolaSound.briscolaCoppe, src: '../sounds/briscolaCoppe.mp3' },
    { category: 'alerts', id: BriscolaSound.briscolaDenara, src: '../sounds/briscolaDenara.mp3' },
    { category: 'alerts', id: BriscolaSound.briscolaSpade, src: '../sounds/briscolaSpade.mp3' },
]

const sounds =
    commonSounds
        .concat(scopaSounds)
        .concat(italianPokerSounds)
        .concat(scala40Sounds)
        .concat(burracoSounds)
        .concat(briscolaSound)

SoundManager.loadSounds(sounds);


BackgroundMusicManager.loadSounds([
    { category: 'alerts', id: Sound.backgroundLobby, src: '../sounds/backgroundLobby.mp3', loop: true },])