/* eslint-disable */
import {
  Game,
  Tournament,
  generateTournamentTitle,
  selectActiveGame,
  selectOrientation,
} from '@quanticogames/gameclient-core';
import LockIcon from 'assets/icons/Lock';
import { TOURNAMENT_TYPE } from 'constants/common';
import moment from 'moment';
import { TournamentBadge, TournamentCost, TournamentFavorite, TournamentLine, TournamentPlayer, TournamentPrizeUpTo, TournamentStart, TournamentStatus } from 'pages/tournament/component/list/tournament-cell-mobile';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FlexBox } from 'shared/Flex';
import ButtonComponent from 'shared/components/button'
import PinActiveIcon from 'assets/icons/PinActive';
import PinIcon from 'assets/icons/Pin';

type TournamentItemMobileProps = {
  index: number;
  data: Tournament | any;
  className?: string;
  isFake?: boolean;
  favorite?: {
    id: number;
    favoriteId: number;
  };
};

const TournamentItemMobile = ({ data, className, favorite }: TournamentItemMobileProps) => {
  const { t } = useTranslation();
  const game: Game = useSelector(selectActiveGame);
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const isScheduled = window.location.pathname.includes('tournaments');

  const renderTitle = () => {
    return generateTournamentTitle({
      game: game,
      ...data,
      template: 'standard',
      demo: true
    }, t);

    // const speed = data?.gameId == 9 && data?.maxPlayers == 2 ? 'Nibble' : getSpeedLabel(data?.speed);
    // const winObj = "DEMO";

    // const currentTime = moment.utc();
    // const startingTime = moment.utc(data.startingTime);
    // const duration = startingTime.diff(currentTime, 'seconds');

    // const showSeatCount = () => {
    //   if (isChipGame(game?.code)) {
    //     return `- ${data?.maxSeats} ${t('Positions')}`;
    //   } else return '';
    // }

    // if (data?.freeroll) {
    //   return `${t('Freeroll')} ${game?.name} ${t(speed)} ${winObj} ${getGameType(data?.type, t) ? `- ${getGameType(data?.type, t)}` : ''} ${data.startingTime && duration > 0 ? `${numberWithDecimals(convertCentToEuro(data?.rewardValue), 2)} EURO BONUS ${showSeatCount()}` : ''}`;
    // }
    // if (data?.guaranteedValue) {
    //   return `${game?.name} ${t(speed)} ${winObj} GTD ${numberWithDecimals(convertCentToEuro(data?.guaranteedValue), 2)} ${showSeatCount()}`;
    // }
    // return `${game?.name} ${t(speed)} ${winObj} ${getGameType(data?.type, t) ? `- ${getGameType(data?.type, t)}` : ''} ${data.startingTime && duration > 0 ? `${showSeatCount()}` : ''}`;
  };

  const currentTime = moment.utc();
  const startingTime = moment.utc(data.startingTime);
  const duration = startingTime.diff(currentTime, 'seconds');
  const isColpoGrosso = data?.type === TOURNAMENT_TYPE.CRAZY_PRIZE;

  const renderFavoriteIcon = useMemo(
    () => () => {
      return favorite?.id ? <PinActiveIcon /> : <PinIcon />
    },
    [favorite]
  );



  const TournamentDes = () => <p className={"tournament-des-title-schedule-mobile"}>{renderTitle()}</p>;
  return (
    <FlexBox
      alignItems={isMobileLandscape ? 'flex-start' : 'center'}
      className={`tournament-mobile-item ${isScheduled ? 'tournament-mobile-item-schedule' : ''} type-${data.type} ${className}`}
      onClick={() => { }}
    >
      <TournamentFavorite data={data} handleActionFavorite={() => { }} renderFavoriteIcon={renderFavoriteIcon} />
      <FlexBox
        gap={isMobileLandscape ? 15 : 3}
        flexDirection={isMobileLandscape ? 'row' : 'column'}
        alignItems={isMobileLandscape ? 'center' : 'flex-start'}
        width="100%"
        className={`tournament-mobile-content tournament-mobile-content-${data?.id} flex-1 ${isMobileLandscape ? 'tournament-mobile-content-landscape' : ''}`}
      >
        {isMobileLandscape ? (
          <div className='flex-1'>
            <div className='flex justify-between gap-[10px]'>
              <div className='flex-1'>
                <TournamentDes />
                <div className='flex gap-[10px] absolute bottom-0 w-full'>
                  <TournamentBadge data={data} />
                  <TournamentPlayer data={data} />
                  {isColpoGrosso && <TournamentPrizeUpTo cost={data?.cost} />}
                </div>
              </div>
              <div className='w-[130px]'>
                <TournamentCost data={data} />
                {isScheduled && <TournamentStart data={data} duration={duration} />}
              </div>
              <div className='w-[85px] self-center'>
                <TournamentStatus data={data} />
              </div>

              <div className='w-[120px]'>
                <div className="flex items-center">
                  <ButtonComponent
                    variant="outlined"
                    title={t('Play')}

                    rouned="large"
                    size="large"
                    color="primary"
                    fullWidth
                    className="lobby-button subscribe-button"
                    onClick={(e) => {
                      e.stopPropagation();

                    }}
                    startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '11px', bottom: '13px' }}><LockIcon /></span>}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='flex justify-between w-full gap-[20px]'>
              <div className="flex-1">
                <TournamentDes />
              </div>
              <div className='w-[100px]'>
                <div className="flex items-center">
                  <ButtonComponent
                    variant="outlined"
                    title={t('Play')}
                    rouned="large"
                    size="large"
                    color="primary"
                    fullWidth
                    className="lobby-button subscribe-button"
                    onClick={(e) => {
                      e.stopPropagation();

                    }}
                    startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '11px', bottom: '13px' }}><LockIcon /></span>}
                  />
                </div>
              </div>

            </div>
            <TournamentLine>
              <TournamentCost data={data} />
              <TournamentStatus data={data} />
              <TournamentPlayer data={data} />
            </TournamentLine>
            <TournamentLine>
              {isScheduled && <TournamentStart data={data} duration={duration} />}
              {isColpoGrosso ? <TournamentPrizeUpTo cost={data?.cost} /> : <div></div>}
              <TournamentBadge data={data} />
            </TournamentLine>
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};
export default React.memo(TournamentItemMobile);
