import { isMobileApp } from "constants/common";


export function appMessageOrientation(isLandscape: boolean) {
    if (isMobileApp() && window['ReactNativeWebView']) { 
        window['ReactNativeWebView'].postMessage(JSON.stringify({ type: 'orientation', payload: isLandscape ? 'landscape' : 'portrait' }));
    }
}

export function appMessageLogout() {
    if (isMobileApp() && window['ReactNativeWebView']) {
        window['ReactNativeWebView'].postMessage(JSON.stringify({ type: 'logout' }));
    }
}