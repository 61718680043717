import { Avatar, Box, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import { authAction, configActions, selectConfigState, selectCurrentUser, selectLanguage, selectSessionInfo, User, userApi } from '@quanticogames/gameclient-core';
import { AVATAR_LIST, LOGO_LIST } from 'constants/common';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ModalComponent from 'shared/components/modal';
import './style.scss';
import { FlexBox } from 'shared/Flex';
import { useTranslation } from 'react-i18next';
import CheckMarkIcon from 'assets/images/icons/check_mark.png';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import TickIcon from '@mui/icons-material/Done';
import IconLoading from "assets/icons/Loading";
import { GrLanguage } from 'react-icons/gr';
import { isAlreadyTourKey } from 'constants/localStorage';

const listLang = [
  { label: 'English', value: 'en' },
  { label: 'Italiano', value: 'it' }
]

function ModalSetUserName() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(true);
  const [userName, setUserName] = React.useState('');
  const [checkingLabel, setCheckingLabel] = React.useState({ err: false, label: "", isVerify: false });
  const joinAvatarUrl = (avatar) => `/images/avatar/${avatar}`;
  const joinLogoUrl = (logo) => `/images/logo/user-login/${logo}`;
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(AVATAR_LIST[0].src);
  const avatarListRef = useRef<HTMLDivElement>(null); // Ref for avatar list container
  const changeAvatarBtnRef = useRef<HTMLDivElement>(null);
  const activeSession: any = useSelector(selectSessionInfo);
  const [isChangeUsername, setIsChangeUsername] = useState(false);
  const [isCompiling, setIsCompiling] = useState(false);
  const settings = useSelector(selectConfigState)
  const language = useSelector(selectLanguage);

  const onAvatarItemClick = (avatar: string) => {
    setSelectedAvatar(avatar);
  };

  const handleSelectedAvatar = (data) => {
    onAvatarItemClick(data);
  };

  useEffect(() => {
    const avatarList = avatarListRef.current;
    const changeAvatarBtn = changeAvatarBtnRef.current;

    if (avatarList && changeAvatarBtn) {
      const handleScroll = () => {
        if (avatarList.scrollHeight - avatarList.scrollTop === avatarList.clientHeight) {
          changeAvatarBtn.classList.add('scroll-end');
        } else {
          changeAvatarBtn.classList.remove('scroll-end');
        }
      };

      if (avatarList.scrollHeight === avatarList.clientHeight) {
        changeAvatarBtn.classList.add('scroll-end');
      } else {
        changeAvatarBtn.classList.remove('scroll-end');
      }

      avatarList.addEventListener('scroll', handleScroll);

      return () => {
        avatarList.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const onVerifyUsername = async (username: string) => {
    try {
      const response = await userApi.verifyUserName({ username });
      setCheckingLabel({ err: false, label: response, isVerify: true });
    } catch (error) {
      setCheckingLabel({
        err: true,
        label: error?.response?.data || "",
        isVerify: true
      });
    }
    setIsCompiling(false);
  }

  useEffect(() => {
    if (isChangeUsername) {
      setIsCompiling(true)
      const timeOut = setTimeout(() => {
        onVerifyUsername(userName)
      }, 1000);

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [userName])

  const updateUserName = async () => {

    if (!checkingLabel.isVerify || !isChangeUsername || checkingLabel.err) {
      toast.error(t("Create nick name failed"))
      return;
    }

    const avatar = selectedAvatar;
    await userApi.updateAvatar({ avatar });
    const session = await userApi.getSessionInfo();
    dispatch(authAction.loginSuccess(session));

    try {
      await userApi.updateUserName({ userName });
      setOpen(false);
      localStorage.removeItem(isAlreadyTourKey);
      window.location.reload();
    } catch (error) {
      toast.error(t("Create nick name failed"))
      return;
    }
  };

  const activeLogo = LOGO_LIST.find((logo) => logo.id == activeSession?.casinoId);

  const showVerifyProgressIcon = () => {
    if (isCompiling) return <div className='progress'><IconLoading color="currentColor" /></div>;
    if (checkingLabel?.err && checkingLabel?.isVerify) return <CloseIcon style={{ color: 'red' }} />
    if (!checkingLabel?.err && checkingLabel?.isVerify) return <TickIcon style={{ color: 'green' }} />
    return <></>
  }

  const handleChangeLanguage = (e) => {
    const { value } = e.target
    
    if (value) {
      dispatch(configActions.updateSetting({...settings, language: value, notCallApi: false}));
    }
  }

  return (
    <>
      <ModalComponent
        open={open}
        size='small'
        typeModal='confirm'
        showFooter={true}
        title={`${t('Welcome')}!`}
        textOk={t('Play now')}
        sizeButton='large'
        onOk={updateUserName}
        showIconClose={false}
        className="welcome-user-modal"
        disableTextOK={checkingLabel?.err || !checkingLabel?.isVerify || isCompiling}
      >
        {activeLogo && (
          <FlexBox alignItems="center" justifyContent="center" className="welcome-user-logo">
            <img
              className={`welcome-user-logo-images logo-${activeLogo.id}`}
              key={`logo-item-${activeLogo.id}`}
              src={joinLogoUrl(activeLogo.src)}
            />
          </FlexBox>
        )}
        <div className="welcome-user-main">
          <div className='welcome-user-left'>
            <TextField
              className='welcome-user-form'
              placeholder={t('Enter an username')}
              variant="outlined"
              id="standard-multiline-static"
              autoComplete="off"
              InputProps={{
                endAdornment: (<InputAdornment position="start">
                  {showVerifyProgressIcon()}
                </InputAdornment>)
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUserName(event.target.value);
                if (!isChangeUsername) {
                  setIsChangeUsername(true);
                  setCheckingLabel({ err: false, label: "", isVerify: false });
                }
              }}
            />

            <p className='welcome-user-note'>{t('Note set userName')}</p>
            <div className={`welcome-user-${checkingLabel.err ? "error" : "valid"}`}>
              {(!checkingLabel.err && checkingLabel.isVerify && !isCompiling) ? (
                <div><img src={CheckMarkIcon} alt="check_mark" width={16} height={16} /></div>
              ) : <></>}
              <p>{!isCompiling && t(checkingLabel.label || "")}</p>
            </div>
          </div>
          <FlexBox className='welcome-user-avatar-title' alignItems={'center'} gap={12}>
            <FlexBox sx={{ flex: 1 }} justifyContent={'flex-end'} alignItems={'center'} gap={6}>
              <GrLanguage size={20} />
              {t('Language')}:
            </FlexBox>
            <FlexBox sx={{ flex: 1 }} justifyContent={'flex-start'}>
              <Select
                value={language}
                onChange={handleChangeLanguage}
                aria-label="Game details tabs"
                variant="outlined"
                className='select-component'
              >
                {listLang.map((item, index) => (
                  <MenuItem key={index} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FlexBox>
          </FlexBox>
          <div className="welcome-user-avatar-container">
            <h3 className="welcome-user-avatar-title">{t('Pick your avatar')}</h3>
            <Box className="welcome-user-avatar-list" id="avatarList" ref={avatarListRef}>
              {AVATAR_LIST.map((item) => (
                <Avatar
                  key={`welcome-user-${item.id}`}
                  src={`${joinAvatarUrl(item.src)}`}
                  sx={{
                    cursor: 'pointer',
                    ...(selectedAvatar === item.src && {
                      outline: '3px solid #0066FF',
                    }),
                  }}
                  className="welcome-user-avatar-item"
                  onClick={() => handleSelectedAvatar(item.src)}
                />
              ))}
            </Box>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}
export default memo(ModalSetUserName);
