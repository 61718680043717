export const formatMoney = (money: number = 0) => {
  return money.toLocaleString('it-IT', { currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export const formatLargeMoney = (money: number = 0) => {
  const absMoney = Math.abs(money);
  let formattedMoney;

  if (absMoney >= 1_000_000_000) {
    formattedMoney = (money / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'; // Tỷ
  } else if (absMoney >= 1_000_000) {
    formattedMoney = (money / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'; // Triệu
  } else {
    formattedMoney = money.toLocaleString('it-IT', { currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  return formattedMoney;
};

export const numberWithDecimals = (input: number, dec: number = 0) => {
  let value = input;
  if (dec !== null) {
      const multiplier = Math.pow(10, dec);
      value = (Math.round(value * multiplier) / multiplier);
  }
  return value.toLocaleString('it-IT', {
      minimumFractionDigits: dec,
      maximumFractionDigits: dec === null ? 2 : dec,
  }).replace(`,${Array.from(Array(dec)).fill('0').join('')}`, '');
};

export const convertCentToEuro = (money: number = 0) => money / 100;

export const convertEuroToCent = (money: number = 0) => money * 100;

export const formatMoneyColpoGrosso = (money: number = 0) => {
    let formattedMoney = money.toLocaleString('it-IT', { currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  
    if (money >= 1000) {
      return `${(money / 1000).toLocaleString('it-IT', { minimumFractionDigits: 0, maximumFractionDigits: 1 })}k`;
    }
  
    if (formattedMoney.endsWith(',00')) {
      formattedMoney = formattedMoney.slice(0, -3);
    }
  
    // if (formattedMoney.endsWith(',50')) {
    //   formattedMoney = formattedMoney.slice(0, -1);
    // }
  
    return formattedMoney;
  }