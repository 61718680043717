import React, { useEffect, useState } from 'react';
import { MenuItem, Select, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { configActions, darkMode, footerMobileLeft, selectOpenTutorial, selectConfigState, selectOrientation, userApi, UserSettings } from '@quanticogames/gameclient-core';
import { useDispatch, useSelector } from 'react-redux';
import { Toggle } from 'shared/components/toggle';
import { SoundIcon } from 'assets/icons/SoundIcon';
import { DarkModeIcon } from 'assets/icons/DarkModeIcon';
import { ChatMenuIconSvg } from 'assets/icons/ChatMenuIconSvg';
import PointerIcon from 'assets/icons/PointerIcon';
import { GrLanguage } from "react-icons/gr";

import ManualIconSvg from 'assets/icons/ManualIconSvg';
import i18n from 'i18n';
import { ClubIcon } from 'assets/icons/ClubIcon';
import { BellIcon } from 'assets/icons/BellIcon';
import { Button } from 'antd';
import { AppCrack } from 'layouts/profiles/appCrack';
import { getWinObject } from 'utils/tournamentTitle';

const Title = styled(Typography)(() => ({
  color: '#101223',
  fontWeight: 500,
  fontSize: '14px',
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  borderBottom: '1px solid #D2D2D3',
  height: 40,
  padding: '8px 12px',
  '.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    opacity: 1,
  },
  '.text': {
    flex: 1,
  },
}));

const listLang = [
  { label: 'English', value: 'en' },
  { label: 'Italiano', value: 'it' }
]

export const Settings: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const configState: UserSettings = useSelector(selectConfigState);
  const isFooterMenuLeft: boolean = useSelector(footerMobileLeft);
  const isDarkMode: boolean = useSelector(darkMode) || false;
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const showTut: any = useSelector(selectOpenTutorial);
  const [startChange, setStartChange] = useState(false)
  const [settings, setSettings] = useState<UserSettings>(configState);

  const handleChangeToggle = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if(!startChange) setStartChange(true);
    setSettings({ ...settings, [type]: e.target.checked });
  }

  useEffect(() => {
    if(!startChange) return;
    dispatch(configActions.updateSetting({...settings, notCallApi: false}));
  }, [settings, startChange]);

  const handleChangeLanguage = (e) => {
    const { value } = e.target;
    if (value) {
      if(!startChange) setStartChange(true);
      setSettings({ ...settings, language: value });

      setTimeout(() => {
        window.location.reload();
      }, 2000)
    }
  }

  return (
    <>
      <Title className="setting-item">
        <SoundIcon />
        <span className="text">{t('Sound')}</span>
        <Toggle checked={configState?.sound} onChange={(e) => {
          handleChangeToggle(e, 'sound')
        }} 
        size={'small'} />
      </Title>

      <Title className="setting-item">
        <ChatMenuIconSvg />
        <span className="text">{t('Chat')}</span>
        <Toggle checked={configState?.chat} onChange={(e) => {
          handleChangeToggle(e, 'chat')
        }} size={'medium'} />
      </Title>

      <Title className="setting-item">
        <DarkModeIcon />
        <span className="text">{t('Dark mode')}</span>
        <Toggle checked={isDarkMode} onChange={(e) => {
          handleChangeToggle(e, 'darkMode')
        }} />
      </Title>

      {/* <Title className="setting-item">
        <ClubIcon />
        <span className="text">{t('Club')}</span>
        <Toggle checked={configState?.club} onChange={(e) => {
          handleChangeToggle(e, 'club')
        }} />
      </Title>
      <Title className="setting-item">
        <BellIcon />
        <span className="text">{t('club Invitation')}</span>
        <Toggle checked={configState?.clubInvitationNotice} onChange={(e) => {
          handleChangeToggle(e, 'clubInvitationNotice')
        }} />
      </Title> */}

      <Title className='setting-item'>
        <GrLanguage size={24} />
        <span className='text'>{t("Language")}</span>
        <Select
          value={configState?.language || 'it'}
          onChange={handleChangeLanguage}
          aria-label="Game details tabs"
          variant="outlined"
          className='select-component'
        >
          {listLang.map((item, index) => (
            <MenuItem key={index} value={item?.value}>
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      </Title>

      {isMobileLandscape && (
        <Title className="setting-item">
          <PointerIcon />
          <span className="text">{t('Left-handed mode')}</span>
          <Toggle checked={isFooterMenuLeft} onChange={(e) => {
            handleChangeToggle(e, 'footerMobileLeft')
          }} />
        </Title>
      )}

      <Title className="setting-item">
        <ManualIconSvg />
        <span className="text">{t('Tutorial')}</span>
        <Toggle checked={showTut} onChange={(e) => {
          handleChangeToggle(e, 'showTutorial');
        }} />
      </Title>

    </>
  );
};
