import { Popover } from "@mui/material";
import { BalanceInfo, convertCentToEuro, formatMoney, gameActions, selectBalanceInfo, selectIsLoadedBalance } from "@quanticogames/gameclient-core";
import { Plus } from "assets/icons/Plus"
import { WalletIcon } from "assets/icons/WalletIcon"
import { useSelector } from "react-redux";
import ButtonComponent from "shared/components/button"
import { useState, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { formatLargeMoney } from "utils/formatCurrency";

const HeaderBalance = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const balanceInfo = useSelector(selectBalanceInfo) as BalanceInfo;
    let totalBalance = formatLargeMoney(convertCentToEuro(Number(balanceInfo?.balance ?? 0) + (balanceInfo?.bonusBalance ?? 0)));

    if(totalBalance === 'NaN') {
        totalBalance = '0.00';
    }
    const balanceFormat = formatMoney(convertCentToEuro(Number(balanceInfo?.balance)));
    const balanceBonusFormat = formatMoney(convertCentToEuro(Number(balanceInfo?.bonusBalance)));
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const isLoadedBalance = useSelector(selectIsLoadedBalance);
    useEffect(() => {
        let interval = setInterval(() => {
            if (isLoadedBalance) clearInterval(interval);

            dispatch(gameActions.accountInfo({}));
        }, 5000);

        return () => clearInterval(interval);
    }, [isLoadedBalance]);
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    
    const PopoverBalance = () => {
        return (
            <Popover
                open={open}
                id={id}
                onClose={handleClose}
                anchorEl={anchorEl}
                className='popover-balance'
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
            >
                <div className="popover-balance-txt">
                    <div className="popover-balance-item">
                        <span>{t('Credit')}:</span>
                        <span className="whitespace-nowrap"> € {balanceFormat}</span>
                     </div>
                    <div className="popover-balance-item">
                        <span>{t('Bonus')}:</span> 
                        <span className="whitespace-nowrap">€ {balanceBonusFormat}</span> 
                    </div>
                </div>
            </Popover>
        )
    }
    return(
        <>
            <div className="header-balance" aria-describedby={id} onClick={(e: any) => handleClick(e)} >
                <WalletIcon />
                    <div className="header-balance-text">
                        <span>
                            € {totalBalance}
                        </span>
                    </div>
                    <ButtonComponent 
                        size="small"
                        icon={<Plus />}
                        variant="text"
                        className="header-balance-btn"
                        sx={{
                            padding: 0,
                            minWidth: 0
                        }}
                    />
            </div>
            <PopoverBalance />
        </>
    )
}

export default memo(HeaderBalance);