import { useEffect, useRef } from "react";
import { GrLanguage } from "react-icons/gr";
import { FlexBox } from "shared/Flex";

const TranslateComponent = () => {
    const googleTranslateRef = useRef(null);
    const windowObj: any = window;

    useEffect(() => {
        const addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        document.body.appendChild(addScript);
        windowObj.googleTranslateElementInit = googleTranslateElementInit;
    }, []);

    const googleTranslateElementInit = () => {
        new windowObj.google.translate.TranslateElement(
            {
                pageLanguage: 'it',
                includedLanguages: 'en',
                layout: windowObj.google?.translate?.TranslateElement?.InlineLayout?.HORIZONTAL,
            },
            googleTranslateRef.current,
        );
    };

    return (
        <FlexBox gap={8} style={{ color: "#ffffff" }} alignItems={'center'}>
            <GrLanguage size={24} color="#ffffff" />
            <div className="translate-component" ref={googleTranslateRef}></div>
        </FlexBox>
    );
};

export default TranslateComponent;