import { Container } from '@mui/material';
import { GameHelper, darkMode } from '@quanticogames/gameclient-core';
import { Close } from '@mui/icons-material'
import clsx from 'clsx';
import { SKIN } from 'constants/skin';

import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { FlexBox } from 'shared/Flex';
import TranslateComponent from 'layouts/translationWidget';
import { useEffect } from 'react';

const RegulationPage = () => {
  const casino = 'quantico';
  const isDarkMode = useSelector(darkMode);
  const isMobile = GameHelper.isMobile();

  const className = clsx(
    'root-container',
    'container-regulation',
    isMobile ? 'mobile' : 'desktop',
    isDarkMode ? 'dark-mode' : casino,
  );

  useEffect(() => {
      if(casino) {
        const faviconEl = document.getElementById('favicon') as HTMLLinkElement;
        faviconEl.href = `/favicon-${SKIN[casino].logoHeader}.ico`;
      }
  }, [casino]);

  return (
    <>
      {casino && <div className={className}>
        <div className="header-box">
          <div className="header-top-container">
            <Container className="h-full !flex items-center justify-between">
              <div className="header-top-main">
                <FlexBox className="max-h-[40px] w-[100%]" justifyContent={'space-between'} alignItems={'center'}>
                  <img src={`/images/logo/skin/logo-${SKIN[casino].logoHeader}.webp`} className="h-[40px]" height={'40px'} width={'auto'} />
                  <FlexBox alignItems={'center'} gap={2}>
                    <TranslateComponent />
                    {isMobile && <a className="close-btn w-[44px] h-[44px] flex items-center justify-center z-10 cursor-pointer" href="/">
                      <Close style={{
                        color: SKIN[casino].backgroundButton
                      }} />
                    </a>}
                  </FlexBox>
                </FlexBox>
              </div>
            </Container>
          </div>
        </div>

        <div className="main-container">
          {isMobile ? (<Outlet />) : (
            <Container className="h-full" sx={{ py: 3 }}>
              <Outlet />
            </Container>
          )}
        </div>
      </div>}
    </>
  )
};
export default RegulationPage
