import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { SessionInfo, selectSessionInfo } from "@quanticogames/gameclient-core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ModalComponent from "shared/components/modal";

const ModalLockGame = () => {
    const { t } = useTranslation('common');
    const [isOpenModalLogGame, setIsOpenModalLogGame] = useState<boolean>(false);
    const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;

    useEffect(() => {
        if (!sessionInfo) {
            return
        }
        setIsOpenModalLogGame(sessionInfo?.isBanned ?? false);
    }, [sessionInfo])
    const handleClose = () => {
        
    }
    return (
        <ModalComponent
            open={isOpenModalLogGame}
            onClose={handleClose}
            className="modal-club-canceled"
            size='small'
            title={t("Notification banner")}
            textOk={t('OK')}
            onOk={handleClose}
            showFooter={false}
            showIconClose={false}
        >
            <p className='font-medium'>{t("You are suspended from the platform, please contact your operator for more info")}</p>
        </ModalComponent>
    )
}
export default ModalLockGame
