import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {IOption, ListChildren} from './component/profile-menu-item';
import { useTranslation } from 'react-i18next';

interface NestedListProps {
  options: IOption[];
  onClickMenu: (label: string) => void;
}

export default function MenuList(props: NestedListProps) {
  const { options, onClickMenu } = props;

  const { t } = useTranslation()

  return (
    <List
      sx={{ width: '100%', padding: 0 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      className="menu-list-container"

    >
      {options &&
        options.map((option, menuKey) => {
          if (option.children) {
            return (
              <div key={menuKey} className={`${option.id}`}>
                <ListChildren  children={option.children} label={t(option.label)} icon={option.icon} onClickMenu={onClickMenu} />
              </div>
            );
          }
          return (
            <ListItemButton 
              key={menuKey} 
              className={`profile-menu-item ${option.id}`} 
              onClick={() => {onClickMenu(option.value);}}
              id={option.id}
            >
              <ListItemIcon className="profile-menu-icon">{option.icon}</ListItemIcon>
              <ListItemText primary={t(option.label)} className="profile-menu-text" />
            </ListItemButton>
          );
        })}
    </List>
  );
}
