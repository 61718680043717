import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clubApi, gameSocket, IClubSettings, selectCurrentUser, User, selectActiveClub, gameActions, selectOrientation } from '@quanticogames/gameclient-core';
import ClubInfo from './club-info'
import ClubSettings from './club-settings';
import UserOnline from './user-online'
import '../index.scss';
import ListPlayerInvite from './list-player-invite';
import { LobbyToast } from 'shared/Toast';
import JoinTable from './join-table';
import { useTranslation } from 'react-i18next';
import { ClubJoinNotice } from 'pages/club/components/modal/club-join-notice';
import { MobileTab } from 'pages/club/components/mobile-tab';
interface Item {
    label: string;
    value: string;
}

interface ClubModeItemProps {
    onCreateFinish: (id: number) => void;
    onCancelFinish: () => void;
    onCancelInvite: () => void
}

export const ClubModeItem = (props: ClubModeItemProps) => {
    const { onCreateFinish, onCancelFinish, onCancelInvite } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation('club')
    const [phase, setPhase] = React.useState<'create' | 'invite' | 'join'>('invite');
    const [invitePlayers, setInvitePlayers] = React.useState<Item[]>([]);
    const currentUser: User = useSelector(selectCurrentUser);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [isOwner, setOwner] = useState(false)
    const [gettingListOnline, setGettingListOnline] = useState(false)
    const activeClub: any = useSelector(selectActiveClub)
    const [showInfoJoin, setShowInfoJoin] = useState<boolean>(false);
    const isMobilePortrait = useSelector(selectOrientation) === 'portrait';

    useEffect(() => {
        if (activeClub) {
            if (!activeClub?.tournamentAAMS && activeClub?.clubSetting?.cost > 0 || !activeClub?.id) {
                return;
            }
            const isJoinPhase = !activeClub?.clubSetting?.clubMembers?.find(item => item.isOwner) || activeClub?.clubSetting?.clubMembers?.length > 1

            if (isJoinPhase) {
                setPhase('join')
            }
            else {
                setInvitePlayers([])
                setPhase('invite')
            }
            const isOwner = activeClub?.clubSetting?.clubMembers?.find(item => item.isOwner)?.userId === currentUser?.id;
            setOwner(isOwner)
        }
        else {
            setPhase('create')
        }
    }, [activeClub]);


    const [listPlayers, setListPlayers] = React.useState<Item[]>([])
    const getListPlayerOnline = async () => {
        if (gettingListOnline) return;
        setGettingListOnline(true)
        const data = await gameSocket.getListPlayerOnline() as string[]
        let dataParse = []
        for (let i = 0; i < data?.length; i++) {
            const obj = data[i].split(';')
            dataParse.push({
                label: obj[1],
                value: obj[0]
            })
        }
        dataParse = dataParse.filter(item => item.value && item.label).filter(item => item.value !== String(currentUser?.id))
        setListPlayers(dataParse)

        setTimeout(() => {
            setGettingListOnline(false)
        }, 2000)
    }
    useEffect(() => {
        getListPlayerOnline()
    }, [])

    const handleCreateFinish = async (data: IClubSettings) => {
        try {
            const response = await clubApi.createGame(data)
            if (response === 'You can only create 1 table for 1 game in the club mode at the same time') {
                setError(t(response))
                setShowError(true)
                return
            }

            onCreateFinish(response?.id)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleInviteFinish = async () => {
        try {
            if (invitePlayers.length !== activeClub?.clubSetting?.maxSeats - 1) {
                setError('Please invite enough players')
                setShowError(true)
                return
            }
            const data = {
                clubId: activeClub?.id,
                userIds: invitePlayers.map(item => item.value)
            }
            await clubApi.inviteClub(data)
            const response = await clubApi.getClubDetail(activeClub?.id)
            dispatch(gameActions.updateClubInfo({
                id: activeClub?.id,
                clubSetting: response,
            }));
            setShowInfoJoin(true)
            setPhase('join');
        }
        catch (error) {
            console.log(error)
        }
    }
    const handleCancelInvite = async () => {
        try {
            await clubApi.cancelClub(activeClub?.id)
            onCancelFinish()
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [phase])

    const handleCancel = () => {
        setPhase('create');
        onCancelInvite();
    }

    return (
        <div className="club-type">
            <div className='club-container'>
                <div className='filter'>
                    {isMobilePortrait && <MobileTab />}
                    {
                        phase === 'create' &&
                        <>
                            <ClubInfo phase={phase} isOwner={isOwner} />
                            <ClubSettings onCreateFinish={handleCreateFinish} />
                        </>
                    }
                    {
                        phase === 'invite' &&
                        <>
                            <UserOnline listPlayers={listPlayers} onReload={getListPlayerOnline} invitePlayers={invitePlayers} setInvitePlayers={setInvitePlayers} gettingListOnline={gettingListOnline} />
                            <ListPlayerInvite invitePlayers={invitePlayers} setInvitePlayers={setInvitePlayers} onNext={handleInviteFinish} onBack={handleCancelInvite} />
                        </>
                    }
                    {
                        phase === 'join' &&
                        <>
                            <ClubInfo phase={phase} isOwner={isOwner} />
                            <JoinTable isOwner={isOwner} onCancelInvite={handleCancel} />
                        </>
                    }
                </div>
            </div>
            <LobbyToast open={showError} content={error} type={'error'} onClose={() => { setShowError(false) }} />
            <ClubJoinNotice
                open={showInfoJoin && Boolean(activeClub?.tournamentCode)}
                onClose={() => {
                    setShowInfoJoin(false)
                }}
                AAMSCode={activeClub?.tournamentAAMS}
                TournamentCode={activeClub?.tournamentCode}
            />
        </div>
    );
};



