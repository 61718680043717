import { all } from 'redux-saga/effects'
import { authSaga, chatSaga, gameSaga, matchSaga, tournamentSaga, cashTableSaga, configSaga } from '@quanticogames/gameclient-core';
import { tresetteSaga } from 'games/tresette';
import { cirullaSaga } from 'games/cirulla';
// import { bestiaSaga } from 'games/bestia';
// import { scoponeSaga } from 'games/scopone';
// import { solitarioSaga } from 'games/solitario';
// import { telesinaCashSaga } from 'games/cashTables/telesina';
// import { telesinaSaga } from 'games/telesina';
// import { peppaSaga } from 'games/peppa';
import { trentunoSaga } from 'games/trentuno';
import { ginRummySaga } from 'games/ginRummy';

export default function* rootSaga() {
  yield all([
    authSaga(),
    chatSaga(),
    gameSaga(),
    tournamentSaga(),
    matchSaga(),
    // tresetteSaga(),
    // cirullaSaga(),
    // bestiaSaga(),
    // scoponeSaga(),
    // solitarioSaga(),
    cashTableSaga(),
    // telesinaSaga(),
    // telesinaCashSaga(),
    // peppaSaga(),
    trentunoSaga(),
    ginRummySaga(),
    configSaga(),
  ])
}
