
const SortItem = ({ name, column, handleSort, sortColumn, sortDirection, t, isMinContent = true }) => {

    const active = column === sortColumn || (sortColumn === null && column === 'players');
    
    const direction = sortDirection === 'asc' ? 'up' : 'down';

    return (
        <div className='flex items-center text-left'>
            <span style={{
                width: isMinContent ? 'min-content' : 'auto',
            }
            }>
                {t(name)}
            </span>
            <div className="flex flex-col ml-1.5  cursor-pointer" onClick={() => {
                handleSort(column)
            }} >
                <svg width="12" height="10" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.83862 0.112376L6.67572e-06 6.94336L9.54468 6.94336C9.69139 6.94336 9.8103 6.81045 9.8103 6.64648C9.8103 6.57959 9.79009 6.51466 9.75294 6.46222L5.25515 0.112376C5.16409 -0.0161705 4.99703 -0.0378799 4.88201 0.0638738C4.86592 0.0781131 4.85136 0.0943894 4.83862 0.112376Z" fill={(active && direction === 'up') ? 'black' : '#c2c0c0'} />
                </svg>
                <svg width="12" height="10" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.97169 6.83098L9.8103 0H0.265625C0.118917 0 0 0.132907 0 0.296875C-3.3635e-07 0.363765 0.0202111 0.428696 0.0573584 0.481142L4.55515 6.83098C4.64621 6.95953 4.81328 6.98124 4.92829 6.87949C4.94438 6.86525 4.95895 6.84897 4.97169 6.83098Z" fill={(active && direction === 'down') ? 'black' : '#c2c0c0'} />
                </svg>

            </div>
        </div>
    )
}

export default SortItem