import { clubApi, ClubInvitation, Game, gameActions, selectActiveGame, selectClubCanceled, selectConfigState, selectGames, UserSettings } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalComponent from 'shared/components/modal';
import React, { useEffect, useState } from 'react';
import { convertSecondToTime } from 'utils/caculateTime';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { cashGame } from 'constants/common';
import moment from 'moment';
import "./club.scss";
import { roundNumberCost } from 'pages/club/components/helpers';
import { PATH_ROUTES } from 'constants/routes';

interface ClubInvitationProps {
  clubInvitation: ClubInvitation
}

const ClubInvitationModal = (props: ClubInvitationProps) => {
  const { clubInvitation } = props;
  const { t } = useTranslation(['club', 'common']);
  const navigate = useNavigate();

  const activeGame: Game = useSelector(selectActiveGame);
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [cost, setCost] = useState("");
  const [displayTime, setDisplayTime] = useState("");
  const games: Game[] = useSelector(selectGames);
  const [diffTime, setDiffTime] = useState(1000)
  const clubCancelled: any = useSelector(selectClubCanceled);
  const configState: UserSettings = useSelector(selectConfigState);
  const [invitationGame, setInvitationGame] = useState<string>(null)

  useEffect(() => {
    if (diffTime > 150) return;
    let countDown = 150 - diffTime

    const interval = setInterval(() => {
      countDown -= 1
      const endTimeDisplay = convertSecondToTime(countDown)
      setDisplayTime(endTimeDisplay)
      if (countDown <= 0) {

        clearInterval(interval)
        handleCancel()
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [diffTime])


  useEffect(() => {
    if (clubInvitation?.showInvite && configState?.clubInvitationNotice) {
      setOpen(true);
      const gameId = clubInvitation?.gameId;
      const gameName = games.find(game => game.id === gameId)?.name;
      setInvitationGame(gameName)
      const { cost, feeRate } = clubInvitation;
      
      if (cost === 0) { 
        setCost(t('Free'))
      }
      else {
        const costRound = roundNumberCost(cost);
       
        const fee = costRound * feeRate;
        setCost(`€ ${costRound} (€ ${costRound - fee} + € ${fee})`)
      }
      
      const diff = moment.utc().diff(moment.utc(clubInvitation?.createdTime), 'seconds')
      setDiffTime(diff)
    }
    else {
      setOpen(false);
    }
  }, [clubInvitation]);

  useEffect(() => {
    if (clubCancelled?.showing) {
      
      setOpen(false);
      
    }
  }, [clubCancelled])

  const handleAccept = async () => {
    const gameId = clubInvitation?.gameId;
    if (gameId !== activeGame?.id) {
      if (cashGame.includes(activeGame?.code)) {
        dispatch(gameActions.unSubscribeCashGame({ gameId: activeGame?.id }));
      }

      dispatch(gameActions.unSubscribeGame({ gameId: activeGame?.id }))
      const game = games.find(game => game.id === gameId);
      dispatch(gameActions.activeGame({ game }));
    }
    navigate(`${PATH_ROUTES.CLUB}?clubId=${clubInvitation.clubId}`, { replace: true });
    dispatch(gameActions.removeInvitation({ clubId: clubInvitation.clubId }));
  };

  const handleCancel = async () => {
    try {
      dispatch(gameActions.removeInvitation({ clubId: clubInvitation.clubId, owner: clubInvitation.ownerId, gameId: clubInvitation.gameId }));
      dispatch(gameActions.removeClub({
        id: clubInvitation.clubId,
      }))
      await clubApi.refuseInvite({
        clubId: clubInvitation.clubId,
      });

    }
    catch (error) {
      console.log(error);
    }
  };

  return (
    <ModalComponent
      open={open}
      size='small'
      typeModal='confirm'
      showFooter={true}
      title={`${t('common:Welcome')}!`}
      textOk={t('common:Confirm') + " " + displayTime}
      textCancel={t('common:Cancel')}
      onOk={handleAccept}
      onCancel={handleCancel}
      showIconClose={false}
      className="club-invitation-user-modal"
      disableTextOK={false}

    >
      <p>{t('club invitation', { code: clubInvitation.tournamentCode, owner: clubInvitation.ownerName, game: invitationGame, cost: cost })}</p>
      <p>
        {t('club invitation guide')}
      </p>
    </ModalComponent>
  );
};

export default React.memo(ClubInvitationModal);
