import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from '../theme';
import { Router } from './routes';
import i18n from 'i18n';
import { authEvent, gameActions, GameHelper, selectLanguage } from '@quanticogames/gameclient-core';
import RedirectLoginModal from 'shared/RedirectLoginModal/RedirectLoginModal';
import * as Sentry from "@sentry/react";
import FailBackPage from 'pages/failback';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import SystemMaintenance from 'pages/unexpected-page/SystemMaintenance';
import { appMessageOrientation } from 'utils/appPostMessage';
import { isAlreadyTourKey, isOpenClubNoticeKey } from 'constants/localStorage';
import SystemScheduledMaintenance from 'pages/unexpected-page/SystemScheduledMaintenance';


function App() {
  const dispatch = useDispatch();
  const [isShowRedirectLoginModal, setIsShowRedirectLoginModal] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);
  const language: string = useSelector(selectLanguage);
  const [isShowSystemMaintenance, setIsShowSystemMaintenance] = useState<boolean>(false);
  const [isShowSystemScheduledMaintenance, setIsShowSystemScheduledMaintenance] = useState<boolean>(false);

  const isTutorial = window.location.pathname.includes('tutorial');

  useEffect(() => {
    localStorage.setItem(isAlreadyTourKey, 'false');
    localStorage.setItem(isOpenClubNoticeKey, 'true');
    authEvent.on("authentication-fail", () => {
      setIsShowRedirectLoginModal(true)
    });
    
    authEvent.on("system-maintenance", () => {
      setIsShowSystemMaintenance(true);
    });

    authEvent.on("system-scheduled-maintenance", () => {
      setIsShowSystemScheduledMaintenance(true);
    });

    setTimeout(() => {
      window.dispatchEvent(new Event("reactLoaded"));
      setIsAppReady(true);
    }, 1500);
  }, [])

  const updateOrientation = () => {
    const isLandscape = GameHelper.isLandscape();
    if(!GameHelper.isMobile()) {
      dispatch(gameActions.updateOrientation('desktop'));
    }
    else {
      dispatch(gameActions.updateOrientation(isLandscape ? 'landscape' : 'portrait'));
    }
    appMessageOrientation(isLandscape);
  }

  useEffect(() => {
    updateOrientation()
    window.addEventListener(
      'resize',
      updateOrientation
    )
    return () => {
      window.removeEventListener(
        'resize',
        updateOrientation
      )
    }
  }, [])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])


  console.log('version of core: 2.4.304-3')

  if (!isAppReady) {
    return (
      <div className="loading-bg">
        <div className="loading-icon">
          <svg width="50px" height="50px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid" className="uil-default">
            <rect x="0" y="0" width="100" height="100" fill="none" className="bk"></rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(0 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0s" repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(30 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.08333333333333333s"
                repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(60 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.16666666666666666s"
                repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(90 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.25s" repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(120 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.3333333333333333s"
                repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(150 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.4166666666666667s"
                repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(180 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.5s" repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(210 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.5833333333333334s"
                repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(240 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.6666666666666666s"
                repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(270 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.75s" repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(300 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.8333333333333334s"
                repeatCount="indefinite" />
            </rect>
            <rect x="46.5" y="40" width="7" height="20" rx="6" ry="6" fill="#fff"
              transform="rotate(330 50 50) translate(0 -30)">
              <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.9166666666666666s"
                repeatCount="indefinite" />
            </rect>
          </svg>
        </div>
      </div>
    );
  }

  if(isShowSystemMaintenance) {
    return (<SystemMaintenance />)
  }

  if(isShowSystemScheduledMaintenance) {
    return (
      <SystemScheduledMaintenance />
    )
  }

  return (
    <Sentry.ErrorBoundary fallback={FailBackPage}>
      <ThemeProvider theme={theme}>
        <RedirectLoginModal open={isShowRedirectLoginModal && !isTutorial}
          onClose={() => setIsShowRedirectLoginModal(false)} />
        <Router />
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
