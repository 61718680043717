
import { useTranslation } from 'react-i18next';
import {
  Game,
  generateTournamentTitle,
  matchActions,
  selectActiveGame,
  selectCurrentUser,
  Tournament,
  tournamentActions,
  tournamentApi,
  TournamentConfig,
  User,
} from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

interface TournamentItemHelperProps { 
  onAction: (action: string, id: number) => void;
}
export function TournamentItemHelper(props: TournamentItemHelperProps) {
  const { onAction } = props;
  const { t } = useTranslation();
  const [showPassModal, setShowPassModal] = useState(false);
  const [contentDialog, setContentDialog] = useState('');
  const game: Game = useSelector(selectActiveGame);
  const dispatch = useDispatch();
  const currentUser: User = useSelector(selectCurrentUser);
  const renderTitle = (data: Tournament, isFake = false) => {
    return generateTournamentTitle({
          game: game,
          ...data,
          template: 'standard',
          demo: isFake
        }, t);
    
    // const speed = data?.gameId == 9 && data?.maxPlayers == 2 ? 'Nibble' : getSpeedLabel(data?.speed);
    // const winObj = isFake ? "DEMO" : getWinObject(data?.objective, data?.objectiveValue, t) ;
    
    // const currentTime = moment.utc();
    // const startingTime = moment.utc(data.startingTime).local();
    // const duration = startingTime.diff(currentTime, 'seconds');

    // const showSeatCount = () => {
    //   if (isChipGame(game?.code)) {
    //     return `- ${data?.maxSeats} ${t('Positions')}`;
    //   } else return '';
    // }

    // if (data?.freeroll) {
    //   return `${t('Freeroll')} ${game?.name} ${t(speed)} ${winObj} ${getGameType(data?.type, t) ? `- ${getGameType(data?.type, t)}` : ''} ${data.startingTime && duration > 0 ? `${numberWithDecimals(convertCentToEuro(data?.rewardValue), 2)} EURO BONUS ${showSeatCount()}` : ''}`;
    // }
    // if (data?.guaranteedValue) {
    //   return `${game?.name} ${t(speed)} ${winObj} GTD ${numberWithDecimals(convertCentToEuro(data?.guaranteedValue), 2)} ${showSeatCount()}`;
    // }
    // return `${game?.name} ${t(speed)} ${winObj} ${getGameType(data?.type, t) ? `- ${getGameType(data?.type, t)}` : ''} ${data.startingTime && duration > 0 ? `${showSeatCount()}` : ''}`;
  };
  const handlePlayScheduled = (id: number) => {
    const fetchData = async () => {
      try {
        const response = await tournamentApi.getPlayerStatus(id);
        if (response) {
          if (response?.status === 2) {
            setContentDialog('You have been eliminated from this tournament');
            setShowPassModal(true);
            return;
          }
          if (response?.status === 1 && response?.ongoingMatches?.length === 0) {
            setContentDialog(t('You have passed to the next round, wait for your opponent'));
            setShowPassModal(true);
            return;
          }
        }
        onAction('play', id);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  };
  const handleOpenNewTab = (e: any, id: number) => {
    e.stopPropagation();
    const fetchData = async () => {
      try {
        const response = await tournamentApi.getDetails(id);
        const matchId = response.matches.find((m) => !!m.players.find((p) => p.id === currentUser.id));
        const spectatorMatchId = response.matches[0]?.id;
        if (matchId || spectatorMatchId) {
          dispatch(
            matchActions.openMatchWindow({
              id: matchId ? matchId?.id : spectatorMatchId,
              gameCode: game.code,
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const isDevelopment = /true/i.test(process.env.REACT_APP_DEVELOPMENT);

    if (isDevelopment) {
      onAction('play', id);
    } else {
      fetchData();
    }
  };
  const handleActionFavorite = async (data: Tournament, favorite: { id: number; favoriteId: number }) => {
    if (favorite) {
      dispatch(tournamentActions.removeFavoriteTournament({
        id: favorite?.favoriteId,
      }));
      await tournamentApi.updateFavoriteTournament(favorite?.favoriteId, false);
      return;
    }
    try {
      const response: TournamentConfig = await tournamentApi.createFavoriteTournament(data?.id);

      dispatch(tournamentActions.addFavoriteTournament({
        config: response,
      }));
    }
    catch (error) {
      console.error(error);
    }

  };
  return {
    renderTitle,
    showPassModal,
    setShowPassModal,
    contentDialog,
    handlePlayScheduled,
    handleOpenNewTab,
    handleActionFavorite,
  }
};
