import { MonetizationBadgeIcon } from '../assets/icons/MonetizationBadgeIcon';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

export enum Badges {
  FREE = 'Free',
  TREBLE = 'Treble',
  PRIZE_POOL = 'Prize_pool',
  SATELLITE = 'Satellite',
  CRAZY_PRIZE = 'Crazy_Prize',
  TWO_PAIRS = '2',
  HEAD_TWO_HEAD = '0',
  FOUR_IN_TWO = '1',
  TOKEN_ICON = 'Token_Icon',
  SIT_AND_GO = 'Normal',
  SCHEDULED = 'Scheduled',
  CASH_TABLE = 'Cash_Table'
}

export const getBadges = () => {
  const { t } = useTranslation(); 

  return {
    [Badges.FREE]: {
      title: 'Free',
      icon: <Tooltip title={t("Free")} placement="top" arrow>
        <img src="/images/badge/free.png" alt={t("Free")}/>
      </Tooltip>,
    },
    [Badges.TREBLE]: {
      title: 'Filotto',
      icon: <Tooltip title={t("Filotto")} placement="top" arrow>
        <img src="/images/badge/fillotto.png" alt={t("Filotto")} />
      </Tooltip>,
    },
    [Badges.PRIZE_POOL]: {
      title: 'Guaranteed Prize',
      background: '#CD1B1B',
      icon: <MonetizationBadgeIcon />,
    },
    [Badges.SATELLITE]: {
      title: 'Sateline',
      background: '#8341D8',
      icon: <Tooltip title={t("Sateline")} placement="top" arrow>
        <img src='/images/badge/satelite.png' width={'auto'} height={'auto'} alt='Sateline' />
        </Tooltip>,
      color: '#fff',
    },
    [Badges.CRAZY_PRIZE]: {
      title: 'Colpo Grosso',
      icon: <Tooltip title={t("Colpo Grosso")} placement="top" arrow>
        <img src="/images/badge/colpo-grosso.png" alt={t("Colpo Grosso")} />
      </Tooltip>,
    },
    [Badges.TWO_PAIRS]: {
      title: 'Two pairs',
      icon: <Tooltip title={t("Two pairs")} placement="top" arrow>
        <img src="/images/badge/2vs2.png" alt={t("Two pairs")} />
      </Tooltip>,
    },
    [Badges.FOUR_IN_TWO]: {
      title: 'Four in two',
      icon: <Tooltip title={t("Four in two")} placement="top" arrow>
        <img src="/images/badge/4in2.png" alt={t("Four in two")} />
      </Tooltip>,
    },
    [Badges.HEAD_TWO_HEAD]: {
      title: 'Head two head',
      icon: <Tooltip title={t("Head two head")} placement="top" arrow>
        <img src="/images/badge/1vs1.png" alt={t("Head two head")} />
      </Tooltip>,
    },
    [Badges.TOKEN_ICON]: {
      title: 'Token',
      icon: <Tooltip title={t("Token")} placement="top" arrow>
        <img src="/images/badge/token.png" alt={t("Token")} />
      </Tooltip>,
    },
    [Badges.SIT_AND_GO]: {
      title: 'Sit & Go',
      icon: <Tooltip title={t("Sit & Go")} placement="top" arrow>
        <img src="/images/badge/sit-and-go.png" alt={t("Sit & Go")} />
      </Tooltip>,
    },
    [Badges.SCHEDULED]: {
      title: 'Scheduled',
      icon: <Tooltip title={t("Scheduled")} placement="top" arrow>
        <img src="/images/badge/tournament.png" alt={t("Scheduled")} />
      </Tooltip>,
    },
    [Badges.CASH_TABLE]: {
      title: 'Cash Table',
      icon: <Tooltip title={t("Cash Table")} placement="top" arrow>
        <img src="/images/badge/cash-table.png" alt={t("Cash Table")} />
      </Tooltip>,
    },
  }
};



export const bottom = 50;
export const bottomInGame = 20;
export const zIndex = 800;
