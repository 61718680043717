import React, { useEffect, useState } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { selectTournamentSort, Sound, SoundManager, Tournament, tournamentActions } from '@quanticogames/gameclient-core'
import moment from 'moment'
import { CountDown } from '@quanticogames/gameclient-components'
import { useTranslation } from 'react-i18next'
import './index.scss'
import { TournamentItemHelper } from './desktopHelpers'
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import { StateLabel } from 'pages/tournament/component/tournament-state'
import { TOURNAMENT_STATES_TITLES } from 'pages/tournament/component/tournament-label'
import TournamentItemButton from 'shared/components/TournamentListTable/item-button'
import { useDispatch } from 'react-redux'
import Favorite from './favorite'
import PassModal from 'pages/tournament/modal/modal-pass'
import { SortingOrder } from 'types/commonTable'
import SortItem from 'shared/components/TournamentListTable/sortItem'
import { getHighlightClass } from 'utils/tournamentTable'
import Tooltip from '@mui/material/Tooltip';
import { useSelector } from 'react-redux'
import { getBadges } from 'constants/badges'
import BadgesTournament from 'shared/Badges/badges-tournament'
interface TournamentList {
  data: Tournament[];
  onAction: (action: string, id: number, index?: number) => void;
  onSelect: (id: number, index: number) => void;
}


function TournamentList(props: TournamentList) {
  const { data, onAction, onSelect } = props;
  const dispatch = useDispatch();
  const tournamentItem = TournamentItemHelper({ onAction })
  const { renderTitle, showPassModal,
    setShowPassModal,
    contentDialog,
    handlePlayScheduled,
    handleOpenNewTab,
    handleActionFavorite } = tournamentItem;
    
  const { t } = useTranslation();
  const [sorting, setSorting] = React.useState<SortingState>([])

  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [tournamentWidth, setContainerWidth] = useState<number>(1165);
  const isScheduled = window.location.pathname.includes('tournaments');
  const sortOption = useSelector(selectTournamentSort)


  useEffect(() => {
    const handleResize = () => {
      const tournamentContainer = document.querySelector('.tournament-table-container');
      setContainerWidth(tournamentContainer?.clientWidth ?? 1165);
      setContainerHeight(tournamentContainer?.clientHeight);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSort = (column) => {
    const sortObject = {
      sortColumn: column,
      sortDirection: column !== sortOption.sortColumn ? SortingOrder.ASC : sortOption.sortDirection === SortingOrder.ASC ? SortingOrder.DESC : SortingOrder.ASC
    }
    dispatch(tournamentActions.updateTournamentSort(sortObject));
  };

  const handleTournamentsDetail = (id: number, index: number) => {
    SoundManager.play(Sound.clickButton);
    onSelect(id, index);
  };

  const closePassModal = () => {
    setShowPassModal(false);
    dispatch(tournamentActions.resetAdvanceToNextRound({}));
  }

  const columns = React.useMemo<Array<ColumnDef<Tournament>>>(() => [
    {
      accessorKey: 'favorite',
      header: '',
      size: tournamentWidth * 0.03,
      cell: ({ row }) => {
        const rowData = row.original;
        return (
          <Favorite tour={rowData} handleActionFavorite={handleActionFavorite} />
        )
      }
    },
    {
      header: () => <div>
        <SortItem name="Description" column={'description'} handleSort={handleSort} sortColumn={sortOption.sortColumn} sortDirection={sortOption.sortDirection} t={t} />
      </div>,
      id: 'Description',
      size: tournamentWidth * 0.38,
      cell: ({ row }) => {
        const rowData = row.original;
        const currentTime = moment.utc();
        const startingTime = moment.utc(rowData.startingTime).local();
        const duration = startingTime.diff(currentTime, 'seconds');
        const durationWithMs = startingTime.diff(currentTime, 'milliseconds') / 1000;
        const title = renderTitle(rowData);
        const isShowPopup = isScheduled;
        return (
          <div className={`tournament-des tournament-des-${rowData.id}`}>
            <div className="tournament-des-content">
              <Tooltip title={renderTitle(rowData)} placement="top-start" disableHoverListener={!isShowPopup} >
                <p style={{
                  width: tournamentWidth * 0.38 - 30
                }} className={`tournament-des-title ${isScheduled ? 'tournament-des-title-schedule' : ''}`}>{title}</p>
              </Tooltip>

              {rowData.startingTime && duration > 0 && (
                <p className="tournament-des-time">
                  {duration > 86400 ? t('Start at') : t('Start in')}
                  {/* {t('Start in')} */}
                  <span className='ml-[5px]'>
                    {duration > 86400 ? (
                      <span>{startingTime.format('DD/MM/YYYY HH:mm')}</span>
                    ) : (
                      <CountDown second={durationWithMs} format={durationWithMs > 3600 ? 'hh:mm:ss' : 'mm:ss'} />
                    )}
                  </span>
                </p>
              )}

            </div>
          </div>
        );
      }
    },
    {
      header: t('Badge'),
      size: 170,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <BadgesTournament data={data} />
        );
      }
    },
    {
      header: () => <SortItem name="Cost" column={'cost'} handleSort={handleSort} sortColumn={sortOption.sortColumn} sortDirection={sortOption.sortDirection} t={t} />,
      id: 'cost',
      size: tournamentWidth * 0.08,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <>
            <span>{data.cost === 0 ? t('Free') : `€ ${formatMoney(convertCentToEuro(data.cost))}`}</span>
          </>
        );
      }
    },
    {
      header: () => <SortItem name="Players" column={'players'} handleSort={handleSort} sortColumn={sortOption.sortColumn} sortDirection={sortOption.sortDirection} t={t} />,
      id: 'players',
      size: tournamentWidth * 0.1,
      cell: ({ row }) => {
        const data = row.original;
        return <>{`${data.registered}/${data.maxPlayers}`}</>;
      }
    },
    {
      header: () =><SortItem name="State" column={'state'} handleSort={handleSort} sortColumn={sortOption.sortColumn} sortDirection={sortOption.sortDirection} t={t} />,
      id: 'state',
      size: tournamentWidth * 0.12,
      cell: ({ row }) => {
        const data = row.original;
        const state = data.state;

        return (
          <StateLabel
            color={TOURNAMENT_STATES_TITLES[state]?.color}
            background={TOURNAMENT_STATES_TITLES[state]?.background}
          >
            {t(TOURNAMENT_STATES_TITLES[state]?.title)}
          </StateLabel>
        )
      }
    },
    {
      header: () => <div className='w-full flex justify-center' style={{
        width: '100%'
      }}>
        {t('Actions')}
      </div>,
      id: 'actions',
      size: tournamentWidth * 0.12,
      cell: ({ row }) => {
        const data = row.original;
        return (
          <TournamentItemButton data={data} onAction={onAction} handleOpenNewTab={handleOpenNewTab} handlePlayScheduled={handlePlayScheduled} />
        )
      }
    },
  ], [sortOption, tournamentWidth])


  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    state: {
      sorting,
    },
    onSortingChange: setSorting,

  })

  const { rows } = table.getRowModel()

  //The virtualizer needs to know the scrollable container element
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50, //estimate row height for accurate scrollbar dragging,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 7,

  })

  //All important CSS styles are included as inline styles for this example. This is not recommended for your code.
  return (
    <div className='tournament-table-container'>
      <PassModal isOpen={showPassModal} onClose={() => closePassModal()} content={contentDialog} />
      <div
        ref={tableContainerRef}
        style={{
          overflow: 'auto', //our scrollable table container
          overflowX: 'hidden',
          position: 'relative', //needed for sticky header
          height: containerHeight + 'px', //should be a fixed height
        }}
      >
        {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
        <table style={{ display: 'grid' }}>
          <thead
            style={{
              display: 'grid',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: '#F4F8F9',
            }}
          >
            {table.getHeaderGroups().map(headerGroup => (
              <tr
                key={headerGroup.id}
                style={{ display: 'flex', width: '100%' }}
              >
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      style={{
                        display: 'flex',
                        width: header.getSize(),
                        height: '55px',
                        alignItems: 'center',
                        padding: '4.5px 6px'
                      }}
                    >
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : 'w-full text-left',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              display: 'grid',
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: 'relative', //needed for absolute positioning of rows
              marginBottom: '10px'
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index] as Row<Tournament>

              return (
                <tr
                  onClick={() => handleTournamentsDetail(row.original.id, virtualRow.index)}
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  className={`tournament-item-row type-Normal ${getHighlightClass(row.original.highlight, row.original.type)}`}
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                    width: '100%',
                    height: '50px',
                    borderTop: '1px solid #d2d2d3',
                  }}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          display: 'flex',
                          width: cell.column.getSize(),
                          alignItems: 'center',
                          wordBreak: 'break-word',

                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TournamentList