import { ExpandLess } from "@mui/icons-material";
import { Game, GameHelper, selectActiveGame } from "@quanticogames/gameclient-core";
import { CloseIconSvg } from "assets/icons/CloseIconSvg";
import React, { useEffect, useState, useTransition } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { HeightTabs } from "shared/Regulation/components/HeightTabs";
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from "react-i18next";

type TabsRegulationProps = {
    items: any;
    fetchData?: (gameCode, timeType, mode) => void;
}

const TabsRegulation = ({ items, fetchData }: TabsRegulationProps) => {
    const { t } = useTranslation('common');
    const [heightTabs, setHeightTabs] = useState<any>(0);
    const [isShowSub, setIsShowSub] = useState<boolean>(false);
    const location = useLocation();
    const pathname = location.pathname;
    const parts = pathname.split('/');
    const regulationLayout = parts[parts.length - 1];
    const searchLocation = location.search;
    const searchParams = new URLSearchParams(searchLocation);
    const tabsValue = searchParams.get('tabs');
    const [activeTab, setActiveTab] = useState<string>(tabsValue);
    const [activeSubTab, setActiveSubTab] = useState<string>('')
    const [activePage, setActivePage] = useState<string>(regulationLayout);
    const game: Game = useSelector(selectActiveGame);
    const [openBarMobile, setOpenBarMobile] = useState<boolean>(false);
    const navigate = useNavigate();
    const activeGameCode = JSON.parse(localStorage.getItem('activeGame'))?.code;

    useEffect(() => {
        setHeightTabs(HeightTabs());
        if (items.length > 0) {
            if (tabsValue) {
                const checkTabs = items.find((tab) => tab.key === tabsValue);
                if (checkTabs?.subTabs && checkTabs?.subTabs.length > 0) {
                    setActiveTab(checkTabs.key);
                    setActiveSubTab(checkTabs.subTabs[0].key);
                    const antTabsTabActive = document.querySelector('.ant-tabs-tab-active') as HTMLElement;
                    const subTabsList = antTabsTabActive.nextSibling as HTMLElement;
                    subTabsList.classList.add('show');
                }
            } else {
                setActiveTab(items[0].key);
            }
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setHeightTabs(HeightTabs());
        };

        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const TabContent = ({ content }) => (
        <div className='ant-tabs-tabpane ant-tabs-tabpane-active'>
            {content}
        </div>
    );

    const handleOpenBar = () => {
        const element = document.querySelector('.ant-tabs-nav') as HTMLElement;
        element.classList.add('active');
        setOpenBarMobile(true);
    }

    const handleActivePage = (text) => {
        setActivePage(text);
        switch (text) {
            case 'game':
                navigate(`/information/regulation/${text}?tabs=${game?.code ?? activeGameCode}`);
                break;
            case 'classifiche':
                navigate(`/information/regulation/${text}?tabs=${game?.code ?? activeGameCode}`);
                break;
            default:
                navigate(`/information/regulation/${text}`);
                break;
        }
    }

    const handleCloseBarMobile = () => {
        setOpenBarMobile(false);
        if (GameHelper.isMobile()) {
            const element = document.querySelector('.ant-tabs-nav') as HTMLElement;
            element.classList.remove('active');
        }
    }

    const handleCloseSub = () => {
        setIsShowSub(false);
        const subTabsList = document.querySelector('.sub-tabs-list.show');

        if (subTabsList) {
            subTabsList.classList.remove('show');
        }
    }


    const handleSubTabClick = (subTabKey, gameCode, timeType) => {
        setActiveSubTab(subTabKey);
        setIsShowSub(true);
        if (fetchData) {
            fetchData(gameCode, timeType, 1);
        }
    };

    return (
        <div className='h-full flex flex-col'>
            <div className="bar-mobile flex items-center justify-between px-[15px] mb-[15px] sm:hidden fixed top-0 left-0 right-0 h-[60px] z-[9999] bg-[#fff]">
                <div className="w-[40px]"></div>
                <h2 className="font-bold text-[23px] font-raleway text-[#27347f] leading-[1.1] text-center">{activeTab?.replace(/-/g, ' ')}</h2>
                <a className="close-btn w-[44px] h-[44px] flex items-center justify-center z-10 cursor-pointer" href="/">
                    <CloseIconSvg />
                </a>
            </div>

            <div className="fixed top-[10px] left-[10px] z-[99999] sm:hidden">
                {openBarMobile ? (
                    <a className="close-btn w-[44px] h-[44px] flex items-center justify-center z-10 cursor-pointer" onClick={handleCloseBarMobile}>
                        <CloseIconSvg />
                    </a>
                ) : (
                    <a className="header-bar px-[10px] pt-[7px] pb-[12px] z-10 cursor-pointer" onClick={handleOpenBar}>
                        <MenuIcon />
                    </a>
                )}
            </div>

            <div className="regulation-top flex items-center text-[#fff]">
                <div className={`regulation-item ${activePage === 'game' ? 'bg-[#27347f]' : 'bg-[#afb1b4]'}`} onClick={() => handleActivePage('game')}>Regole dei giochi</div>
                <div className={`regulation-item ${activePage === 'lobby' ? 'bg-[#27347f]' : 'bg-[#afb1b4]'}`} onClick={() => handleActivePage('lobby')}>Regolamenti generali</div>
                <div className={`regulation-item ${activePage === 'classifiche' ? 'bg-[#27347f]' : 'bg-[#afb1b4]'}`} onClick={() => handleActivePage('classifiche')}>Classifiche</div>
                <div className={`regulation-item ${activePage === 'scheduled' ? 'bg-[#27347f]' : 'bg-[#afb1b4]'}`} onClick={() => handleActivePage('scheduled')}>Palinsesto</div>
            </div>

            <div className={`ant-tabs ant-tabs-left rule-tabs lg:overflow-hidden mt-[20px] flex-1 ${activePage === 'classifiche' ? 'tabs-classifiche': ''}`} style={{ height: heightTabs - 50 }}>
                <div className='ant-tabs-nav'>
                    <div className='ant-tabs-nav-wrap h-full relative'>
                        <div className={`ant-tabs-nav-list h-full ${isShowSub ? 'overflow-hidden' : 'overflow-y-auto overflow-x-hidden'}`} style={{ height: heightTabs - 50 }}>
                            {items.map(tab => (
                                <React.Fragment key={tab.key}>
                                    <div className={`ant-tabs-tab ${activeTab === tab.key ? 'ant-tabs-tab-active' : ''}`}>
                                        {tab && tab.subTabs && tab.subTabs.length > 0 ? (
                                            <div
                                                className={`ant-tabs-tab-btn cursor-pointer flex justify-between ${activeTab === tab.key ? 'text-[#fff] bg-[#0e345e]' : ''}`}
                                                onClick={() => {
                                                    setIsShowSub(true)
                                                    setActiveTab(tab.key);
                                                    setActiveSubTab(tab.subTabs[0]?.key);
                                                    if(activePage === 'classifiche') {
                                                        fetchData(tab.key, 0, 1);
                                                    }
                                                    navigate(`/information/regulation/${activePage}?tabs=${tab.key}`);
                                                }}
                                            >
                                                {tab.label}
                                                <span className='rotate-[90deg]'><ExpandLess /></span>
                                            </div>
                                        ) : (
                                            <div
                                                className='cursor-pointer ant-tabs-tab-btn'
                                                onClick={() => {
                                                    setActiveTab(tab.key);
                                                    handleCloseBarMobile();
                                                    setActiveSubTab('');
                                                    if(activePage === 'lobby') {
                                                        navigate(`/information/regulation/lobby?tabs=${tab.key}`);
                                                    }
                                                }}
                                            >
                                                {tab.label}
                                            </div>
                                        )}
                                    </div>

                                    {tab && tab.subTabs && tab.subTabs.length > 0 && <div className={`sub-tabs-list ${isShowSub && activeTab === tab.key ? 'show' : ''}`}>
                                        <div
                                            className="ant-tabs-tab-btn cursor-pointer flex justify-between text-[#fff] bg-[#27347f] !pl-[15px]"
                                            onClick={handleCloseSub}
                                        >
                                            <span className='rotate-[-90deg]'><ExpandLess style={{ color: "#fff" }} /></span>
                                            <span className="flex-1 pl-[10px] uppercase">{tab.label}</span>
                                        </div>
                                        <div className="ant-sub-tabs-list">
                                            {tab.subTabs?.map((subTabs: any) => {
                                                const timeType = subTabs.label === `${t("Weekly")}` ? 0 : subTabs.label === `${t("Monthly")}` ? 1 : subTabs.label === `${t("Annually")}` ? 2 : 3;
                                                return (
                                                    <div className={`ant-tabs-tab ${activeSubTab === subTabs.key ? 'ant-tabs-tab-active' : ''}`} key={subTabs.key} >
                                                        <div
                                                            className={`ant-tabs-tab-btn cursor-pointer !h-[auto] !py-[12px] !pl-[50px] ${activeSubTab === subTabs.key ? '!bg-[#3F51B5]' : ''}`}
                                                            onClick={() => {
                                                                handleSubTabClick(subTabs.key, tab.key, timeType);
                                                                setOpenBarMobile(false);
                                                                handleCloseBarMobile();
                                                            }}>{subTabs.label}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='ant-tabs-content-holder overflow-auto max-h-[100%]'>
                    <div className='ant-tabs-content ant-tabs-content-left'>
                        {items.map((tab, index) => (
                            <React.Fragment key={index}>
                                {activeTab === tab.key && activeSubTab === '' && (
                                    <TabContent content={tab.children}/>
                                )}
                                {tab && tab.subTabs && tab.subTabs.length > 0 && (
                                    <>
                                        {tab.subTabs?.map(subTab => (
                                            <React.Fragment key={subTab.key}>
                                                {activeSubTab === subTab.key && (
                                                    <TabContent content={subTab.children} />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TabsRegulation