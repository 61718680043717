import { Box, List, ListItem, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { selectActiveTournament, Tournament } from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import moment from 'moment';
import { StateLabel } from 'pages/tournament/component/tournament-state';
import { TOURNAMENT_STATES_TITLES } from 'pages/tournament/component/tournament-label';
import { useNavigate } from 'react-router';
import { CountDown } from '@quanticogames/gameclient-components';
import { PATH_ROUTES } from 'constants/routes';
import BadgesTournament from 'shared/Badges/badges-tournament';

const StyledListItem = styled(ListItem)(() => ({
  padding: 0,
  height: 30,
  marginTop: 5,
}));

const ListTitle = styled('p')(() => ({}));

const ListValue = styled('p')(() => ({}));

interface InfoListProps {
  onClose: () => void;
}

export const InfoList = (props: InfoListProps) => {
  const { onClose } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentTime = moment.utc();
  const tournament: Tournament = useSelector(selectActiveTournament);
  const startingTime = tournament && moment.utc(tournament.startingTime).local();
  const duration = startingTime?.diff(currentTime, 'seconds');
  const durationWithMs = startingTime?.diff(currentTime, 'milliseconds') / 1000;

  const getNameSpeed = () => {
    switch (tournament?.speed) {
      case 0:
        return t('Slow');
      case 1:
        return t('Normal');
      case 2:
        return t('Fast');
      case 3:
        return t('Turbo');
      default:
        return '';
    }
  };
  return (
    <List className="tournament-info-list">
      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Tournament ID')}</ListTitle>
        {/* <ListValue className="tournament-info-value" id='tournament-info-id'>{tournament?.id}</ListValue> */}
        <ListValue className="tournament-info-value" id='tournament-info-id'>{tournament?.code}</ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Cost')}</ListTitle>
        <ListValue className="tournament-info-value">
          {tournament?.cost === 0 ? t('Free') : `€ ${formatMoney(convertCentToEuro(tournament?.cost || 0))}`}
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Players')}</ListTitle>
        <ListValue className="tournament-info-value" id='tournament-info-players'>
          {tournament?.registered} / {tournament?.maxPlayers || 2}
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('State')}</ListTitle>
        <ListValue className="tournament-info-value" id="tournament-info-state">
          <StateLabel
            color={TOURNAMENT_STATES_TITLES[tournament?.state]?.color}
            background={TOURNAMENT_STATES_TITLES[tournament?.state]?.background}
          >
            {t(TOURNAMENT_STATES_TITLES[tournament?.state]?.title)}
          </StateLabel>
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Speed')}</ListTitle>
        <ListValue className="tournament-info-value" id="tournament-info-speed">{getNameSpeed()}</ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Playing time')}</ListTitle>
        <ListValue className="tournament-info-value" id="tournament-info-turn-time">
          {new String(Math.round((tournament?.playingTurnTime / 60) * 2) / 2 || 0)} {t('minutes')}
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Match time')}</ListTitle>
        <ListValue className="tournament-info-value" id="tournament-info-time">
          {new String(Math.round((tournament?.matchTime / 60) * 2) / 2 || 0)} {t('minutes')}
        </ListValue>
      </StyledListItem>

      <StyledListItem className="tournament-info-item">
        <ListTitle className="tournament-info-label">{t('Badge')}</ListTitle>
        <Box className="tournament-info-value">
          <BadgesTournament data={tournament} />
        </Box>
      </StyledListItem>

      {tournament?.type === 'Scheduled' && (
        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{duration > 86400 ? t('Start at') : t('Start in')}</ListTitle>
          <ListValue className="tournament-info-value">
            {duration > 86400 ? (
              <span>{startingTime.format('DD/MM/YYYY HH:mm')}</span>
            ): (
              <CountDown second={durationWithMs} format={durationWithMs > 3600 ? 'hh:mm:ss' : 'mm:ss'} />
            )}
          </ListValue>
        </StyledListItem>
      )}
      {tournament?.parentGuaranteeCode && (
        <StyledListItem className="tournament-info-item">
          <ListTitle className="tournament-info-label">{t('Satellite at')}</ListTitle>
          <ListValue className="tournament-info-value" 
          onClick={() => {
            navigate(PATH_ROUTES.TOURNAMENTS + "?code=" + tournament?.parentGuaranteeCode);
            onClose()
          }}
          >
            <p style={{
              color: '#1DA25B',
              fontWeight: 'bold',
              cursor: 'pointer',
              marginTop: 5,
            }} >
              {tournament?.parentGuaranteeCode}
            </p>

          </ListValue>
        </StyledListItem>
      )}
    </List>
  );
};
