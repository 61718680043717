import clsx from 'clsx';
import {
  ClubInvitation,
  Game,
  GameHelper,
  GameRound,
  IAlertMessage,
  IFRAME_MSG_TYPE,
  SessionInfo,
  cashTableActions,
  darkMode,
  footerMobileLeft,
  gameActions,
  isPopup,
  selectActiveGame,
  selectAlertMessage,
  selectCashTableRound,
  selectCasino,
  selectClubInvitations,
  selectOrientation,
  selectRound,
  selectRounds,
  selectSessionInfo,
  useCheckLobbyPage,
} from '@quanticogames/gameclient-core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { CONTAINER_ID } from '../../constants/common';
import { PATH_ROUTES } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalSetUserName from 'shared/Tournament/ModalSetUserName';
import { EndSession, ConfirmEndSession } from 'shared';
import ChatDesktop from 'shared/Chat/Component/chat-desktop';
import ModalComponent from 'shared/components/modal';
import './index.scss';
import { ControlPanel } from 'layouts/control-panel';
import { Footer } from 'layouts/footer';
import { FooterMobile } from 'layouts/footer/component/footer-mobile';
import Header from 'layouts/headers';
import ToastContainerCustom from 'layouts/main-layout/component/toast-container';
import MatchSwitcher from 'shared/components/match-switcher';
import SliderTable from 'shared/components/slider-table';
import AlertMessageModal from 'shared/AlertMessageModal/AlertMessageModal';

import { useDispatch } from 'react-redux';
import InactiveNotice from 'layouts/inactive-notice';
import useSound from 'hooks/useSoundManage';
import ClubInvitationModal from 'pages/club/components/modal/club-invitation';
import ModalClubCanceled from 'pages/club/components/modal/club-canceled';
import ModalTournamentDelete from 'pages/tournament/modal/modal-tournament-delete';
import ModalLockChat from 'shared/components/modal-log-chat';
import ModalLockGame from 'shared/components/modal-log-game';

type roundStart = {
  gameCode: string;
  id: number;
};

const MainLayout = () => {
  const { t } = useTranslation();
  const isMobile = GameHelper.isMobile();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLobby = useCheckLobbyPage();
  const isGamePage = location.pathname.includes(PATH_ROUTES.GAME);
  const [isRegulationPage, setIsRegulationPage] = useState<boolean>(false);
  const activeGame: Game = useSelector(selectActiveGame);
  const rounds: GameRound[] = useSelector(selectRounds);
  const url = new URL(window.location.href);
  const currentLocation = useLocation().pathname.split('/')[1];
  const [openBlockPopup, setOpenBlockPopup] = useState<boolean>(false);
  const [roundStart, setRoundStart] = useState<roundStart>();
  const sessionInfo = useSelector(selectSessionInfo) as SessionInfo;
  const skinType: string = (useSelector(selectCasino) as string);
  const isDarkMode: boolean = useSelector(darkMode);
  const isFooterMenuLeft: boolean = useSelector(footerMobileLeft);
  const alertMessage: IAlertMessage = useSelector(selectAlertMessage);
  const [headerSmall, setHeaderSmall] = useState<boolean>(false);
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const isMobilePortrait = useSelector(selectOrientation) === 'portrait';
  const isIpad = GameHelper.isTablet()
  const [showLayoutFooterMenuLeft, setShowLayoutMenuLeft] = useState<boolean>(false);
  const [isOpenAlertMessageModal, setIsOpenAlertMessageModal] = useState(false);
  const isCashTable = window.location.href.includes(PATH_ROUTES.TABLE);
  const [searchParams] = useSearchParams();
  const idFromUrl = searchParams.get('id');
  const round: any = useSelector(isCashTable ? selectCashTableRound() : selectRound());
  const [loading, setLoading] = useState<boolean>(true);
  const clubInvitations: any = useSelector(selectClubInvitations)

  const gameName = useMemo(() => {
    if (location.pathname.includes(PATH_ROUTES.TABLE) || location.pathname.includes(PATH_ROUTES.GAME)) {
      const arr = location?.pathname.split('/')?.filter(item => item !== '');
      return arr[arr.length - 1];
    }
    return activeGame?.code;
  }, [location?.pathname, activeGame?.code]);

  useSound();

  useEffect(() => {
    if (alertMessage && !isOpenAlertMessageModal) setIsOpenAlertMessageModal(!isOpenAlertMessageModal);
  }, [alertMessage]);

  useEffect(() => {
    if (isFooterMenuLeft) {
      setShowLayoutMenuLeft(true);
    } else {
      setShowLayoutMenuLeft(false);
    }
  }, [isMobileLandscape, isFooterMenuLeft])

  useEffect(() => {
    if (location.pathname.includes('regulation')) {
      setIsRegulationPage(true);
    } else {
      setIsRegulationPage(false);
    }
  }, [location]);

  useEffect(() => {
    const listRoundStart = rounds.filter((item) => item.state === 'started');
    let roundStartLastId;
    let roundStartLast;
    if (listRoundStart.length > 0) {
      roundStartLast = listRoundStart[listRoundStart.length - 1];
      roundStartLastId = roundStartLast?.matchId;
      setRoundStart(roundStartLast);
    } else {
      setOpenBlockPopup(false);
    }

    if (isPopup === undefined && roundStartLastId) {
      setOpenBlockPopup(true);
    }
  }, [rounds]);

  const handleClickGioca = () => {
    if (!roundStart) return;
    const inGameUrl = `${url.origin}/game/${roundStart?.gameCode}?id=${roundStart?.id}`;
    window.open(inGameUrl, roundStart?.id.toString(), 'width=' + screen.availWidth + ',height=' + screen.availHeight);
    setOpenBlockPopup(false);
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  const ticking = useRef(false);

  useEffect(() => {
    const isColpoGrosso = location.pathname.includes('colpo-grosso') || location.pathname.includes('filotto');
    if (isMobileLandscape && !isIpad) {
      if (!skinType) return;
      const mainContent = document.getElementById('main-content') as HTMLElement;
      if (mainContent) {
        mainContent.style.paddingTop = '85px';
      }
      if (isColpoGrosso) {   
        const containerLobby = document.querySelector('.container-lobby-page') as HTMLElement;
        if (containerLobby) {
          containerLobby.style.height = '330px';
        }
      }
      else {
        const containerLobby = document.querySelector('.container-lobby-page') as HTMLElement;
        if (containerLobby) {
          containerLobby.style.height = '100%';
        }
      }

      const handleScroll = debounce(() => {
        if (!ticking.current) {
          window.requestAnimationFrame(() => {
            const isSmall = window.scrollY > (isColpoGrosso ? 40 : 100);
            if (isSmall !== headerSmall) {
              setHeaderSmall(isSmall);
            }
            ticking.current = false;
          });
          ticking.current = true;
        }
      }, 50);

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    if (isMobilePortrait) {
      const containerLobby = document.querySelector('.container-lobby-page') as HTMLElement;
      if (containerLobby) {
        containerLobby.style.height = '100%';
      }
      const mainContent = document.getElementById('main-content') as HTMLElement;
        if (mainContent) {
          mainContent.style.paddingTop = '0px';
        }
    }
  }, [isMobileLandscape, headerSmall, skinType, currentLocation, isMobilePortrait]);

  useEffect(() => {
    if (isMobileLandscape || isMobilePortrait) {
      setHeaderSmall(false);
    }
   }, [isMobileLandscape, isMobilePortrait]);

  let containerClass = `container-game container-game-${gameName}`;
  if (isLobby) containerClass = 'container-lobby';
  else if (isRegulationPage) containerClass = 'container-regulation';

  // useEffect(() => {
  //   if (sessionInfo && sessionInfo?.isAuthenticated) {
  //     dispatch(cashTableActions.loadOnGoingGame());
  //   }
  // }, [sessionInfo]);

  useEffect(() => {
    if (sessionInfo && sessionInfo.isAuthenticated) {
      const matchId = url.searchParams.get('id');
      const metaData = {
        isLobby: isLobby,
        matchId: isLobby ? null : matchId
      }
      dispatch(gameActions.ping(metaData))
    }
  }, [isLobby, sessionInfo]);


  const className = useMemo(() => {
    return clsx(
      'root-container',
      isMobile && 'mobile',
      !isMobile && 'desktop',
      isDarkMode ? 'dark-mode' : skinType,
      isMobileLandscape && 'mobile-landscape',
      isMobileLandscape && (showLayoutFooterMenuLeft ? 'root-container-left' : 'root-container-right'),
      containerClass
    );
  }, [isMobile, isDarkMode, isMobileLandscape, showLayoutFooterMenuLeft, containerClass, skinType]);

  useEffect(() => {
    setLoading(true);
  }, [idFromUrl])

  useEffect(() => {
    if (round?.loaded) setLoading(false)
  }, [round?.loaded]);

  useEffect(() => {
    const cb = (e: any) => {
      if (e?.data?.type === IFRAME_MSG_TYPE.IFRAME_LOADED) setLoading(false);
    }
    window.addEventListener('message', cb);

    return () => {
      window.removeEventListener('message', cb);
    };
  }, []);

  return (
    <>
      {skinType && <div id={CONTAINER_ID} className={className}>
        {sessionInfo?.isAuthenticated && !sessionInfo?.user?.userName && <ModalSetUserName />}

        <div className={`header-box ${isMobileLandscape ? headerSmall ? 'header-small' : '' : ''}`}>
          {((isMobile && !isGamePage) || !isMobile) && <Header isHeaderSmall={headerSmall} />}

          {isLobby && !isRegulationPage && (
            <>
              <SliderTable />
              <ControlPanel />
            </>
          )}
        </div>

        <div className="main-content" id="main-content">
          <AlertMessageModal
            alertMessage={alertMessage}
            open={isOpenAlertMessageModal}
            onClose={() => setIsOpenAlertMessageModal(!isOpenAlertMessageModal)}
          />

          <div className="main-container">
            {/* <Loading inGame={true}/> */}
            <Outlet />
          </div>
        </div>

        {(isLobby || isRegulationPage) && <Footer />}

        {isMobile && !isGamePage && <FooterMobile />}

        {loading && isGamePage ? <></> : <><MatchSwitcher /> <ChatDesktop /></>}

        {openBlockPopup && roundStart && (
          <ModalComponent
            title={t("Notification")}
            onOk={handleClickGioca}
            textOk={t("Play")}
            onCancel={() => setOpenBlockPopup(false)}
            className="modal-tooltip"
            open={openBlockPopup}
            size='small'
          >
            <p>{t('New game in tour started', { id: roundStart?.id })}</p>
          </ModalComponent>
        )}

        <ModalLockChat />
        <ModalLockGame />
        <EndSession />
        <ConfirmEndSession />
        <ToastContainerCustom />
        <InactiveNotice />
        <ModalClubCanceled />
        <ModalTournamentDelete />

        {clubInvitations?.length > 0 && clubInvitations.map((invitation: ClubInvitation) => {

          return (
            <ClubInvitationModal clubInvitation={invitation} />
          )
        })}
      </div>}
    </>

  );
};
export default MainLayout;
