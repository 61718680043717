import { cashTableActions, Game, GameHelper, selectActiveGame, selectCashFilterOptions, selectCashSortOptions, TournamentStateEnum } from '@quanticogames/gameclient-core';
import { PATH_ROUTES } from 'constants/routes';
import { SortMenu } from 'layouts/control-panel/component/Sort';
import { HideFullTableToggle } from 'layouts/control-panel/component/Toggle';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useCashTableFilter } from 'shared/Filters/FilterConstants';
import { FilterItem } from 'shared/Filters/FilterItem';
import ModalComponent from 'shared/components/modal';

type FilterCashTableProps = {
  open: boolean;
  onClose: () => void;
};

const FilterCashTable = ({ open, onClose }: FilterCashTableProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState<any>({});
  const cashTableFilter = useCashTableFilter();
  const isMobile = GameHelper.isMobile();
  const [fieldSort, setFieldSort] = useState<string>('invitation');
  const [sortType, setSortType] = useState<'asc' | 'desc'>('asc');
  const filterOptions = useSelector(selectCashFilterOptions);
  const filterSort = useSelector(selectCashSortOptions)

  const game = useSelector(selectActiveGame)
  const filterData = (name: string, values: any[]) => {
    const updatedFilterOptions: any = { ...filterList };
    const options = cashTableFilter[name].options.filter(o => values.includes(o.id));

    switch (name) {
      case 'cost':
        updatedFilterOptions.costLabels = options.map(o => o.label);
        updatedFilterOptions.costRanges = options.map(o => o.costRange);
        break;

      case 'maxPlayers':
        updatedFilterOptions.maxPlayersLabels = options.map(o => o.label);
        updatedFilterOptions.maxPlayers = options.map(o => o.maxPlayers);
        break;
    }

    setFilterList(updatedFilterOptions);
  };

  const onResetFilter = () => {
    setFilterList({});
    setIsHideFullTable(false);
    dispatch(cashTableActions.resetFilters());
    dispatch(cashTableActions.updateSort({ sortColumn: fieldSort, sortDirection: sortType }))
    onClose();
  }

  const handleApply = () => {
    dispatch(cashTableActions.updateFilters(filterList));
    dispatch(cashTableActions.updateSort({ sortColumn: fieldSort, sortDirection: sortType }))
    onClose();
  };

  useEffect(() => {
    setFilterList(filterOptions);
    setIsHideFullTable(filterOptions?.state === TournamentStateEnum.open);
  }, [open, filterOptions]);

  const onCancelFilter = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      setFieldSort(filterSort.sortColumn);
      setSortType(filterSort.sortDirection
      );
    }
  }, [open, filterSort]);

  const calculateFilterValue = (name: string) => {
    const labels: string[] = filterList[`${name}Labels`] || [];
    const options = cashTableFilter[name].options.filter((o) => labels.includes(o.label));
    return options.map((o) => o.id); // return id list
  };
  const [isHideFullTable, setIsHideFullTable] = useState<boolean>(false);


  useEffect(() => {
    setFilterList(prevState => {
      return {
        ...prevState,
        state: isHideFullTable ? TournamentStateEnum.open : false
      }
    })

  }, [isHideFullTable]);

  return (
    <ModalComponent
      open={open}
      className="filter-mobile-modal"
      title="Filter"
      onClose={onCancelFilter}
      typeModal="filter"
      onCancel={onResetFilter}
      onOk={handleApply}
      textOk={t('Apply')}
      textCancel={t('Reset')}
      showFooter={true}
      size={isMobile ? "medium" : "small"}
    >
      <div className="filter-mobile-list">
        {isMobile && <div className={"filter-mobile-item"}>
          <SortMenu isCashTable={true} setSortType={setSortType} setFieldSort={setFieldSort} sortType={sortType} fieldSort={fieldSort} />
        </div>}
        <div className="filter-mobile-item">
          <HideFullTableToggle isHideFullTable={isHideFullTable} setIsHideFullTable={setIsHideFullTable} />
        </div>
        <div className="filter-mobile-item">
          <p className="filter-mobile-label">{t('Description')}</p>
          {(Object.entries(cashTableFilter) || []).map(([name, filter]) => {
            const title = (game?.code === "SetteEMezzo" && name === 'cost') ? t('Cost') : t(filter
              .title)
            return (
              <FilterItem
                key={`filter-item-${name}`}
                id={name}
                title={title}
                options={filter.options}
                values={calculateFilterValue(name)}
                onChange={(values) => filterData(name, values)}
              />
            );
          })}
        </div>


      </div>
    </ModalComponent>
  );
};
export default FilterCashTable;
