import { TooltipPosition } from 'intro.js/src/core/steps';

interface IntroStep {
    index: number;
    element: string;
    intro: string;
    position: TooltipPosition;
}

export const introStep = [
    {
        index: 0,
        element: '.tournament-table',
        intro: 'Welcome to the lobby',
        position: 'top',
    },
    {
        index: 1,
        element: '.nav-link-game-mode',
        intro: 'Switch between game modes',
        position: 'bottom',
    },
    {
        index: 2,
        element: '.header-balance',
        intro: 'User credit balance',
        position: 'bottom',
    },
    {
        index: 3,
        element: '.change-game-button',
        intro: 'Click here to change the game',
        position: 'bottom',
    },
    {
        index: 4,
        element: '.tutorial-btn',
        intro: 'view tutorial game',
        position: 'top',
    },
    {
        index: 5,
        element: '.type-Normal',
        intro: 'Tournament table information',
        position: 'top-left-aligned',
    },
    {
        index: 6,
        element: '.subscribe-button',
        intro: 'Click here to register for the table',
        position: 'top',
    },
    {
        index: 7,
        element: '.button-action-icon',
        intro: 'Click here to see all your current tables',
        position: 'top',
    },
    {
        index: 8,
        element: '#match-switch-mobile-fake',
        intro: 'Click here to jump right in and join the ongoing tables.',
        position: 'top',
    },
    {
        index: 9,
        element: '.chat-button',
        intro: 'Click here to access the chat',
        position: 'top',
    },
    {
        index: 10,
        element: '.chat-room-Lobby',
        intro: 'Tour chat lobby',
        position: 'top',
    },
    {
        index: 11,
        element: '.chat-room-Helpdesk',
        intro: 'Tour chat helpdesk',
        position: 'top',
    },
    {
        index: 12,
        element: '.header-user',
        intro: 'Player information and settings',
        position: 'bottom',
    },
    {
        index: 13,
        element: '.profile-header-info-container',
        intro: 'Balance information',
        position: 'bottom',
    },
    {
        index: 14,
        element: '.top-up-button',
        intro: 'Wallet balance user',
        position: 'bottom',
    },
    {
        index: 15,
        element: '.profile-menu-settings',
        intro: 'User profile information',
        position: 'bottom',
    },
    {
        index: 16,
        element: '.profile-menu-history',
        intro: 'The match history',
        position: 'bottom',
    },
    {
        index: 17,
        element: '#profile-menu-token',
        intro: 'User token list',
        position: 'bottom',
    },
    // {
    //     element: '.profile-menu-warning',
    //     intro: 'Warning section',
    //     position: 'bottom',
    // },
    
   
] as IntroStep[];

export const introStepMobile = [
    {
        index: 0,
        element: '.tournament-table',
        intro: 'Welcome to the lobby',
        position: 'top',
    },
    {
        index: 1,
        element: '.nav-link-game-mode-mobile',
        intro: 'Switch between game modes',
        position: 'bottom',
    },
    {
        index: 2,
        element: '.header-balance',
        intro: 'User credit balance',
        position: 'bottom',
    },
    {
        index: 3,
        element: '.change-game-button',
        intro: 'Click here to change the game',
        position: 'bottom',
    },
    {
        index: 4,
        element: '.tutorial-btn',
        intro: 'view tutorial game',
        position: 'bottom',
    },
    {
        index: 5,
        element: '.type-Normal',
        intro: 'Tournament table information',
        position: 'bottom',
    },
    {
        index: 6,
        element: '.subscribe-button',
        intro: 'Click here to register for the table',
        position: "left",
    },
    {
        index: 7,
        element: '.button-action-icon',
        intro: 'Click here to see all your current tables',
        position: 'top',
    },
    {
        index: 8,
        element: '#match-switch-mobile-fake',
        intro: 'Click here to jump right in and join the ongoing tables.',
        position: 'bottom',
    },
    {
        index: 9,
        element: '.chat-button',
        intro: 'Click here to access the chat',
        position: 'top',
    },
    {
        index: 10,
        element: '.chat-room-Lobby',
        intro: 'Tour chat lobby',
        position: 'top',
    },
    {
        index: 11,
        element: '.chat-room-Helpdesk',
        intro: 'Tour chat helpdesk',
        position: 'top',
    },
    {
        index: 12,
        element: '.header-user',
        intro: 'Player information and settings',
        position: 'bottom',
    },
    {
        index: 13,
        element: '.profile-header-info-container',
        intro: 'Balance information',
        position: 'bottom',
    },
    {
        index: 14,
        element: '.top-up-button',
        intro: 'Wallet balance user',
        position: 'bottom',
    },
    {
        index: 15,
        element: '.profile-menu-settings',
        intro: 'User profile information',
        position: 'bottom',
    },
    {
        index: 16,
        element: '.profile-menu-history',
        intro: 'The match history',
        position: 'bottom',
    },
    {
        index: 17,
        element: '#profile-menu-token',
        intro: 'User token list',
        position: 'bottom',
    },
    // {
    //     element: '.profile-menu-warning',
    //     intro: 'Warning section',
    //     position: 'bottom',
    // },


] as IntroStep[];