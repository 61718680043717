import { selectClubMode } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalComponent from 'shared/components/modal';
import "./club.scss";
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { PATH_ROUTES } from 'constants/routes';
import { isOpenClubNoticeKey } from 'constants/localStorage';

const ModalNotice = () => {
  const { t } = useTranslation('club');
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    localStorage.setItem(isOpenClubNoticeKey, 'false');
    setOpenModal(false);
  }

  useEffect(() => {
    const isOpenClubNotice = localStorage.getItem(isOpenClubNoticeKey);
    if (!isOpenClubNotice || isOpenClubNotice === 'true') {
      setOpenModal(true);
      localStorage.setItem(isOpenClubNoticeKey, 'true');
    }
    else {
      setOpenModal(false);
    }
  }, []);

  const handleCancel = () => {
    navigate(PATH_ROUTES.SIT_AND_GO)
  }

  return (
    <ModalComponent
      open={openModal}
      className="modal-club-disclaimer"
      size='small'
      title={t("Disclaimer")}
      onOk={handleClose}
      textOk={t('I agree')}
      textCancel={t('I disagree')}
      onCancel={handleCancel}
      disableTextOK={false}
      showFooter={true}
      showIconClose={false}
    >
      <p>{t('Disclaimer content 1')}</p>
      <p>{t('Disclaimer content 2')}</p>
      <p>{t('Disclaimer content 3')}</p>
    </ModalComponent>
  );
};

export default ModalNotice;
