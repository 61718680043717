import { Dialog, DialogContent, DialogTitle, Typography, styled } from '@mui/material';
import {
  Tournament,
  selectActiveTournament,
  selectCashTable,
} from '@quanticogames/gameclient-core';
import { CloseIconSvg } from 'assets/icons/CloseIconSvg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';

type ModalInformationProps = {
  onClose?: (...agrs: any) => void;
  openModalMatchInfo?: boolean;
  isCashTable?: boolean;
};

const tournamentMap = {
  'Normal': 'Sit & Go',
  'Crazy_Prize': 'Colpo Grosso',
  'Treble': 'Filotto',
}

const StyledText = styled(Typography)(() => ({
  fontWeight: 600,
}));

const ModalInformation: React.FC<ModalInformationProps> = ({
  onClose,
  openModalMatchInfo,
  isCashTable = false,
}: ModalInformationProps) => {
  const { t } = useTranslation();
  const tournament: Tournament = useSelector(selectActiveTournament);
  const table: any = useSelector(selectCashTable);

  return (
    <>
      <Dialog
        open={openModalMatchInfo}
        sx={{
          borderRadius: '10px',
        }}
        className="font-raleway"
        onClose={onClose}
      >
        <DialogTitle
          sx={{
            background: '#0E345E',
            color: '#fff',
            padding: '10px 20px',
            height: '45px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontWeight: 400,
          }}
        >
          <div className="flex flex-1 justify-center items-center">{isCashTable ? t('Cash Table') : t('Tournament')}</div>
          <div onClick={onClose} className="flex justify-end items-end cursor-pointer"><CloseIconSvg /></div>
        </DialogTitle>
        <DialogContent sx={{ fontSize: '15px', marginTop: '15px' }}>
          {isCashTable ? (
            <>
              <StyledText>{t("Table Code")}: {table?.code}</StyledText>
              {tournament?.aamsCode && <StyledText>{t("AAMS Code")}: {table?.aamsCode}</StyledText>}
              <StyledText>{t("Table ID")}: {table?.id}</StyledText>
              <StyledText>{t('Mode')}: {t('Cash Table')}</StyledText>
              <StyledText>{t('Ante')}: € {formatMoney(convertCentToEuro(table?.cost || table?.invitation))}</StyledText>
            </>
          ) : (
            <>
              <StyledText>{t("Tournament Code")}: {tournament?.code}</StyledText>
              {tournament?.aamsCode && <StyledText>{t("AAMS Code")}: {tournament?.aamsCode}</StyledText>}
              <StyledText>{t("Tournament ID")}: {tournament?.id}</StyledText>
              <StyledText>{t("Mode")}: {t(tournamentMap[tournament?.type])}</StyledText>
              {tournament?.prizePlan && (
                <>
                  <StyledText>{t("Cost")}: € {formatMoney(convertCentToEuro(tournament?.cost))}</StyledText>
                  <StyledText>{t("Prize Pool")}: € {formatMoney(convertCentToEuro(tournament?.prizePlan?.totalPrize))}</StyledText>
                </>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalInformation;
