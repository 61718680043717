import { useEffect, useMemo, useState, useRef } from 'react';
import { styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  PricePoolDistribution,
  selectActiveTournament,
  Tournament,
  TournamentStateEnum,
} from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import { useTranslation } from 'react-i18next';
import { ConditionComponent } from 'shared/condition';
import { Select } from 'antd';

const StyledTableCell = styled(TableCell)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

type PrizePoolTableProps = {
  subscribeSimulation?: boolean;
  prizeInProgress: any;
  prizeDistribution: PricePoolDistribution[];
};

export const PrizePoolTable = ({
  prizeInProgress,
  prizeDistribution,
}: PrizePoolTableProps) => {
  const { t } = useTranslation();
  const tournament: Tournament = useSelector(selectActiveTournament);
  const [prizeDistribute, setPrizeDistribute] = useState<number[]>([]);
  const [subcribeNumber, setSubcribeNumber] = useState<number>(tournament?.minPlayers);
  const [satellitePrize, setSatellitePrize] = useState<number[]>([]);
  const [changesSimulazione, setChangeSimulazione] = useState<string>(
    `${tournament?.minPlayers || 0} ${t('subscribers')}`
  );

  const parentRef = useRef(null);
  
  const isInGame =
    tournament?.state === TournamentStateEnum.inprogress ||
    tournament?.state === TournamentStateEnum.end ||
    tournament?.state === TournamentStateEnum.terminated;

  const subcribeSimulationList = Array.from(new Set(prizeDistribution?.map((entry) => entry?.participants)).values());

  useEffect(() => {
    if (isInGame) {
      if (prizeInProgress) {
        handleSubscribeSimulation(tournament?.participants?.length);
      }
      return;
    }
    if (prizeDistribution) handleSubscribeSimulation(subcribeNumber);
  }, [prizeDistribution, prizeInProgress, isInGame]);

  const handleSubscribeSimulation = (e: string | number) => {
    const value = Number(e);
    setSubcribeNumber(value);
    setChangeSimulazione(`${value} ${t('subscribers')}`);
    let number: number;

    if (value >= subcribeSimulationList[subcribeSimulationList.length - 1]) {
      number = subcribeSimulationList[subcribeSimulationList.length - 1];
    } else {
      for (let i = 0; i < subcribeSimulationList.length - 1; i++) {
        if (value >= subcribeSimulationList[i] && value < subcribeSimulationList[i + 1]) {
          number = subcribeSimulationList[i];
        }
      }
    }

    const prizeDistributeArr = [];
    prizeDistribution
      .filter((item) => item.participants === number)
      .forEach((item) => {
        const a = item.toPos - item.fromPos;
        if (a !== 0) {
          for (let index = 0; index <= a; index++) {
            prizeDistributeArr.push(item.rate);
          }
        } else {
          prizeDistributeArr.push(item.rate);
        }
      });
    setPrizeDistribute(prizeDistributeArr);
  };

  const totalPrize = useMemo(() => {
    if (tournament?.freeroll) return tournament?.rewardValue;
    const totalPrizeByCost = (tournament?.cost - tournament?.fee) * subcribeNumber;
    return Math.max(totalPrizeByCost, tournament?.guaranteedValue);
  }, [tournament, subcribeNumber]);

  const subscribeOption = useMemo(() => {
    const minPlayers = tournament?.minPlayers || 0;
    const maxPlayers = tournament?.maxPlayers || 0;
    const players = maxPlayers - minPlayers + 1;
  
    return Array.from({ length: players }, (_, index) => {
      const value = minPlayers + index; 
      return { value, label: `${value} ${t('subscribers')}` };
    });
  }, [tournament, t]);

  useEffect(() => {

    if (tournament?.parentGuaranteeCode) {
      const parentCost = tournament?.parentGuaranteeCost;
      const fee = tournament?.fee;
      const totalPrize = ((tournament?.cost - fee) * tournament?.minPlayers)
      const secondPrize = totalPrize - parentCost;
      setSatellitePrize([parentCost, secondPrize]);
    }
  }, [tournament]);

  const rowVirtualizer = useVirtualizer({
    count: subcribeNumber,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40, 
  });

  return (
    <>
      {tournament?.type === 'Scheduled' && !isInGame && (
        <div className="flex justify-end mb-[10px] items-center pr-[5px]">
          <div className="mr-[10px]">{t("Simulation prize pool")}</div>
          <Select
            defaultValue={changesSimulazione}
            style={{ width: 120 }}
            onChange={handleSubscribeSimulation}
            options={subscribeOption}
          />
        </div>
      )}

      <ConditionComponent condition={Boolean(tournament?.parentGuaranteeCode)}>
        <p style={{ textAlign: 'right', marginRight: 14, fontSize: 14 }}>
          {t('Simulation prize pool') + ' ' + tournament?.minPlayers + t("subscribers")}
        </p>
      </ConditionComponent>

      <Table className='table-container' style={{ width: '100%'}} ref={parentRef}>
        <TableHead style={{position: 'sticky', top: 0, zIndex: 1}}>
          <TableRow style={{ display: 'flex', width: '100%' }}>
            <StyledTableCell width="60px" style={{ textAlign: 'center', justifyContent: 'center' }}>
              {t('Pos')}.
            </StyledTableCell>
            <StyledTableCell style={{ flex: 1}}>
              {t('Prize')}
            </StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody style={{ height: rowVirtualizer.getTotalSize(), position: 'relative', width: '100%' }}>
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            return (
              <TableRow 
                key={`prize-pool-table-item-${virtualRow.index}`}
                style={{ position: 'absolute', top: virtualRow.start, width: '100%', display: 'flex' }}
              >
                <StyledTableCell width="60px" style={{ textAlign: 'center', justifyContent: 'center' }}>
                  {virtualRow.index + 1}°
                </StyledTableCell>

                <ConditionComponent condition={!tournament?.parentGuaranteeCode}>
                  <StyledTableCell style={{ flex: 1 }}>
                    {virtualRow.index >= prizeDistribute?.length
                      ? '-'
                      : !isInGame
                        ? `€ ${formatMoney(convertCentToEuro(Math.floor(prizeDistribute[virtualRow.index] * totalPrize)))}`
                        : `€ ${formatMoney(convertCentToEuro(prizeInProgress[virtualRow.index]?.amount))}`}
                  </StyledTableCell>
                </ConditionComponent>

                <ConditionComponent condition={Boolean(tournament?.parentGuaranteeCode)}>
                <StyledTableCell style={{ flex: 1 }}>
                  {virtualRow.index === 0
                    ? t('qual.', { tournamentCode: tournament?.parentGuaranteeCode })
                    : virtualRow.index === 1
                      ? `€ ${formatMoney(convertCentToEuro(satellitePrize[1]))}`
                      : '-'}
                </StyledTableCell>
                </ConditionComponent>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  );
};