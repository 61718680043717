import { Game, GameHelper, Sound, SoundManager, TournamentFilter, darkMode, selectActiveGame, selectCasino, selectFilterOptions, tournamentActions, TournamentStateEnum, selectTournamentSort, cashTableActions, } from "@quanticogames/gameclient-core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { TOURNAMENT_STATUS_FILTER, useTourFilter, useTourItPokerFilter } from "shared/Filters/FilterConstants";
import { FilterItem } from "shared/Filters/FilterItem";
import ModalComponent from "shared/components/modal";
import { isChipGame } from "utils/constants";
import { FormControlLabel } from '@mui/material';
import { CustomToggle, HideFullTableToggle } from './Toggle';
import { COLORS } from "constants/colors";
import { SortMenu } from "layouts/control-panel/component/Sort";
import { PATH_ROUTES } from "constants/routes";
import { set } from "lodash";


type FilterTournamentProps = {
    open: boolean,
    onClose: () => void;
}

const FilterTournament = ({ open, onClose }: FilterTournamentProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const updatedFilterOptions: any = {} as TournamentFilter;

    const filterOptions = useSelector(selectFilterOptions);
    const sortOptions = useSelector(selectTournamentSort)

    const [filterList, setFilterList] = useState<any>({});
    const activeGame: Game = useSelector(selectActiveGame);

    const isMobile = GameHelper.isMobile();

    const tournamentItPokerFilter = useTourItPokerFilter(activeGame?.code);
    const tournamentFilter = useTourFilter();
    const descriptionFilter = isChipGame(activeGame?.code) ? tournamentItPokerFilter : tournamentFilter;
    const tournamentFilters = isChipGame(activeGame?.code) ? { ...tournamentItPokerFilter, ...TOURNAMENT_STATUS_FILTER } : { ...tournamentFilter, ...TOURNAMENT_STATUS_FILTER };
    const [fieldSort, setFieldSort] = useState<string>('players');
    const [sortType, setSortType] = useState<'asc' | 'desc'>('asc');

    const [isHideFullTable, setIsHideFullTable] = useState<boolean>(false);

    const filterData = (name: string, values: any[]) => {
        const options = tournamentFilters[name].options.filter(o => values.includes(o.id));
        switch (name) {
            case 'cost':
                updatedFilterOptions.costLabels = options.map(o => o.label);
                updatedFilterOptions.costRanges = options.map(o => o.costRange);
                break;

            case 'objective':
                updatedFilterOptions.objectiveLabels = options.map(o => o.label);
                updatedFilterOptions.objectives = options.map(o => o.objective);
                updatedFilterOptions.objectiveValues = options.map(o => o.objectiveValue);
                break;

            case 'matchType':
                updatedFilterOptions.matchTypeLabels = options.map(o => o.label);
                updatedFilterOptions.matchTypes = options.map(o => o.matchType);
                break;

            case 'timeSpeed':
                updatedFilterOptions.timeSpeedLabels = options.map(o => o.label);
                updatedFilterOptions.timeSpeeds = options.map(o => o.timeSpeed);
                break;

            case 'maxPlayers':
                updatedFilterOptions.maxPlayersLabels = options.map(o => o.label);
                updatedFilterOptions.maxPlayers = options.map(o => o.maxPlayers);
                break;

            case 'status':
                updatedFilterOptions.statusLabels = options.map(o => o.label)
                updatedFilterOptions.status = options.map(o => o.status)
                break;
        }
        setFilterList({ ...filterList, ...updatedFilterOptions })
    }

    useEffect(() => {
        setFilterList(prevState => {
            return {
                ...prevState,
                state: isHideFullTable ? TournamentStateEnum.open : false
            }
        })
    }, [isHideFullTable]);

    const handleApply = () => {
        dispatch(tournamentActions.updateFilters(filterList))
        dispatch(tournamentActions.updateTournamentSort({ sortColumn: fieldSort, sortDirection: sortType }))
        onClose();
    }

    const onResetFilter = () => {
        setFilterList(null);
        setIsHideFullTable(false);
        dispatch(tournamentActions.resetFilters(null));
        dispatch(tournamentActions.updateTournamentSort({ sortColumn: fieldSort, sortDirection: sortType }))
        onClose();
    }
    const onCancelFilter = () => {
        onClose()
    }

    const calculateFilterValue = (name: string) => {
        if (filterList === null) return [];
        try {
            const labels: string[] = filterList[`${name}Labels`] || [];
            const options = tournamentFilters[name].options.filter(o => labels.includes(o.label));
            return options.map(o => o.id); // return id list
        }
        catch (error) {
            console.log('error', filterList)
        }
    }


    useEffect(() => {
        setFilterList(filterOptions);
        setIsHideFullTable(filterOptions?.state === TournamentStateEnum.open);
        setFieldSort(sortOptions.sortColumn);
        setSortType(sortOptions.sortDirection);
    }, [filterOptions, sortOptions, open]);

    return (
        <ModalComponent
            open={open}
            className={isMobile ? "filter-mobile-modal" : "filter-desktop-modal"}
            title="Filter"
            onClose={onCancelFilter}
            typeModal='filter'
            onCancel={onResetFilter}
            onOk={handleApply}
            textOk={t('Apply')}
            textCancel={t('Reset')}
            showFooter={true}
            size={isMobile ? 'small' : 'xl'}
        >

            <div className={isMobile ? "filter-mobile-list" : "filter-desktop-list"}>
                {isMobile && <div className={"filter-mobile-item"}>
                    <SortMenu isCashTable={false} setSortType={setSortType} setFieldSort={setFieldSort} sortType={sortType} fieldSort={fieldSort} />
                </div>}
                <div className={isMobile ? "filter-mobile-item" : "filter-desktop-item"}>
                    <HideFullTableToggle isHideFullTable={isHideFullTable} setIsHideFullTable={setIsHideFullTable} />
                </div>
                <div className={isMobile ? "filter-mobile-item" : "filter-desktop-item"}>
                    <p className={isMobile ? "filter-mobile-label" : "filter-desktop-label"}>{t('Description')}</p>
                    {(Object.entries(descriptionFilter) || []).map(([name, filter]: any[]) => {
                        return (
                            <FilterItem
                                key={`filter-item-${name}`}
                                id={name}
                                title={t(filter.title)}
                                options={filter.options}
                                values={calculateFilterValue(name)}
                                onChange={(values) => filterData(name, values)}
                                className={`filter-${name}`}
                            />
                        )
                    })}
                </div>

                <div className={isMobile ? "filter-mobile-item" : "filter-desktop-item"}>
                    <p className="filter-mobile-label">{t('Status')}</p>
                    {(Object.entries(TOURNAMENT_STATUS_FILTER) || []).map(([name, filter]: any[]) => {

                        return (
                            <FilterItem
                                key={`filter-item-${name}`}
                                id={name}
                                title={t(filter.title)}
                                options={filter.options}
                                values={calculateFilterValue(name)}
                                onChange={(values) => filterData(name, values)}
                                className={`filter-${name}`}
                            />
                        )
                    })}
                </div>


            </div>
        </ModalComponent>
    )
}
export default FilterTournament;
