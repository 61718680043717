import { Plus } from "assets/icons/Plus"
import BtnClose from 'shared/components/button/btn-close';
import IconSvg from 'shared/components/icon-svg';
import LineIconText from 'shared/components/line-icon-text';
import SectionSidebar from 'shared/components/section-sidebar';
import {
  Sound,
  SoundManager,
  matchActions,
  selectActiveMatchId,
  selectBalance,
  selectCurrentUser,
  isPopup,
  selectActiveInteraction,
  Interaction,
  Game,
  User,
  selectCasino,
  cashTableActions,
  selectActiveTableId,
  selectCashTableActiveInteraction,
  gameActions,
  selectChatTableEnabled,
  configActions,
  selectConfigState,
  IFRAME_MSG_TYPE,
  selectCashTableRound,
  selectRound,
  selectUserRanking,
  GAME_ARR,
} from '@quanticogames/gameclient-core';
import { useSelector } from 'react-redux';
import { selectActiveGame } from '@quanticogames/gameclient-core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_ROUTES } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@mui/material';
import ModalInformation from 'shared/components/modal-information';
import { useEffect, useMemo, useState } from 'react';
import ModalOptions from 'shared/components/modal-options';
import { CheatToolCashModal, CheatToolModal, CheatToolScoponeModal, ModalConfirm, CheatToolSolitarioModal } from '@quanticogames/gameclient-components';
import { useDispatch } from 'react-redux';
import { SKIN } from 'constants/skin';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import { SideDrawer } from 'shared/components/side-drawer';
import { Toggle } from 'shared/components/toggle';
import { IFRAME_GAME_ID, REGULATION_URL } from 'constants/common';
import { getGameNameByCode } from 'i18n/i18helper';
import { SoundIcon } from 'assets/icons/SoundIcon';

type SideBarProps = {
  open: boolean;
  onClose: () => void;
};

const SideBar = ({ open, onClose }: SideBarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser: User = useSelector(selectCurrentUser);
  const balance: number = useSelector(selectBalance);
  const balanceFormat = formatMoney(convertCentToEuro(Number(balance)));
  const games: Game = useSelector(selectActiveGame);
  const [openModalMatchInfo, setModalMatchInfo] = useState<boolean>(false);
  const [openModalOptions, setModalOptions] = useState<boolean>(false);
  const [openModelLeave, setModelLeave] = useState<boolean>(false);
  const skinType: string = useSelector(selectCasino) || 'quantico';
  const isCashTable = window.location.href.includes(PATH_ROUTES.TABLE);
  const activeInteractionSelector = isCashTable ? selectCashTableActiveInteraction : selectActiveInteraction;
  const activeInteraction: Interaction = useSelector(activeInteractionSelector());
  const id: number = useSelector(isCashTable ? selectActiveTableId : selectActiveMatchId);
  const [searchParams] = useSearchParams();
  const chatTableEnabled: boolean = useSelector(selectChatTableEnabled);
  const soundEnabled: boolean = useSelector(selectConfigState).sound;
  const configState = useSelector(selectConfigState);
  const CheatToolModalComponent = isCashTable ? CheatToolCashModal : CheatToolModal;
  const round: any = useSelector(isCashTable ? selectCashTableRound() : selectRound());
  const userRanking = useSelector(selectUserRanking)
  const isCurrentPlayerTurn = activeInteraction?.playerId === currentUser?.id;
  const gamePath = useMemo(() => {
      const arr = location?.pathname.split('/')?.filter(item => item !== '');
      return arr[arr.length - 1];
    }, [location?.pathname]);

  const onLeaveTable = () => {
    setModelLeave(true);
    onClose();
  };

  const acceptLeaveTable = () => {
    SoundManager.play(Sound.cardSelection);
    const isSitAndGo = window.location.href.includes(PATH_ROUTES.GAME);
    const currentId = id ?? (searchParams.get('id') || null);
    if (currentId) {
      if (isCashTable) {
        const frame = document.querySelector<HTMLObjectElement>(`#${IFRAME_GAME_ID}`);
        if (frame) {
          frame.contentWindow.postMessage({ type: IFRAME_MSG_TYPE.LEAVE_CASH_TABLE }, '*');
          return
        };
        dispatch(
          cashTableActions.leaveTable({
            tableId: Number(currentId),
            playerId: currentUser?.id,
          })
        );
      } else if (isSitAndGo) {
        dispatch(matchActions.leaveMatch({ matchId: Number(currentId), action: 'LeaveMatch' }));
        dispatch(matchActions.updateActiveMatches({}));
      }
      if (isPopup) window.close();
      else goToTheLobby();
    }
  };

  const goToTheLobby = () => {
    dispatch(gameActions.goLobby({ gameId: games.id }));
  };

  const cancelLeaveTable = () => {
    setModelLeave(false);
  };

  // cheat logic
  const [isOpenCheat, setIsOpenCheat] = useState<boolean>(false);

  const onCheat = () => {
    const currentId = id || parseInt(searchParams.get('id'));
    if (GAME_ARR.includes(gamePath)) {
      const frame = document.querySelector<HTMLObjectElement>(`#${IFRAME_GAME_ID}`);
      frame.contentWindow.postMessage({
        type: "cheat",
        data: {
          id: currentId,
          isCashTable: isCashTable,
        },
      }, '*');

      onClose();
      return;
    }

    onClose();
    setIsOpenCheat(true);

    if (process.env.REACT_APP_ENABLE_CHEATTOOL == 'enable') {
      const actions = isCashTable ? cashTableActions : matchActions;
      const params = {
        playerIds: [currentUser.id],
        action: 'Cheat',
        inputValue: '{"type":0}',
        ...(isCashTable
          ? {
            tableId: id,
          }
          : {
            matchId: id,
          }),
      };
      dispatch(actions.loadCheat(params));
    }
  };

  useEffect(() => {
    const cb = (e: any) => {
      if (e?.data?.type === IFRAME_MSG_TYPE.LEAVE_CASH_TABLE) {
        if (isPopup) window.close();
        else goToTheLobby();
      }
    }
    window.addEventListener('message', cb);
    return () => {
      window.removeEventListener('message', cb);
    }
  }, [isPopup, games?.id]);

  const gameName = getGameNameByCode(games?.code);

  return (
    <>
      <SideDrawer showHeader={false} open={open} onClose={onClose} anchor="left" key="left" className="side-bar-game">
        <div className="flex flex-col justify-between h-full">
          <div className="">
            <SectionSidebar className="sidebar-top px-[24px] py-[24px] flex justify-between items-center gap-1">
              <div className="logo flex-1">
                <img src={`/images/logo/skin-sidebar/${SKIN[skinType].logoHeader}.png`} alt={skinType} className='max-h-full' />
              </div>

              <div className="flex items-center">
                <div className="flex items-center mr-[18px] justify-end">
                  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13 10.5C13.4333 10.5 13.7917 10.3583 14.075 10.075C14.3583 9.79167 14.5 9.43333 14.5 9C14.5 8.56667 14.3583 8.20833 14.075 7.925C13.7917 7.64167 13.4333 7.5 13 7.5C12.5667 7.5 12.2083 7.64167 11.925 7.925C11.6417 8.20833 11.5 8.56667 11.5 9C11.5 9.43333 11.6417 9.79167 11.925 10.075C12.2083 10.3583 12.5667 10.5 13 10.5ZM2.3 17.5C1.8 17.5 1.375 17.325 1.025 16.975C0.675 16.625 0.5 16.2 0.5 15.7V2.3C0.5 1.8 0.675 1.375 1.025 1.025C1.375 0.675 1.8 0.5 2.3 0.5H15.7C16.2 0.5 16.625 0.675 16.975 1.025C17.325 1.375 17.5 1.8 17.5 2.3V5.025H16V2.3C16 2.21667 15.971 2.146 15.913 2.088C15.8543 2.02933 15.7833 2 15.7 2H2.3C2.21667 2 2.146 2.02933 2.088 2.088C2.02933 2.146 2 2.21667 2 2.3V15.7C2 15.7833 2.02933 15.8543 2.088 15.913C2.146 15.971 2.21667 16 2.3 16H15.7C15.7833 16 15.8543 15.971 15.913 15.913C15.971 15.8543 16 15.7833 16 15.7V12.975H17.5V15.7C17.5 16.2 17.325 16.625 16.975 16.975C16.625 17.325 16.2 17.5 15.7 17.5H2.3ZM10.3 13.5C9.8 13.5 9.375 13.325 9.025 12.975C8.675 12.625 8.5 12.2 8.5 11.7V6.3C8.5 5.8 8.675 5.375 9.025 5.025C9.375 4.675 9.8 4.5 10.3 4.5H16.7C17.2 4.5 17.625 4.675 17.975 5.025C18.325 5.375 18.5 5.8 18.5 6.3V11.7C18.5 12.2 18.325 12.625 17.975 12.975C17.625 13.325 17.2 13.5 16.7 13.5H10.3ZM16.7 12C16.7833 12 16.8543 11.9707 16.913 11.912C16.971 11.854 17 11.7833 17 11.7V6.3C17 6.21667 16.971 6.14567 16.913 6.087C16.8543 6.029 16.7833 6 16.7 6H10.3C10.2167 6 10.146 6.029 10.088 6.087C10.0293 6.14567 10 6.21667 10 6.3V11.7C10 11.7833 10.0293 11.854 10.088 11.912C10.146 11.9707 10.2167 12 10.3 12H16.7Z"
                      fill="#828B92"
                    />
                  </svg>
                  <span
                    title={`€ ${balanceFormat}`}
                    className="ml-[10px] text-[#000] text-ellipsis overflow-hidden max-w-[100px] whitespace-nowrap"
                  >
                    € {balanceFormat}
                  </span>
                </div>
                <a className="mr-2" href="#">
                  <Plus />
                </a>
              </div>
            </SectionSidebar>

            <SectionSidebar className="sidebar-logo flex items-center justify-between px-[24px] py-[24px]">
              <div className="flex items-center">
                <img
                  src={`/images/common/logo/logo-game-${games?.code || 'Scopa'}.png`}
                  alt=""
                  width=""
                  height=""
                  className="w-[36px] h-[36px]"
                />
                <div className="logo-text ml-[16px]">
                  <h3 className="text-[#0B0E11] font-raleway mb-0 font-bold text-[18px] sm:text-[24px]">
                    {gameName}
                  </h3>
                </div>
              </div>
              <BtnClose handleClick={onClose} />
            </SectionSidebar>

            <SectionSidebar className="sidebar-user flex items-center px-[24px] py-[24px]">
              <Avatar
                alt={currentUser?.userName}
                src={`/images/avatar/${currentUser?.avatar}` || currentUser?.userName}
                sx={{ width: 56, height: 56 }}
                className={`profile-avatar-image ranking-vip-${userRanking}`}
              />
              <h4 className="ml-[16px] mb-0 flex flex-col">
                <span className="leading-[24px]">{t('Hello')}</span>
                <span className="font-bold leading-[24px]">{currentUser?.userName}</span>
              </h4>
            </SectionSidebar>

            <SectionSidebar className="sidebar-info">
              <LineIconText
                icon={<IconSvg typeIcon="info" />}
                text={t('Match Information')}
                arrowRight={true}
                handleClick={() => {
                  setModalMatchInfo(true), onClose();
                }}
              />

              <LineIconText
                icon={<IconSvg typeIcon="regulation" />}
                text={t('Regulation')}
                arrowRight={true}
                link={`${REGULATION_URL}/game?tabs=${games?.code}&casino=${skinType}`}
              />
            </SectionSidebar>

            <SectionSidebar className="sidebar-info">
              <LineIconText
                icon={<IconSvg typeIcon="setting" />}
                text={t('Options')}
                arrowRight={true}
                handleClick={() => {
                  setModalOptions(true), onClose();
                }}
              />
            </SectionSidebar>
            <SectionSidebar className="sidebar-info">
              <LineIconText
                icon={<SoundIcon />}
                text={t('Table audio')}
                handleClick={() => dispatch(configActions.updateSetting({ ...configState, sound: !configState.sound, notCallApi: false }))}
              >
                <Toggle checked={soundEnabled} sx={{ '.MuiSwitch-track': { opacity: '1!important' } }} />
              </LineIconText>
            </SectionSidebar>

            <SectionSidebar className="sidebar-info">
              <LineIconText
                icon={<IconSvg typeIcon="icon-chat" />}
                text={t('Table chat')}
                handleClick={() => dispatch(configActions.updateSetting({ ...configState, chatTable: !configState.chatTable, notCallApi: false }))}
              >
                <Toggle checked={chatTableEnabled} sx={{ '.MuiSwitch-track': { opacity: '1!important' } }} />
              </LineIconText>
            </SectionSidebar>

            {round?.state !== "finished" && (
              <SectionSidebar className="sidebar-info">
                <LineIconText
                  icon={<IconSvg typeIcon="logout" />}
                  text={t('Leave the table')}
                  handleClick={onLeaveTable}
                />
              </SectionSidebar>
            )}

            {process.env.REACT_APP_ENABLE_CHEATTOOL == 'enable' && (
              <SectionSidebar className="sidebar-info">
                <LineIconText icon={<IconSvg typeIcon="setting" />} text={t('Cheat')} handleClick={onCheat} />
              </SectionSidebar>
            )}
            {/* {process.env.REACT_APP_ENABLE_CHEATTOOL == 'enable' && games?.id === 14 && (
              <SectionSidebar className="sidebar-info">
                <LineIconText icon={<IconSvg typeIcon="setting" />} text={t('Cheat')} handleClick={onCheat} />
              </SectionSidebar>
            )} */}
          </div>

          <SectionSidebar>
            {!isPopup && (
              <LineIconText icon={<IconSvg typeIcon="home" />} text={t('Home')} handleClick={goToTheLobby} />
            )}
          </SectionSidebar>
        </div>
      </SideDrawer>

      {openModalMatchInfo && (
        <ModalInformation onClose={() => setModalMatchInfo(false)} openModalMatchInfo={openModalMatchInfo} isCashTable={isCashTable} />
      )}

      {openModalOptions && <ModalOptions onClose={() => setModalOptions(false)} openModalOptions={openModalOptions} />}

      {openModelLeave && (
        <ModalConfirm
          title={t('Do you want to leave the table?')}
          openModalConfirm={openModelLeave}
          textBtnAccept={t('Confirm')}
          textBtnCancel={t('Cancel')}
          handleAccept={acceptLeaveTable}
          handleCancel={cancelLeaveTable}
          onClose={cancelLeaveTable}
        >
          <p className="text-[16px] text-center my-[10px]">{t('Confirm leave table')}</p>
        </ModalConfirm>
      )}

      {!GAME_ARR.includes(gamePath) && 
        <>
          {process.env.REACT_APP_ENABLE_CHEATTOOL == 'enable' && games?.id !== 13 && games?.id !== 14 && (
            <CheatToolModalComponent isOpenCheat={isOpenCheat} setIsOpenCheat={setIsOpenCheat} />
          )}
          {process.env.REACT_APP_ENABLE_CHEATTOOL == 'enable' && games?.id === 13 && <CheatToolScoponeModal isOpenCheat={isOpenCheat} setIsOpenCheat={setIsOpenCheat} />}
          {process.env.REACT_APP_ENABLE_CHEATTOOL == 'enable' && games?.id === 14 && <CheatToolSolitarioModal isOpenCheat={isOpenCheat} setIsOpenCheat={setIsOpenCheat} />}
        </>
      }
    </>
  );
};
export default SideBar;
