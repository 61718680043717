/* eslint-disable */
export function mapStyleGame(game: string) {
    switch (game) {
        case 'Tresette':
            return {
                background: '#D53900',
                backgroundGradient: 'linear-gradient(180deg, #F98850 0%, #E95A14 51.67%, #983A0C 100%)',
                color: '#fff',
                fullName: 'Tresette',
                shortName: 'Tre',
            };
        case 'Solitario':
            return {
                background: '#0055BD',
                backgroundGradient: '#0055BD',
                color: '#fff',
                fullName: 'Solitario',
                shortName: 'Soli',
            };
        case 'SetteEMezzo':
            return {
                background: '#86EB25',
                backgroundGradient: '#86EB25',
                color: '#fff',
                fullName: 'Sette E Mezzo',
                shortName: 'Sette',
            };
        case 'Scopone':
            return {
                background: '#14A2EA',
                backgroundGradient: '#14A2EA',
                color: '#fff',
                fullName: 'Scopone',
                shortName: 'Scopone',
            };
        case 'Scopa':
            return {
                background: '#E90012',
                backgroundGradient: '#E90012',
                color: '#fff',
                fullName: 'Scopa',
                shortName: 'Scopa',
            };
        case 'Scala40':
            return {
                background: '#00D1EB',
                backgroundGradient: '#00D1EB',
                color: '#fff',
                fullName: 'Scala 40',
                shortName: 'Scala',
            };
        case 'ItalianPoker':
            return {
                background: '#0042EE',
                backgroundGradient: '#0042EE',
                color: '#fff',
                fullName: 'Italian Poker',
                shortName: 'Poker',
            };
        case 'Peppa':
            return {
                background: '#5300EC',
                backgroundGradient: '#5300EC',
                color: '#fff',
                fullName: 'Peppa',
                shortName: 'Peppa',
            };
        case 'Cirulla':
            return {
                background: '#00D7CD',
                backgroundGradient: '#00D7CD',
                color: '#fff',
                fullName: 'Cirulla',
                shortName: 'Cirulla',
            };
        case 'Burraco':
            return {
                background: '#E8DE00',
                backgroundGradient: '#E8DE00',
                color: '#fff',
                fullName: 'Burraco',
                shortName: 'Burraco',
            };
        case 'Briscola':
            return {
                background: '#0FC000',
                backgroundGradient: '#0FC000',
                color: '#fff',
                fullName: 'Briscola',
                shortName: 'Briscola',
            };
        case 'Bestia':
            return {
                background: '#00E929',
                backgroundGradient: '#00E929',
                color: '#fff',
                fullName: 'Bestia',
                shortName: 'Bestia',
            };
        case 'AssoPigliaTutto':
            return {
                background: '#C2EB14',
                backgroundGradient: '#C2EB14',
                color: '#fff',
                fullName: 'Asso Piglia Tutto',
                shortName: 'Asso',
            };
        case 'Telesina':
            return {
                background: '#E95A14',
                backgroundGradient: '#E95A14',
                color: '#fff',
                fullName: 'Telesina',
                shortName: 'Teles',
            };
        case 'Trentuno':
            return {
                background: '#dcf151',
                backgroundGradient: '#dcf151',
                color: '#fff',
                fullName: 'Trentuno',
                shortName: 'Tren',
            };
        case 'GinRummy':
            return {
                background: '#9b00c1',
                backgroundGradient: '#9b00c1',
                color: '#fff',
                fullName: 'GinRummy',
                shortName: 'Gin',
            };
        default:
            return {
                background: '',
                backgroundGradient: '',
                color: '#fff',
                fullName: '',
                shortName: '',
            }
    }
}