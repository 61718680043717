import { CashTable, convertCentToEuro, formatMoney, selectCashTable } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CashTableInformation = () => {
    const { t } = useTranslation();
    const table: CashTable = useSelector(selectCashTable);

    return (
        <>
            {table && (
                <div className='tournament-information'>
                    <p>{t("Table Code")}: {table?.code}</p>
                    {
                        table?.aamsCode && <p>{t("AAMS Code")}: {table?.aamsCode}</p>
                    }
                    <p>{t('Table ID')}: {table?.id}</p>
                    <p>{t('Mode')}: {t('Cash Table')}</p>
                    <p>{t('Ante')}: € {formatMoney(convertCentToEuro(table.cost || table.invitation))}</p>
                </div>
            )}
        </>
    );
};

export default CashTableInformation;
