import { selectClubMode } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalComponent from 'shared/components/modal';
import "./club.scss";
import { useNavigate } from 'react-router';
import { PATH_ROUTES } from 'constants/routes';

const ModalClubDeactivate = () => {
  const { t } = useTranslation('club');
  const clubModeOpen = useSelector(selectClubMode);
  const navigate = useNavigate();
  const handleClose = () => {
      navigate(PATH_ROUTES.SIT_AND_GO)
  }
  return (
    <ModalComponent 
      open={!clubModeOpen} 
      onClose={handleClose} 
      className="modal-club-canceled"
      size='small'
      title={t("Notification")}
      showFooter={false}
    >
      <p>{t('Deactivate club')}</p>
    </ModalComponent>
  );
};

export default ModalClubDeactivate;
