/* eslint-disable */
import {
  Game,
  ParticipantStatusEnum,
  Sound,
  SoundManager,
  Tournament,
  TournamentConfig,
  TournamentStateEnum,
  User,
  generateTournamentTitle,
  selectActiveGame,
  selectBalanceInfo,
  selectCurrentUser,
  selectOrientation,
  tournamentActions,
  tournamentApi,
} from '@quanticogames/gameclient-core';
import LockIcon from 'assets/icons/Lock';
import { TOURNAMENT_TYPE } from 'constants/common';
import moment from 'moment';
import { TournamentBadge, TournamentCost, TournamentFavorite, TournamentLine, TournamentPlayer, TournamentPrizeUpTo, TournamentStart, TournamentStatus } from 'pages/tournament/component/list/tournament-cell-mobile';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FlexBox } from 'shared/Flex';
import ButtonComponent from 'shared/components/button'
import PinActiveIcon from 'assets/icons/PinActive';
import PinIcon from 'assets/icons/Pin';

type TournamentItemMobileProps = {
  index: number;
  data: Tournament | any;
  onSelect: (id: number, index?: number) => void;
  onAction: (action: string, id: number, index?: number) => void;
  className?: string;
  isFake?: boolean;
  favorite?: {
    id: number;
    favoriteId: number;
  };
  handlePlayScheduled?: (id: number) => void;
};

const TournamentItemMobile = ({ index, data, onSelect, onAction, className, isFake, favorite, handlePlayScheduled }: TournamentItemMobileProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser: User = useSelector(selectCurrentUser);
  const location = useLocation();
  const path = location.pathname.split('/');
  const id = Number(path[path.length - 1]) || data?.id;
  const game: Game = useSelector(selectActiveGame);
  const balance = useSelector(selectBalanceInfo);
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const isScheduled = window.location.pathname.includes('tournaments');


  const handleActionFavorite = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (favorite) {
      dispatch(tournamentActions.removeFavoriteTournament({
        id: favorite?.favoriteId,
      }));
      await tournamentApi.updateFavoriteTournament(favorite?.favoriteId, false);
      return;
    }
    try {
      const response: TournamentConfig = await tournamentApi.createFavoriteTournament(data?.id);

      dispatch(tournamentActions.addFavoriteTournament({
        config: response,
      }));
    }
    catch (error) {
      console.error(error);
    }
  };
  const renderTitle = useMemo(() => {
    return generateTournamentTitle({
      game: game,
      ...data,
      template: 'standard',
      demo: isFake
    }, t);
  }, [data, isFake, t]);


  const handleTournamentsDetail = () => {
    SoundManager.play(Sound.clickButton);
    onSelect(data.id, index);
  };

  const participantsLength = data?.participants?.length;
  const maxPlayers = data?.maxPlayers;
  const registeredNumber = data?.registered;
  let nonClickable =
    data?.state === TournamentStateEnum.scheduled
    || data?.state === TournamentStateEnum.closed
    || data?.state === TournamentStateEnum.terminated
    || data?.currentPlayerStatus == ParticipantStatusEnum.Eliminated
    || data?.participants?.some(item => item.userId === currentUser.id && item.status === ParticipantStatusEnum.Eliminated);

  const isSubscribed = data?.isSubscribed;
  const canPlay = data?.state === TournamentStateEnum.inprogress && isSubscribed;
  const canSubscribe = !isSubscribed && data.state != TournamentStateEnum.inprogress;
  const canUnsubscribe =
    isSubscribed && data.state != TournamentStateEnum.closed && data.state != TournamentStateEnum.inprogress;

  const currentTime = moment.utc();
  const startingTime = moment.utc(data.startingTime);
  const duration = startingTime.diff(currentTime, 'seconds');
  const isColpoGrosso = data?.type === TOURNAMENT_TYPE.CRAZY_PRIZE;

  const renderFavoriteIcon = useMemo(
    () => () => {
      return favorite?.id ? <PinActiveIcon /> : <PinIcon />
    },
    [favorite]
  );
  const isMultipleMatch = data?.type === 'Crazy_Prize' || data?.type === 'Scheduled' || data?.type === 'Treble' || data?.parentGuaranteeId // Satellite;
  const isNoneClickSpectator = data?.state === TournamentStateEnum.inprogress && isMultipleMatch && !isSubscribed;

  const TournamentButton = useMemo(() => {
    return (
      <>
        {canPlay && (
          <div className="flex items-center">
            <ButtonComponent
              variant="outlined"
              title={t('Play')}
              disabled={nonClickable}
              rouned="large"
              size="large"
              color="primary"
              fullWidth
              className="lobby-button"
              onClick={(e) => {
                e.stopPropagation();
                if (data?.type === 'Scheduled' || data?.parentGuaranteeId) {
                  handlePlayScheduled(data.id);
                } else {
                  onAction('play', id);
                }

              }}
              startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '11px', bottom: '13px' }}><LockIcon /></span>}
            />
          </div>
        )}

        {canSubscribe && (
          <ButtonComponent
            variant="contained"
            title={t('Subscribe')}
            rouned="large"
            size="large"
            color="success"
            fullWidth
            className="lobby-button subscribe-button"
            disabled={
              nonClickable ||
              balance === undefined ||
              participantsLength == maxPlayers ||
              maxPlayers === registeredNumber
            }
            onClick={(e) => {
              e.stopPropagation();
              onAction('subscribe', data);
            }}
            startIcon={data?.hasPassword && <span style={{ position: 'absolute', left: '9px', bottom: '14px' }}><LockIcon fill='white' /></span>}
          />
        )}

        {canUnsubscribe && (
          <ButtonComponent
            variant="outlined"
            title={t('Unsubscribe')}
            className="lobby-button"
            disabled={nonClickable || data.isSatelliteSubscriber}
            color="error"
            rouned="large"
            size="large"
            fullWidth
            onClick={(e) => {
              e.stopPropagation();
              onAction('unsubscribe', data.id);
            }}
          />
        )}

        {!isSubscribed && data?.state === TournamentStateEnum.inprogress && (
          <ButtonComponent
            variant="outlined"
            title={t('Spectator')}
            size="large"
            rouned="large"
            color="primary"
            fullWidth
            disabled={isNoneClickSpectator}
            onClick={(e) => {
              e.stopPropagation();
              onAction('play', data.id);
            }}
          />
        )}
      </>
    );
  }, [canPlay, canSubscribe, canUnsubscribe, data, id, nonClickable,]);



  return (
    <FlexBox
      alignItems={isMobileLandscape ? 'flex-start' : 'center'}
      className={`tournament-mobile-item ${isScheduled ? 'tournament-mobile-item-schedule' : ''} type-${data.type} ${className}`}
      onClick={handleTournamentsDetail}
    >
      {!isScheduled && <TournamentFavorite data={data} handleActionFavorite={handleActionFavorite} renderFavoriteIcon={renderFavoriteIcon} />}
      {isScheduled && <div className='w-[30px]' />}
      <FlexBox
        gap={isMobileLandscape ? 15 : 3}
        flexDirection={isMobileLandscape ? 'row' : 'column'}
        alignItems={isMobileLandscape ? 'center' : 'flex-start'}
        width="100%"
        className={`tournament-mobile-content tournament-mobile-content-${data?.id} flex-1 ${isMobileLandscape ? 'tournament-mobile-content-landscape' : ''}`}
      >
        {isMobileLandscape ? (
          <div className='flex-1'>
            <div className='flex justify-between gap-[10px]'>
              <div className='flex-1'>
                <p className={"tournament-des-title-schedule-mobile"}>{renderTitle}</p>
                <div className='flex gap-[10px] absolute bottom-0 w-full items-start'>
                  <TournamentBadge data={data} />
                  <TournamentPlayer data={data} />
                  {isColpoGrosso && <TournamentPrizeUpTo cost={data?.cost} />}
                  {isScheduled && <TournamentStart data={data} duration={duration} />}
                </div>
              </div>
              <div className='w-[130px]'>
                <TournamentCost data={data} />
              </div>
              <div className='w-[85px] self-center'>
                <TournamentStatus data={data} />
              </div>

              <div className='w-[120px]'>
                {TournamentButton}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='flex justify-between w-full gap-[20px]'>
              <div className="flex-1">
                <p className={"tournament-des-title-schedule-mobile"}>{renderTitle}</p>
              </div>
              <div className='w-[100px]'>
                {TournamentButton}
              </div>

            </div>
            <TournamentLine>
              <TournamentCost data={data} />
              <TournamentStatus data={data} />
              <TournamentPlayer data={data} />
            </TournamentLine>
            <TournamentLine>
              {isScheduled && <TournamentStart data={data} duration={duration} />}
              {isColpoGrosso ? <TournamentPrizeUpTo cost={data?.cost} /> : <div></div>}
              <TournamentBadge data={data} />
            </TournamentLine>
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};
export default TournamentItemMobile;
