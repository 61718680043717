import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectConfigState, SoundManager, soundManager, backgroundMusicManager, useCheckLobbyPage, dismissToasts, Sound, GameHelper, authEvent, selectLogoutNotification, BackgroundMusicManager } from '@quanticogames/gameclient-core';
import { isMobileApp } from 'constants/common';
import { isMobile } from 'react-device-detect';


const useSound = () => {
    const configState = useSelector(selectConfigState);
    const { sound, backgroundMusic, soundVolume } = configState
    const isLobby = useCheckLobbyPage();
    const isApp = isMobileApp()
    const isForceLogout = useSelector(selectLogoutNotification) as boolean;
    const [appState, setAppState] = React.useState('inactive'); // active, background, inactive

    function playBackgroundMusic() {
       const isPlaying = BackgroundMusicManager.playing(Sound.backgroundLobby.toString());
         if (!isPlaying) {
              backgroundMusicManager.play(Sound.backgroundLobby.toString());
         }
    }

    function stopBackgroundMusic() {
        backgroundMusicManager.stop(Sound.backgroundLobby.toString());
    }

    useEffect(() => {
        if (sound) {
            SoundManager.turnOffSound(false);
        } else {
            SoundManager.turnOffSound(true);
        }
    }, [sound]);

    useEffect(() => {
        soundManager.setVolume(soundVolume);
        backgroundMusicManager.setVolume(soundVolume);
    }, [soundVolume]);

    useEffect(() => {
        if (!isLobby) {
            soundManager.setVolume(0);
            stopBackgroundMusic();
        }
    }, [isLobby]);


    const turnOffSound = () => {
        soundManager.setVolume(0);
    }

    const turnOnSound = () => {
        soundManager.setVolume(1);

    }

    const onFocus = () => {
        if (!isMobile) return;
        turnOnSound();
    };

    const onBlur = () => {
        if (!isMobile) return;
        turnOffSound();
    };

    useEffect(() => {
        if (!isLobby) {
            turnOffSound();
            return;
        }
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        turnOnSound();
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, [isLobby]);

    useEffect(() => {
        if (isApp) {
            const msgHandler = function (event) {
                const data = event.data;
                if (data?.type === 'appState') setAppState(data.payload);
            }
            window.addEventListener('message', msgHandler);
            return () => window.removeEventListener('message', msgHandler);
        }
    }, [isApp]);

    useEffect(() => {
        if (appState === 'background') {
            soundManager.setVolume(0);
            // backgroundMusicManager.setVolume(0);
            stopBackgroundMusic();

        }
        if (appState === 'active') {
            soundManager.setVolume(1);
            playBackgroundMusic();
        }
    }, [appState]);

    useEffect(() => {
        authEvent.on("authentication-fail", () => {
            stopBackgroundMusic();
            dismissToasts();
        });
    }, [authEvent]);

    useEffect(() => {
        if (isForceLogout) {
            turnOffSound();
            stopBackgroundMusic();
        }
    }, [isForceLogout]);

    useEffect(() => {
        if (backgroundMusic) {
            playBackgroundMusic();
        } else {
            stopBackgroundMusic();
        }
    }, [backgroundMusic]);
};

export default useSound;
