import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import './index.scss';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { SortingOrder } from 'types/commonTable';
import ButtonComponent from 'shared/components/button';
import { FilterIcon } from 'assets/icons/FilterIcon';
import SortTable from 'assets/icons/SortTable';
import { isMobile } from 'react-device-detect';
import { GameHelper } from '@quanticogames/gameclient-core';

type TableComponentProps = {
  maxHeight?: number;
  columns: any[];
  onClickSort?: (...arg0: any[]) => void;
  orderBy?: string;
  onFilterModal?: (row) => void;
  children?: React.ReactNode;
  className?: string;
  showHeadOnMobile?: boolean;
};

const StyledTableSortLabel = styled(TableSortLabel)(() => ({}));

const TableComponent = (props: TableComponentProps) => {
  const {
    maxHeight,
    columns,
    onClickSort = (...agrs: any[]) => {},
    onFilterModal,
    orderBy,
    children,
    className,
    showHeadOnMobile = false,
  } = props;
  const { t } = useTranslation();
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [sortColumn, setSortColumn] = useState('');
  const theme = useTheme();

  const handleSort = (column) => {
    setSortDirection((prev) => {
      if (column !== sortColumn) {
        return SortingOrder.ASC;
      }
      switch (prev) {
        case SortingOrder.ASC:
          return SortingOrder.DESC;
        case SortingOrder.DESC:
          return SortingOrder.ASC;
      }
    });
    setSortColumn(column);
  };

  useEffect(() => {
    onClickSort(sortColumn, sortDirection);
  }, [sortColumn, sortDirection]);

  const CustomSortIcon = () => <SortTable />;
  return (
    <TableContainer className={`table-container ${className || ''}`}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns?.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                onClick={onClickSort}
              >
                <StyledTableSortLabel
                    className={`
                        ${column.sortable ? '' : 'hidden-sort'} 
                        ${sortColumn === column.id ? `table-sort-${sortDirection}` : ''}
                        sort-icon
                        text-[16px]
                    `}
                  active={orderBy === column.id}
                  IconComponent={CustomSortIcon}
                  {...(column?.sortable && sortColumn === column.id ? { direction: sortDirection } : {})}
                  onClick={() => column?.sortable && handleSort(column.id)}
                >
                  {t(column.label)}
                </StyledTableSortLabel>
                {column.filter && (
                  <ButtonComponent
                    onClick={() => onFilterModal(column.id)}
                    variant="text"
                    className="filter-btn"
                    icon={<FilterIcon />}
                    size="large"
                    sx={{ minWidth: 'auto', marginLeft: '5px' }}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ maxHeight: { maxHeight } }}>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableComponent;
