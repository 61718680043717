

import { bottom } from 'constants/badges';
import * as yup from 'yup';


export const chipGameIds = [3, 8, 12, 15];

export const schema = yup.object().shape({
    gameId: yup.number().required(),
    type: yup.string().required(),
    cost: yup.number().required(),
    fee: yup.number().required(),
    feeRate: yup.number().required(),
    speed: yup.number().required(),
    objective: yup.number(),
    matchType: yup.string(),
    matchTime: yup.number(),
    initChip: yup.number(),
    maxSeats: yup.number()
})

export const mapMatchType = (type: number, t: any) => {
    switch (type) {
        case 0:
            return {
                label: t('Head to head'),
                value: 0,
            }
        case 1:
            return {
                label: t('4 In 2'),
                value: 1,
            }
        case 2:
            return {
                label: t('Two pairs'),
                value: 2,
            }
    }
}

export const mapMatchTypeToLabel = (type: string, t: any) => {
    switch (type) {
        case '4in2':
            return t('4 In 2')
        case 'h2h':
            return t('Head to head')
        case '2pairs':
            return t('Two pairs')
        default:
            return t('Head to head')

    }
}

export const roundNumberCost = (number: number) => {
    return Math.round(number * 100) / 10000
}

const mapCost = (costs: number[], feeRates: number[], t: any) => {
    return costs?.map((cost, index) => {
        const fee = cost * feeRates[index]
        const reward = roundNumberCost(cost - fee)
        return {
            label: cost === 0 ? t('Free') : '€ ' + roundNumberCost(cost) + ` (€ ${reward} + € ${roundNumberCost(fee)})`,
            value: cost,
        }
    })
}

const mapFeeOption = (costs: number[], feeRates: number[], t: any) => {
    return costs?.map((cost, index) => {
        const fee = cost * feeRates[index]
        const reward = roundNumberCost(cost - fee)
        return {
            label: cost === 0 ? t('Free') : '€ ' + roundNumberCost(cost) + ` (€ ${reward} + € ${roundNumberCost(fee)})`,
            value: {
                cost: cost,
                feeRate: feeRates[index],
                fee: fee,
            },
        }
    })
}

export const mapSpeed = (speed: number, t: any) => {
    switch (speed) {
        case 0:
            return {
                label: t("Slow"),
                value: 0
            }
        case 1:
            return {
                label: t('Normal'),
                value: 1
            }
        case 2:
            return {
                label: t('Turbo'),
                value: 2
            }
        case 3:
            return {
                label: t('Super Turbo'),
                value: 3
            }
    }
}

const mapStructure = () => {
    return [
        {
            label: "Sit&Go",
            value: 'SG'
        }
    ]
}

export const mapObjectiveLabel = (objective: string, t: any) => {

    if (objective.includes('m')) {
        const key = objective.split('m')[0]
        return key + " " + (key === '1' ? t('Round') : t('Rounds'))
    }
    if (objective.includes('p')) {
        const key = objective.split('p')[0]
        return key + " " + t('Points')
    }
    if (objective.includes('b')) {
        const key = objective.split('b')[0]
        return t('Best of', { match: key })
    }
    if (objective.includes('r')) {
        const key = objective.split('r')[0]
        return key + " " + t('Points to lose')
    }
    return objective
}

export const parseConfig = (config: string, gameId: number, t: any) => {
    try {
        const configObject = JSON.parse(config)
        const { matchTypes, costs, feeRates, speeds, maxPlayers, objectives } = configObject;

        return {
            matchTypeOption: chipGameIds.includes(gameId) ? null : matchTypes?.map((type: number) => mapMatchType(type, t)),
            costOption: mapCost(costs, feeRates, t),
            feeOption: mapFeeOption(costs, feeRates, t),
            speedOption: speeds?.map((speed: number) => mapSpeed(speed, t)),
            maxPlayers: maxPlayers?.map((maxPlayer: number) => {
                return {
                    label: maxPlayer,
                    value: maxPlayer
                }
            }),
            objectiveOption: objectives?.map((objective: string) => {
                return {
                    label: mapObjectiveLabel(objective, t),
                    value: objective
                }
            }),
            structureOption: mapStructure()
        }
    } catch (error) {
        console.error('Error parsing config', error)
        return {}
    }
}



export const mapMatchTypeToMaxSeats = (matchType: number, gameId: number) => {
    // if([3, 8, 12, 14, 15].includes(gameId)) return null;

    if ([4, 5, 6, 7, 9, 10, 11, 13, 14, 18].includes(gameId)) {
        switch (matchType) {
            case 0:
                return 2;
            case 1:
                return 2;
            case 2:
                return 4;
        }
    }
    if ([16].includes(gameId)) {
        return 4;
    }
}


export const positionFactory = (maxSeats: number) => {
    switch (maxSeats) {
        case 2:
            return [
                {
                    bottom: 140,
                    left: 107,
                }
            ]
        case 3:
            return [

                {
                    bottom: 140,
                    left: 40,
                },
                {
                    bottom: 140,
                    right: 40,
                }
            ]
        case 4:
            return [
                {
                    top: 40,
                    left: -45,
                },
                {
                    bottom: 140,
                    left: 107,
                },
                {
                    top: 40,
                    right: -45,
                }
            ]
        case 5:
            return [
                {
                    top: 40,
                    left: -45,
                },
                {
                    bottom: 140,
                    left: 40,
                },
                {
                    bottom: 140,
                    right: 40,
                },
                {
                    top: 40,
                    right: -45,
                }
            ]
        case 6:
            return [
                {
                    top: 0,
                    left: -35,
                },

                {
                    bottom: 140,
                    left: 40,
                },
                {
                    bottom: 140,
                    left: 107,
                },
                {
                    bottom: 140,
                    right: 40,
                },
                {
                    top: 0,
                    right: -35,
                }
            ]
        case 7:
            return [
                {
                    bottom: -35,
                    left: 0,
                    nameBottom: true
                },
                {
                    top: 0,
                    left: -35,
                },
                {
                    bottom: 140,
                    left: 40,
                },
                {
                    bottom: 140,
                    right: 40,
                },
                {
                    top: 0,
                    right: -35,
                },
                {
                    bottom: -35,
                    right: 0,
                    nameBottom: true
                }
            ]
        case 8:
            return [
                {
                    bottom: -35,
                    left: 0,
                    nameBottom: true,
                },
                {
                    top: 0,
                    left: -38,
                },
                {
                    bottom: 140,
                    left: 40,
                },
                {
                    bottom: 140,
                    left: 107,
                },
                {
                    bottom: 140,
                    right: 40,
                },
                {
                    top: 0,
                    right: -38,
                },
                {
                    bottom: -35,
                    right: 0,
                    nameBottom: true,
                }
            ]
        case 9:
            return [
                {
                    bottom: -46,
                    left: 40,
                    nameBottom: true
                },
                {
                    top: 80,
                    left: -40,
                    nameBottom: true
                },
                {
                    top: 0,
                    left: -38,
                },
                {
                    bottom: 140,
                    left: 60,
                },
                {
                    bottom: 140,
                    right: 60,
                },
                {
                    top: 0,
                    right: -38,
                },
                {
                    top: 80,
                    right: -40,
                    nameBottom: true

                },
                {
                    bottom: -46,
                    right: 40,
                    nameBottom: true
                }
            ]
        case 10:
            return [
                {
                    bottom: -46,
                    left: 40,
                    nameBottom: true
                },
                {
                    top: 80,
                    left: -40,
                    nameBottom: true
                },
                {
                    top: 0,
                    left: -38,
                },
                {
                    bottom: 140,
                    left: 30,
                },
                {
                    bottom: 140,
                    left: 107,
                },
                {
                    bottom: 140,
                    right: 30,
                },
                {
                    top: 0,
                    right: -38,
                },
                {
                    top: 80,
                    right: -40,
                    nameBottom: true

                },
                {
                    bottom: -46,
                    right: 40,
                    nameBottom: true
                }
            ]
        default:
            return []
    }
}