import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'
import rootSaga from "./rootSaga";
import { createRouterMiddleware, createRouterReducer } from '@lagunovsky/redux-react-router'
import { combineReducers } from 'redux'
import { createBrowserHistory } from 'history'
import { chatReducer, gameReducer, tournamentReducer, cashTableReducer, configReducer, alertMessageReducer } from '@quanticogames/gameclient-core';
import { matchReducer } from '@quanticogames/gameclient-core';
import { authReducer } from '@quanticogames/gameclient-core';
import { concatenateReducers } from 'utils/concatenateReducers';
// import { tresetteReducer } from '../games/tresette';
import { cirullaReducer } from 'games/cirulla';
// import { bestiaReducer } from 'games/bestia';
// import { scoponeReducer } from 'games/scopone';
// import { telesinaCashReducer } from 'games/cashTables/telesina';
// import { solitarioReducer } from 'games/solitario';
// import { telesinaReducer } from 'games/telesina';
// import { peppaReducer } from 'games/peppa';
import { trentunoReducer } from 'games/trentuno';
import { ginRummyReducer } from 'games/ginRummy';
import * as Sentry from '@sentry/react';

const sendFailBack = (error, detail) => {
    Sentry.captureMessage('Saga error', {
        level: "error",
        extra: {
            error: error,
            detail: detail
        }
    });
}

export const history = createBrowserHistory()
const routerMiddleware = createRouterMiddleware(history)

const matchesReducer = concatenateReducers([
    // tresetteReducer,
    // cirullaReducer,
    // bestiaReducer,
    // scoponeReducer,
    // solitarioReducer,
    // telesinaReducer,
    // peppaReducer,
    trentunoReducer,
    ginRummyReducer,
    matchReducer
]);

const cashTablesReducer = concatenateReducers([
    cashTableReducer,
]);

const rootReducer = combineReducers({
    router: createRouterReducer(history),
    auth: authReducer,
    chat: chatReducer,
    game: gameReducer,
    tournament: tournamentReducer,
    cashTable: cashTablesReducer,
    match: matchesReducer,
    config: configReducer,
    alertMessage: alertMessageReducer,
})

const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error, detail: { sagaStack: string }) => {  
        console.log("Error Saga: ", error);
        console.log(detail.sagaStack);
        sendFailBack(error, detail.sagaStack)
    }
});
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware, routerMiddleware),
});

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
