import Loading from 'shared/components/loading';
import { useEffect, useMemo, useState } from 'react';
import TableNoData from 'shared/components/tables/component/table-nodata';
import { SortingOrder } from 'types/commonTable';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  CashTable,
  CashTableActiveState,
  Game,
  GameHelper,
  User,
  cashTableActions,
  selectActiveGame,
  selectCashFilterOptions,
  selectCashSortOptions,
  selectCashTables,
  selectCashTablesLoading,
  selectCurrentUser,
} from '@quanticogames/gameclient-core';
import useDrawerToggle from 'hooks/useDrawerToggle';
import { DRAWER_POSITIONING } from 'types/rightSideDrawer';
import { SideDrawer } from 'shared/components/side-drawer';
import { CashGameDetail } from 'pages/cash-table/component/detail';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CashListDesktop from 'shared/components/CashList/desktop';
import CashListMobile from 'shared/components/CashList/mobile';

const TableCash = () => {

  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = GameHelper.isMobile();
  const dispatch = useDispatch();
  const loading: boolean = useSelector(selectCashTablesLoading);
  const tables: CashTable[] = useSelector(selectCashTables);
  const sortOption = useSelector(selectCashSortOptions);
  const { drawerAnchor, toggleDrawer } = useDrawerToggle();
  const handleClose = () => toggleDrawer(DRAWER_POSITIONING.RIGHT, false);
  const filterOptions: any = useSelector(selectCashFilterOptions)
  const currentUser: User = useSelector(selectCurrentUser);
  const game: Game = useSelector(selectActiveGame);
  const [oldGameMode, setOldGameMode] = useState<string>(null)

  const { t } = useTranslation();
  let listCashTable = [];

  const onSelect = (id: number) => {
    dispatch(cashTableActions.loadCashTable(id));
    toggleDrawer(DRAWER_POSITIONING.RIGHT, true);
  };

  const isUserInTournament = (tournament, id) => tournament.participants?.some((p) => p.userId === id);

  const isUserSubscribe = (tournament, id) =>
    isUserInTournament(tournament, id) &&
    tournament?.state !== CashTableActiveState.Pending &&
    tournament?.state !== CashTableActiveState.Inprogress;

  if (tables.length > 0 && currentUser !== null && currentUser !== undefined) {
    listCashTable = tables.filter((cashtb) => {
      let filter = true;

      filter =
        cashtb?.state !== CashTableActiveState.Cancelled &&
        cashtb?.state !== CashTableActiveState.Terminated;

      if (filter && game?.id !== cashtb.gameId) {
        filter = false;
      }

      if (filter) {
        filter = filter && Boolean(cashtb?.numberOfSeats);
      }
      if (filter && filterOptions?.costRanges && filterOptions.costRanges.length > 0) {

        if (game.code === 'SetteEMezzo') {
          filter = !!filterOptions.costRanges.find(
            (range) => cashtb.cost <= range.costTo && cashtb.cost >= range.costFrom
          );
        }
        else {
          filter = !!filterOptions.costRanges.find(
            (range) => cashtb.invitation <= range.costTo && cashtb.invitation >= range.costFrom
          );;
        }
      }
      if (filter && filterOptions?.code && cashtb?.code) {
        const cashtbConvert = {
          ...cashtb,
          titleConvert: `${cashtb.id} - ${cashtb.title} - Cash`,
        };

        filter = cashtb.code.toLowerCase().includes(filterOptions.code.toLowerCase()) || cashtbConvert.titleConvert.toLowerCase().includes(filterOptions.code.toLowerCase());
      }
      if (filter && filterOptions?.state && cashtb?.state) {
        filter = cashtb?.activeCount !== cashtb?.numberOfSeats

      }
      if (filterOptions?.maxPlayers?.length > 0 && cashtb?.numberOfSeats && filter) {
        filter = filterOptions.maxPlayers.indexOf(cashtb.numberOfSeats) > -1;
      }
      return filter;
    });

    listCashTable.sort((a, b) => {
      const isUserInTournamentA = isUserInTournament(a, currentUser.id);
      const isUserInTournamentB = isUserInTournament(b, currentUser.id);

      if (isUserInTournamentA && !isUserInTournamentB) return -1;
      if (!isUserInTournamentA && isUserInTournamentB) return 1;
    });

    listCashTable.sort((a, b) => {
      const isUserSubscribeA = isUserSubscribe(a, currentUser.id);
      const isUserSubscribeB = isUserSubscribe(b, currentUser.id);

      if (isUserSubscribeA && !isUserSubscribeB) return -1;
      if (!isUserSubscribeA && isUserSubscribeB) return 1;
    });
  }

  const sortListCashTables = useMemo(() => {
    if (sortOption.sortColumn && sortOption.sortDirection) {
      const dir = sortOption.sortDirection === SortingOrder.ASC ? 1 : -1;
      const newCashTable = [...listCashTable];
      const [subscribed, favorite, otherSub, other] = newCashTable.reduce(
        ([subscribed, favorite, otherSub, other], table) => {
          if (isUserSubscribe(table, currentUser.id)) {
            subscribed.push(table);
          } else if (table.favorite) {
            favorite.push(table);
          } else if (table.activeCount > 0) {
            otherSub.push(table);
          }
          else {
            other.push(table);
          }
          return [subscribed, favorite, otherSub, other];
        },
        [[], [], [], []]
      )
      if (sortOption.sortColumn === 'players') {
        other.sort((a, b) => {
          return dir * (a.numberOfSeats - b.numberOfSeats);
        }
        );
        favorite.sort((a, b) => {
          return dir * (a.numberOfSeats - b.numberOfSeats);
        });
        otherSub.sort((a, b) => {
          return dir * (a.numberOfSeats - b.numberOfSeats);
        });
      }
      if (sortOption.sortColumn === 'title') {
        other.sort((a, b) => {
          const titleA = a.title.toLowerCase();
          const titleB = b.title.toLowerCase();
          if (titleA === titleB) {
            return b.id - a.id;
          }
          return titleA.localeCompare(titleB) * dir;
        }
        );
        favorite.sort((a, b) => {
          const titleA = a.title.toLowerCase();
          const titleB = b.title.toLowerCase();
          if (titleA === titleB) {
            return b.id - a.id;
          }
          return titleA.localeCompare(titleB) * dir;
        }
        );
        otherSub.sort((a, b) => {
          const titleA = a.title.toLowerCase();
          const titleB = b.title.toLowerCase();
          if (titleA === titleB) {
            return b.id - a.id;
          }
          return titleA.localeCompare(titleB) * dir;
        }
        );
      }
      if (game?.code === 'SetteEMezzo' && sortOption.sortColumn === 'invitation') {
        other.sort((a, b) => {
          return dir * (a.cost - b.cost);
        }
        );
        favorite.sort((a, b) => {
          return dir * (a.cost - b.cost);
        });
        otherSub.sort((a, b) => {
          return dir * (a.cost - b.cost);
        })
      }
      else {
        other.sort((a, b) => {
          return dir * (a[sortOption.sortColumn] - b[sortOption.sortColumn]);
        }
        );
        favorite.sort((a, b) => {
          return dir * (a[sortOption.sortColumn] - b[sortOption.sortColumn]);
        });
        otherSub.sort((a, b) => {
          return dir * (a[sortOption.sortColumn] - b[sortOption.sortColumn]);
        });
      }
      return [...subscribed, ...favorite, ...otherSub, ...other];
    }
    const [favorite, other] = listCashTable.reduce(
      ([fav, other], table) => {
        if (table.favorite) {
          fav.push(table);
        } else {
          other.push(table);
        }
        return [fav, other];
      },
      [[], []]
    );
    return [...favorite, ...other];
  }, [listCashTable, sortOption]);

  useEffect(() => {
    if (window.location.href === oldGameMode) return;
    setOldGameMode(window.location.href)
  }, [window.location.href]);

  return (
    <>
      {loading ? (
        <Loading color="#0E345E" background="#fff" className="!top-[200px] !md:top-[192px] loading-tournament" />
      ) : (
        <>
          {(isMobileScreenWidth || isMobile) ? (
            <div className="tournament-mobile-list">
              {sortListCashTables.length > 0 ?
                (
                  <CashListMobile data={sortListCashTables} onSelect={onSelect} />
                )
                : (
                  <TableNoData />
                )}
            </div>
          ) : (
            <>
              {sortListCashTables.length > 0 ? (
                <CashListDesktop data={sortListCashTables} onSelect={onSelect} />
              ) : (<TableNoData />)}
            </>
          )}

        </>
      )}

      <SideDrawer
        anchor="right"
        open={drawerAnchor.right}
        onClose={handleClose}
        showHeader={true}
        titleHeader={t('Tournament information')}
        showButtonBack={false}
      >
        <CashGameDetail onClose={handleClose} />
      </SideDrawer>
    </>
  );
};
export default TableCash;
