import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useDrawerToggle from '../../../../hooks/useDrawerToggle';
import { DRAWER_POSITIONING } from '../../../../types/rightSideDrawer';
import { SideDrawer } from 'shared/components/side-drawer';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tournament,
  TournamentFilter,
  selectFilterOptions,
  selectTournaments,
  tournamentActions,
  selectCurrentUser,
  SoundManager,
  Sound,
  User,
  selectTournamentLoading,
  GameHelper,
  TournamentStateEnum,
  selectActiveGame,
  Game,
  selectPassFirstRound,
  generateTournamentTitle,
  selectTournamentSort,
} from '@quanticogames/gameclient-core';
import Loading from 'shared/components/loading';
import { SortingOrder } from 'types/commonTable';
import ModalConfirmSubscribe from 'pages/tournament/modal/modal-confirm-subscribe';
import { useTranslation } from 'react-i18next';
import TableNoData from 'shared/components/tables/component/table-nodata';
import { useMediaQuery, useTheme } from '@mui/material';
import TournamentDetail from 'pages/tournament/component/detail/tournament-info-container';
import { TOURNAMENT_TYPE } from 'constants/common';
import ColpoGrosso from 'pages/tournament/component/colpo-grosso';
import { ConditionComponent } from 'shared/condition';
import { SatelliteModal } from 'shared/components/satellite/modal';
import PassModal from 'pages/tournament/modal/modal-pass';
import { ListTournamentHelpers } from 'pages/tournament/component/list/help';
import TournamentListTable from 'shared/components/TournamentListTable/desktop';
import TournamentListMobile from 'shared/components/TournamentListTable/mobile';
import { TOURNAMENT_STATES_TITLES } from 'pages/tournament/component/tournament-label';
import { lobbyUrlKey } from 'constants/localStorage';

type TournamentTableProps = {
  type?: string;
  types?: string[];
};

const sortTitleByObjective = ['Scopa', 'Burraco']

export const TournamentTable: React.FC<TournamentTableProps> = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser: User = useSelector(selectCurrentUser);
  const tournamentLoading = useSelector(selectTournamentLoading);
  const filterOptions: any = useSelector(selectFilterOptions) as TournamentFilter;
  const sortOption = useSelector(selectTournamentSort)
  const tournaments = useSelector(selectTournaments) as Tournament[];

  const game: Game = useSelector(selectActiveGame);
  
  const [tokenCode, setGetTokenCode] = useState<string>('');

  const [oldGameMode, setOldGameMode] = useState<string>(null)
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = GameHelper.isMobile();
  const activeGame: Game = useSelector(selectActiveGame);
  const [oldGameCode, setOldGameCode] = useState<string>(null);
  const [noData, setNoData] = useState<boolean>(false);
  const tournamentPassRound = useSelector(selectPassFirstRound);
  const [showPassModal, setShowPassModal] = useState<boolean>(false);
  const [contentDialog, setContentDialog] = useState<string>('');

  const renderTitle = useCallback((tournament: Tournament) => {
    return generateTournamentTitle({
      game: game,
      ...tournament,
      template: 'standard'
    }, t);
  }, []);

  const [listTournaments, setListTournaments] = useState([]);
  const isUserInTournament = (tournament, id) => tournament.participants?.some((p) => p.userId === id);

  const isUserSubscribe = (tournament, id) =>
    isUserInTournament(tournament, id) &&
    tournament?.state !== TournamentStateEnum.closed &&
    tournament?.state !== TournamentStateEnum.inprogress;

  useEffect(() => {
    if (tournamentLoading) {
      setListTournaments([])
      return;
    }
  
    if (tournaments.length > 0 && currentUser !== null && currentUser !== undefined) {
      const newListTournaments = tournaments.filter((tournament) => {
        let filter = true;

        filter =
          tournament?.state !== TournamentStateEnum.canceled &&
          tournament?.state !== TournamentStateEnum.end &&
          tournament?.state !== TournamentStateEnum.terminated;
       
        if (filter && game?.id !== tournament.gameId) {
          filter = false;
        }

        if (filter) {
          filter = filter && Boolean(tournament?.maxPlayers);
        }
        if (filter && filterOptions?.costRanges && filterOptions.costRanges.length > 0) {
          const inRange = !!filterOptions.costRanges.find(
            (range) => tournament.cost <= range.costTo && tournament.cost >= range.costFrom
          );
          filter = inRange;
        }

        if (filter && filterOptions?.costRanges && filterOptions.costRanges.length > 0) {

          const inRange = !!filterOptions.costRanges.find(
            (range) => tournament.cost <= range.costTo && tournament.cost >= range.costFrom
          );
          filter = inRange;
        }

        if (filter && filterOptions?.objectives?.length > 0 && filterOptions?.objectiveValues?.length > 0) {
          filter = filterOptions.objectives.includes(tournament.objective);
        }
        
        if (filter && filterOptions?.status?.length > 0) {
          // always show tournament that current player subscribed
          const isSubscribed = tournament.isSubscribed;
          filter = isSubscribed || filterOptions.status.includes(tournament.state);
        }

        if (filter && filterOptions?.matchTypes?.length > 0) {
          filter = filterOptions.matchTypes.includes(tournament.matchType);
        }
        if (filter && filterOptions?.timeSpeeds?.length > 0) {
          filter = filterOptions.timeSpeeds.includes(tournament.speed);
        }
        if (filter && filterOptions?.code && tournament?.code) {
          const tournamentConvert = {
            ...tournament,
            titleConvert: renderTitle(tournament),
          };
          filter = tournament.code.toLowerCase().includes(filterOptions.code.toLowerCase().trim())
            || tournamentConvert.titleConvert.toLowerCase().includes(filterOptions.code.toLowerCase().trim());
        }
        if (filter && filterOptions?.state && tournament?.state) {
          filter =
            tournament?.state === filterOptions.state ||
            (tournament?.isSubscribed && tournament?.state !== TournamentStateEnum.closed);
        }
        if (filterOptions?.maxPlayers?.length > 0 && tournament?.maxPlayers && filter) {
          filter = filterOptions.maxPlayers.indexOf(tournament.maxSeats) > -1;
        }
        if (filter && type !== 'Normal') {
          filter = type == tournament.type;
        }
        return filter;
      });

      newListTournaments.sort((a, b) => {
        const isUserInTournamentA = isUserInTournament(a, currentUser.id);
        const isUserInTournamentB = isUserInTournament(b, currentUser.id);

        if (isUserInTournamentA && !isUserInTournamentB) return -1;
        if (!isUserInTournamentA && isUserInTournamentB) return 1;
      });

      newListTournaments.sort((a, b) => {
        const isUserSubscribeA = isUserSubscribe(a, currentUser.id);
        const isUserSubscribeB = isUserSubscribe(b, currentUser.id);

        if (isUserSubscribeA && !isUserSubscribeB) return -1;
        if (!isUserSubscribeA && isUserSubscribeB) return 1;
      });
      
      setListTournaments(newListTournaments);
    }

    if (!tournamentLoading && tournaments.length === 0) {
      setNoData(true);
    }
    else {
      setNoData(false);
    }

    
  }, [tournaments, tournamentLoading, filterOptions]);

  useEffect(() => { 
    if (listTournaments && listTournaments.length === 0 && !tournamentLoading) {
      setNoData(true);
    }
    else {
      setNoData(false);
    }
  }, [listTournaments, filterOptions]);

  const [tournamentId, setTournamentId] = useState<number>(-1);
  const { drawerAnchor, toggleDrawer } = useDrawerToggle();
  const [infoTournamentSubcribe, setInfoTournamentSubcribe] = useState({});
  const [isOpenModalConfirmSubscribe, setIsOpenModalConfirmSubscribe] = useState<boolean>(false);

  const confirmToken = (tokenCode) => {
    setGetTokenCode(tokenCode);
  };

  const onSelect = (id: number) => {
    toggleDrawer(DRAWER_POSITIONING.RIGHT, true);
    setTournamentId(id);
  };

  const handleDrawerClose = () => {
    toggleDrawer(DRAWER_POSITIONING.RIGHT, false);
  };

  const onAction = (action: string, data: any) => {
    switch (action) {
      case 'subscribe':
        return subscribe(data);
      case 'unsubscribe':
        return unsubscribe(data);
      case 'play':
        return play(data);
      default:
        return;
    }
  };


  const subscribe = (data: Tournament) => {
    SoundManager.play(Sound.clickSubscribe);
    setInfoTournamentSubcribe(data);
    const tournamentId = data?.id;
    if (data?.cost > 0 || (data?.cost === 0 && data?.hasPassword === true)) {
      setTimeout(() => {
        setIsOpenModalConfirmSubscribe(true);
      }, 100);
    } else {
      dispatch(tournamentActions.subscribeTournament({ tournamentId, tokenCode: tokenCode || null }));
    }
  };

  const play = async (id: number) => {
    SoundManager.play(Sound.clickButton);
    dispatch(tournamentActions.play({tournamentId: id}));
  };

  const unsubscribe = (tournamentId: number) => {
    SoundManager.play(Sound.clickUnsubscribe);
    dispatch(tournamentActions.unsubscribeTournament({ tournamentId }));
  };

  const subscribeGoUp = (list: any) => list.forEach((item, i) => {
    if (item.isSubscribed) {
      list.splice(i, 1);
      list.unshift(item);
    }
  });

  const sortedListTournaments = useMemo(() => {
    const newTournaments = [...listTournaments];
    const subscribedTournaments = newTournaments.filter(tournament => tournament.isSubscribed);
    subscribedTournaments.sort((a, b) => {
      if (a.highlight === b.highlight) {
        return b.id - a.id;
      }
      return a.highlight - b.highlight;
    })

    const hightLightTournaments = newTournaments.filter(tournament => !tournament.isSubscribed && tournament.highlight !== 0);
    const favoriteTournament = newTournaments.filter(tournament => tournament.favorite && !tournament.isSubscribed && tournament.highlight === 0);
    const otherTournaments = newTournaments.filter(tournament => !tournament.favorite && !tournament.isSubscribed && tournament.highlight === 0);

    if (sortOption.sortColumn && sortOption.sortDirection) {
      const dir = sortOption.sortDirection === SortingOrder.ASC ? 1 : -1;
      if (sortOption.sortColumn === "players") {
        otherTournaments.sort((a, b) => {
          if (a.registered === 0 && b.registered === 0) {
            return (a.maxPlayers - b.maxPlayers)*dir;
          }
          if (a.registered === 0 && b.registered !== 0) {
            return 1;
          }
          if (a.registered !== 0 && b.registered === 0) {
            return -1;
          }
          const ratioA = a.maxPlayers - a.registered;
          const ratioB = b.maxPlayers - b.registered;

          if (ratioA === ratioB) {
            if (a.registered === b.registered) {
              return (a.id > b.id ? 1 : -1) * dir;
            }
          }
          return (ratioA < ratioB ? -1 : 1) * dir;
        });
      } 
      else if(sortOption.sortColumn === 'description') {
        otherTournaments.sort((a, b) => 
          {
            const titleA = renderTitle(a).toLowerCase();
            const titleB = renderTitle(b).toLowerCase();
            if (titleA === titleB) {
              return b.id - a.id;
            }
            return titleA.localeCompare(titleB) * dir;
          }
        );
      }
      else if (sortOption.sortColumn === 'state') { 
        otherTournaments.sort((a, b) => {
          const stateName = TOURNAMENT_STATES_TITLES[a?.state]?.title;
          const stateNameB = TOURNAMENT_STATES_TITLES[b?.state]?.title;
          if (stateName === stateNameB) {
            return b.id - a.id;
          }
          return stateName.localeCompare(stateNameB) * dir;
        });
      }
      else {
        otherTournaments.sort((a, b) => (a[sortOption.sortColumn] > b[sortOption.sortColumn] ? 1 : -1) * dir);
      }
    } else {
   
      otherTournaments.sort((a, b) => {
        if (a.registered === 0 && b.registered === 0) {
          return b.id - a.id;
        }
        if (a.registered === 0 && b.registered !== 0) {
          return 1;
        }
        if (a.registered !== 0 && b.registered === 0) {
          return -1;
        }
        const ratioA = a.maxPlayers - a.registered;
        const ratioB = b.maxPlayers - b.registered;

        if (ratioA === ratioB) {
          if (a.registered === b.registered) {
            return b.id - a.id;
          }
          return a.registered - b.registered;
        }
        return ratioA - ratioB;
      })
    }
    if (hightLightTournaments.length > 0) {
      hightLightTournaments.sort((a, b) => {
        if (a.highlight === b.highlight) {
          return b.id - a.id;
        }
        return b.highlight - a.highlight;
      });
    }
    const combinedTournaments = [...subscribedTournaments, ...hightLightTournaments, ...favoriteTournament, ...otherTournaments];
    subscribeGoUp(combinedTournaments);
    return combinedTournaments;
  }, [listTournaments, sortOption, tournamentLoading]);

  ListTournamentHelpers({ listTournaments, sortedListTournaments, onSelect });



  useEffect(() => {
    if (window.location.href === oldGameMode) return;
    setOldGameMode(window.location.href)

  }, [window.location.href]);

  useEffect(() => {
    if (activeGame?.code === oldGameCode) return;
    setOldGameCode(activeGame?.code)

  }, [activeGame]);

  useEffect(() => {
    if (!tournamentPassRound) return;

    setContentDialog(t('You have passed to the next round, wait for your opponent'));
    setShowPassModal(true);
  }, [tournamentPassRound]);

  const closePassModal = () => {
    setShowPassModal(false);
    dispatch(tournamentActions.resetAdvanceToNextRound({}));
  }


  return (
    <>
      {tournamentLoading ? (
        <Loading className="!top-[200px] !md:top-[192px] loading-tournament" />
      ) : (<>
        {(type === TOURNAMENT_TYPE.TREBLE || type === TOURNAMENT_TYPE.CRAZY_PRIZE) ? (
          <ColpoGrosso type={type} subscribe={subscribe} onSelect={onSelect} />
        ) : (
          <>
            {(isMobileScreenWidth || isMobile) ? (
              <div className='tournament-mobile-list'>
                {!noData ?
                  <TournamentListMobile data={sortedListTournaments} onSelect={onSelect} onAction={onAction} />
                  :
                  <>
                    <ConditionComponent condition={!tournamentLoading} >
                      <TableNoData />
                    </ConditionComponent>
                  </>
                }
              </div>
            ) : (
              <>
               {!noData ?
                  <TournamentListTable data={sortedListTournaments} onAction={onAction} onSelect={onSelect} />
                  :
                  <>
                    <ConditionComponent condition={!tournamentLoading} >
                      <TableNoData />
                    </ConditionComponent>
                  </>
                }
              </>
            )}
          </>
        )}
      </>

      )}
      <SideDrawer
        anchor="right"
        open={drawerAnchor.right}
        onClose={handleDrawerClose}
        showHeader={true}
        titleHeader={t('Tournament information')}
        showButtonBack={false}
        className="tournament-info-drawer"
        titlePosition='left'
      >
        <TournamentDetail onClose={handleDrawerClose} onAction={onAction} id={Number(tournamentId)} />
      </SideDrawer>
      {tournaments.length > 0 && <SatelliteModal />}
      {isOpenModalConfirmSubscribe && (
        <ModalConfirmSubscribe
          isOpen={isOpenModalConfirmSubscribe}
          data={infoTournamentSubcribe}
          onCloseModal={() => setIsOpenModalConfirmSubscribe(false)}
          confirmToken={confirmToken}
        />
      )}

      <PassModal isOpen={showPassModal} onClose={() => closePassModal()} content={contentDialog} />

    </>
  );
};