import { Box, Button, DialogContent, DialogTitle, Slider, Stack, TextField, styled } from '@mui/material';
import { ButtonCustom } from '@quanticogames/gameclient-components';
import ButtonComponent from 'shared/components/button';

export const StyledCloseBtn = styled(Button)({
    minWidth: '36px',
    padding: 0,
    position: 'absolute',
    top: 4,
    right: 4,
});

export const StyledDialogTitle = styled(DialogTitle)({
    background: '#0E345E',
    color: '#fff',
    padding: '10px 20px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
});

export const StyledContent = styled(DialogContent)({
    position: 'relative',
    width: '100%',
    fontSize: '15px',
    fontWeight: '500',
    padding: '0 14.5px !important',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '.mobile &': {
        padding: '0 !important',
    }
});

export const StyledBackDrop = styled(Box)({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#000000',
    opacity: 0.5,
    zIndex: 100,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
});

export const StyledBalance = styled(Box)({
    display: 'inline-flex',
    height: '33px',
    width: '149px',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '59px',
    border: '1px solid #4A4A4A',
    fontFamily: `'Inter', sans-serif !important`,
});

export const StyledTitle = styled(Box)({
    borderTop: '1px solid #D2D2D3',
    padding: '20px 0',
    marginTop: '20px',
    fontWeight: '600',
    color: '#101223',
    width: '100%',
    '.mobile &': {
        marginTop: '12px',
        padding: '12px 0',
    },
});

export const StyledWarning = styled(Box)({
    color: '#ff0000',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: '16px',
});

export const StyledStack = styled(Stack)({
    width: '100%',
    alignItems: 'center',
    marginBottom: '20px',
    fontSize: '14px',
    '.mobile &': {
        marginBottom: '12px',
    },
});

export const StyledValueBtn = styled(Button)({
    backgroundColor: '#3F51B5',
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '80px',
    gap: '4px',
    padding: '8px',
    fontSize: '12px',
    height: '48px !important',

    '&:hover': {
        backgroundColor: '#3F51B5CC',
    },

    '&:disabled': {
        backgroundColor: '#ababab',
        color: '#FFFFFF',
    },

    '.mobile &': {
        padding: '4px',
    },
});

export const StyledButtonCustom = styled(ButtonCustom)({
    width: '100%',
    maxWidth: '130px',
    fontSize: '14px',
    '&:disabled': {
        backgroundColor: '#ababab',
        boxShadow: 'none !important',
        '&::before': {
            backgroundColor: '#ababab',
        },
    },
});

export const StyledTextField = styled(TextField)({
    'input': {
        textAlign: 'center',
        padding: '0',
        width: '148px',
        background: '#F4F8F9',
        '.mobile &': {
        },
    },
    'fieldset': {
        borderColor: '#8E8E8E !important',
        borderWidth: '0.5px !important',
    },
});

export const StyledAnnounce = styled(Box)({
    'p': {
        fontFamily: 'Inter',
        textAlign: 'center',
    },
    'span': {
        fontWeight: '600',
        color: '#175EBE'
    },
});

export const StyledSlider = styled(Slider)({
    '&.MuiSlider-root': {
        padding: '7px 0',
        height: 25,
        '.mobile &': {
            margin: '0 10px',
        },
    },
    '.MuiSlider-rail': {
        background: '#323131',
        border: '1px solid #747474',
        opacity: 1,
        '.mobile &': {
            marginLeft: '-10px',
            width: 'calc(100% + 20px)'
        },
    },
    '.MuiSlider-track': {
        background: 'linear-gradient(270deg, #0A3772 0%, #2778E5 100%)',
        border: '1px solid #747474',
        marginLeft: '2px',
        height: '19px',
        '.mobile &': {
            marginLeft: '-8px',
        },
    },
    '.MuiSlider-thumb': {
        width: 36,
        height: 36,
        backgroundSize: "cover",
        boxShadow: 'none !important',
        backgroundImage: "url('/images/slider-handler.svg') !important",
    }
});

export const StyledButton = styled(ButtonComponent)({
    '.mobile &': {
        height: '30px',
    },
});
