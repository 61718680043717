import React, { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Pagination,
  styled,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import {
  Participant,
  Tournament,
  tournamentApi,
} from '@quanticogames/gameclient-core';
import IconLoading from 'assets/icons/Loading';
import TableComponent from 'shared/components/tables';
import { useTranslation } from 'react-i18next';

type Ranking = {
  data?: Tournament | null;
  tournamentId?: number;
  listParticipant?: Participant[];
  isChipGames?: boolean;
};

const PaginationWrapper = styled(Box)(() => ({
  paddingTop: '28px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPaginationItem-root': {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#0B0E11',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
    },
  },
}));

export const Ranking: React.FC<Ranking> = ({ tournamentId, isChipGames }) => {
  const { t } = useTranslation('common');
  const [page, setPage] = useState<number>(1);
  const [listParticipant, setListParticipant] = useState<any>([]);

  // const sortList = isChipGames
  //   ? [...listParticipant]?.sort((a, b) => b?.matchPoint - a?.matchPoint)
  //   : [...listParticipant]?.sort((a, b) => b?.victoryPoint - a?.victoryPoint);
  
  const getParticipants = async () => {
    try {
      const participantResponse = await tournamentApi.getParticipants(tournamentId);    
      setListParticipant(participantResponse);
    } catch (error) {
      console.error('Error fetching participants:', error);
    }
  };

  useEffect(() => {
    getParticipants();

    const interval = setInterval(() => {
      getParticipants();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const HEAD_DATA_PARTICIPANTS = [
    {
      id: 'position',
      label: 'Pos.',
    },
    {
      id: 'user',
      label: 'User',
    },
    {
      id: 'status',
      label: 'Stato',
    },
  ];

  const HEAD_DATA_PARTICIPANTS_IP = [
    {
      id: 'position',
      label: 'Pos.',
      align:'center'
    },
    {
      id: 'user',
      label: 'User',
    },
    {
      id: 'chips',
      label: 'Chips',
    },
    {
      id: 'status',
      label: 'Stato',
    },
  ];

  const renderPlayerInfo = useCallback(
    (player: Participant) => {
      return (
        <TableCell>
          <Box sx={{ display: 'flex' }}>
            <Avatar
              sx={{
                borderColor: 'transparent',
                width: '20px',
                height: '20px',
                marginRight: '8px',
              }}
              src={`/images/avatar/${player.avatar}`}
              key={player.id}
            />
            {player?.userName}
          </Box>
        </TableCell>
      );
    },
    [tournamentId]
  );

  const renderPlayerStatus = useCallback(
    (player) => {
      const defaultStatus = { label: 'Unknown', color: '#000000' };
      
      const statusMappings = {
        1: { label: 'Active', color: '#24AC29' },
        2: { label: 'Eliminated', color: '#CD1B1B' },
        0: { label: 'Inactive', color: '#BDBABB' },
      };
      const { label: statusLabel, color: statusColor } = statusMappings[player.status] || defaultStatus;

      return (
        <TableCell>
          <Box sx={{ display: 'flex' }}>
            <Typography
              sx={{
                fontSize: '0.875rem',
                fontWeight: 500,
                lineHeight: '17px',
                color: `${statusColor}`,
              }}
            >
              {t(`${statusLabel}`)}
            </Typography>
          </Box>
        </TableCell>
      );
    },
    [tournamentId]
  );

  const renderChips = useCallback(
    (player: any) => {
      return (
        <TableCell>
          {player.matchPoint}
        </TableCell>
      );
    }, [listParticipant.length]
  );
  

  return (
    <>
      <TableComponent 
        columns={isChipGames ? HEAD_DATA_PARTICIPANTS_IP : HEAD_DATA_PARTICIPANTS}
      >
        {listParticipant.slice((page - 1) * 10, page * 10).map((player, idx) => (
          <TableRow key={`participant-table-item-${player?.id}`}>
            <TableCell
              width="60px"
              sx={{textAlign: 'center'}}
            >
              {idx + 1 + 10 * (page - 1)}°
            </TableCell>
            {player?.userName ? renderPlayerInfo(player) :  <TableCell><IconLoading /></TableCell> }
            {isChipGames && renderChips(player)}
            {player?.userName ? renderPlayerStatus(player) : <TableCell><IconLoading /></TableCell>}
          </TableRow>
        ))}
      </TableComponent>
      <PaginationWrapper>
        <StyledPagination
          onChange={(e, page) => setPage(page)}
          count={Math.ceil(listParticipant?.length / 10)}
          hidePrevButton
          hideNextButton
        />
      </PaginationWrapper>
    </>
  );
};
