import { useEffect, useRef, useState } from 'react';
import { Avatar, Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { AVATAR_LIST } from '../../../../constants/common';
import { useTranslation } from 'react-i18next';
import { User, authAction, selectCurrentUser, selectOrientation, userApi } from '@quanticogames/gameclient-core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import ButtonComponent from 'shared/components/button';
import { useSelector } from 'react-redux';
import { FlexBox } from 'shared/Flex';

export const AvatarList = styled(Box)(() => ({}));

type EditAvatarProps = {
  onSelectAvatar?: (e) => void;
  onClose?: () => void;
};

export const EditAvatar = ({ onSelectAvatar, onClose }: EditAvatarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const joinAvatarUrl = (avatar) => `/images/avatar/${avatar}`;
  const currentUser: User = useSelector(selectCurrentUser);
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(currentUser.avatar);
  const [checkChangeAvatar, setCheckChangeAvatar] = useState<boolean>(false);
  
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const avatarListRef = useRef<HTMLDivElement>(null); // Ref for avatar list container
  const changeAvatarBtnRef = useRef<HTMLDivElement>(null);

  const onAvatarItemClick = (avatar: string) => {
    setSelectedAvatar(avatar);
    setCheckChangeAvatar(true);
  };

  const onChangeAvatar = async () => {
    try {
      const avatar = selectedAvatar;
      await userApi.updateAvatar({ avatar });

      const session = await userApi.getSessionInfo();
      dispatch(authAction.loginSuccess(session));
      setCheckChangeAvatar(false);
      onClose();
      
    } catch (error) {
     
      throw error;
    }
  };

  const handleSelectedAvatar = (data) => {
    onAvatarItemClick(data);
    onSelectAvatar(data);
  };

  useEffect(() => {
    const avatarList = avatarListRef.current;
    const changeAvatarBtn = changeAvatarBtnRef.current;
  
    if (avatarList && changeAvatarBtn) {
      const handleScroll = () => {
        // Check when scrolled to the end of the list
        if (avatarList.scrollHeight - avatarList.scrollTop === avatarList.clientHeight) {
          changeAvatarBtn.classList.add('scroll-end');
        } else {
          changeAvatarBtn.classList.remove('scroll-end');
        }
      };
  
      if (avatarList.scrollHeight === avatarList.clientHeight) {
        changeAvatarBtn.classList.add('scroll-end');
      } else {
        changeAvatarBtn.classList.remove('scroll-end');
      }
  
      avatarList.addEventListener('scroll', handleScroll);
  
      return () => {
        avatarList.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <div className="profile-edit-container">
      {!isMobileScreenWidth && !isMobileLandscape && <p className="profile-edit-lable">{t('Pick your avatar')}</p>}

      <AvatarList className="avatar-list" id="avatarList" ref={avatarListRef}>
        {AVATAR_LIST.map((item) => (
          <Avatar
            key={`avatar-item-${item.id}`}
            src={`${joinAvatarUrl(item.src)}`}
            sx={{
              cursor: 'pointer',
              ...(selectedAvatar === item.src && {
                outline: '3px solid #0066FF',
              }),
            }}
            className="profile-edit-avatar"
            onClick={() => handleSelectedAvatar(item.src)}
          />
        ))}
      </AvatarList>

      <FlexBox className="change-avatar-btn" width="100%" id="changeAvatarBtn" ref={changeAvatarBtnRef}>
        <ButtonComponent
          title={`${t('Save')}`}
          fullWidth
          size={`${isMobileLandscape ? 'medium' : 'large'}`}
          variant="contained"
          onClick={onChangeAvatar}
          className={`${checkChangeAvatar ? '' : 'btn-disabled'} `}
        />
      </FlexBox>
    </div>
  );
};
