import EngineeringIcon from '@mui/icons-material/Engineering';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'shared/components/button';

const ErrorPage = () => {
    const { t } = useTranslation()
    
    return <div className="error-page flex sm:items-center justify-center sm:flex-nowrap flex-wrap h-[100vh] px-[20px] max-w-[1040px] lg:gap-[40px] sm:gap-[30px] mx-auto gap-[20px]">
        <div className='sm:w-[50%] mt-auto sm:mt-[inherit]'>
            <img src='/images/common/error-page.png' className='w-full' />
        </div>
        <div className='font-raleway text-[18px] text-center sm:w-[50%]'>
            <h1 className='font-bold mb-[20px] text-[40px]'>{t('ERRORE')}</h1>
            <p className='mb-[20px]'><EngineeringIcon sx={{ fontSize: 60 }} /></p>
            <p className='font-medium my-[10px] leading-[1.3]'>{t("error-page.content-1")}</p>
           
            <p className='font-medium leading-[1.3]'>{t('error-page.content-2')}</p>
            <ButtonComponent
                variant="contained"
                onClick={() => window.location.reload()}
                size={'medium'}
                className="error-modal-button"
                title={t("Reconnect")}
                color={'primary'}
            />
        </div>
    </div>
}
export default ErrorPage