import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectGame } from './select-game';
import { ChangeGameIconSvg } from '../../../assets/icons/ChangeGameIconSvg';
import { PATH_ROUTES } from '../../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  Game,
  Sound,
  SoundManager,
  gameActions,
  selectActiveGame,
  tournamentActions,
  selectOpenTutorial,
  cashTableActions,
} from '@quanticogames/gameclient-core';
import ModalComponent from 'shared/components/modal';
import { mapStyleGame } from 'utils/styleGame'
import { cashGame } from 'constants/common';
import { useTranslation } from 'react-i18next';

interface ChangeGameProps { 
  isHeaderSmall: boolean; 
}

export const ChangeGame = (props: ChangeGameProps) => {
  const { isHeaderSmall } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const activeGame: Game = useSelector(selectActiveGame);
  const openTutorialValue = useSelector(selectOpenTutorial);
  const { t } = useTranslation();
  useEffect(() => {
    if (openTutorialValue) {
      setIsOpen(false);
    }
  }, [openTutorialValue])
  const handleClickOpen = () => {
    SoundManager.play(Sound.clickButton);
    setIsOpen(true);
  };

  const handleClose = () => {
    SoundManager.play(Sound.clickButton);
    setIsOpen(false);
  };

  const handleClickButton = (game) => {
    if (game?.code === activeGame?.code) {
      setIsOpen(false);
      return;
    };
    if (cashGame.includes(activeGame?.code)) {
      dispatch(gameActions.unSubscribeCashGame({ gameId: activeGame?.id }));
    }
    dispatch(gameActions.unSubscribeGame({ gameId: activeGame?.id }));
    dispatch(gameActions.activeGame({ game }));

    dispatch(tournamentActions.resetFilters(null));
    dispatch(tournamentActions.updateTournamentSort({ sortColumn: 'players', sortDirection: 'asc' }))
    dispatch(cashTableActions.resetFilters());
    dispatch(cashTableActions.updateSort({ sortColumn: 'invitation', sortDirection: 'desc' }))
    navigate(PATH_ROUTES.SIT_AND_GO);
    setIsOpen(false);
  };

  const gameSmallText = ['Poker 5 Carte', 'Asso Piglia Tutto', 'Sette E Mezzo']
  const isSmallText = gameSmallText.includes(activeGame?.name)
  return (
    <>
      <div
        className={`change-game-button ${isHeaderSmall ? 'change-game-button-small' : ''}`}
        onClick={handleClickOpen}
        style={{
          background: mapStyleGame(activeGame?.code)?.backgroundGradient,
          color: mapStyleGame(activeGame?.code)?.color,
        }}
      >
        <div className='change-game' style={{ borderColor: mapStyleGame(activeGame?.code)?.color }}>
          <p className={`change-game-name ${isSmallText ? 'small-text' : ''}`}>
            {t(mapStyleGame(activeGame?.code)?.fullName)}
          </p>
        </div>
        <ChangeGameIconSvg />
      </div>
      <ModalComponent 
        open={isOpen} 
        onClose={handleClose} 
        title={t('Select Game')} 
        size='large'
        titleCenter={true}
      >
        <SelectGame onClick={handleClose} onClickButton={handleClickButton} />
      </ModalComponent>
    </>
  );
};
