import React from 'react';
import { styled, SxProps } from '@mui/material';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ScheduledTour } from './type';
import moment from 'moment';
import 'moment/locale/it';
import { getSpeedLabel, getWinObject } from 'utils/tournamentTitle';
import { convertCentToEuro } from '@quanticogames/gameclient-core';
import { mapGameIdToGameName } from 'utils/mapGame';
import { numberWithDecimals } from 'utils/formatCurrency';
import { isChipGame } from 'utils/constants';

type TableItemProps = {
  data: ScheduledTour;
  gameCode?: string;
};

type CELL = {
  name: string;
  render?: (data: ScheduledTour, cell: string, t?: TFunction) => string | JSX.Element;
  sx?: SxProps;
};

const StyledTableRow = styled('tr')``;

const StyledTableCell = styled('td')``;

export const TableItem: React.FC<TableItemProps> = ({ data, gameCode }) => {
  const { t } = useTranslation();

  const getMatchType = (type: number) => {
    switch (type) {
      case 0:
        return '1v1';
      case 1:
        return '4in2';
      case 2:
        return '2v2';
      default:
        return '';
    }
  };

  const renderTitle = (data: ScheduledTour) => {
    // const speed = data?.gameId == 9 && data?.maxPlayers == 2 ? 'Nibble' : getSpeedLabel(data?.speed);
    const speed = getSpeedLabel(data?.speed);
    const winObj = isChipGame(data?.gameCode) ? '' : ' ' + getWinObject(data?.objective, data?.objectiveValue, t);
    const matchType = isChipGame(data?.gameCode) ? '' : ' ' + getMatchType(data?.matchType);
    const seat = isChipGame(data?.gameCode) ? ` - ${data?.maxSeats} Posti` : '';

    if (data?.freeroll) {
      return `${t('Freeroll')} ${t(speed)}${winObj} ${numberWithDecimals(convertCentToEuro(data?.rewardValue), 2)} EURO BONUS${matchType}${seat}`;
    };
    if (data?.guaranteedValue) {
      return `${t(speed)}${winObj} GTD ${numberWithDecimals(convertCentToEuro(data?.guaranteedValue), 2)}${matchType}${seat}`;
    };
    return `${t(speed)}${winObj}${matchType}${seat}`;
  };
  
  const CELLS: CELL[] = [
    {
      name: 'day',
      sx: {textAlign: 'center', width: 65},
      render: (data) => moment(data.startingTime).locale('it').format('dddd DD/MM/YYYY'),
    },
    {
      name: 'time',
      sx: {textAlign: 'center', width: 50},
      render: (data) => moment(data.startingTime).format('HH:mm'),
    },
    {
      name: 'description',
      sx: {textAlign: 'center', width: 'auto', minWidth: 210},
      render: (data) => <span className='uppercase'>{renderTitle(data)}</span>,
    },
    {
      name: 'guaranteed',
      sx: {textAlign: 'center', width: 100},
      render: (data) => <span>{numberWithDecimals(convertCentToEuro(data?.guaranteedValue || 0), 2)}</span>,
    },
    {
      name: 'cost',
      sx: {textAlign: 'center'},
      render: (data) => <span>{numberWithDecimals(convertCentToEuro(data?.cost || 0), 2)}</span>,
    },
    {
      name: 'subscribers',
      sx: {textAlign: 'center', width: 'auto', minWidth: 125},
      render: (data) => <span>{data?.minPlayers} / {data?.maxPlayers}</span>,
    },
    ...(gameCode ? [] : [{
      name: 'game',
      sx: {textAlign: 'center'},
      render: (data) => <span>{mapGameIdToGameName(data?.gameId)}</span>,
    }]),
  ];

  return (
    <>
      <StyledTableRow>
        {CELLS.map((cell) => (
          <StyledTableCell key={cell.name} sx={cell.sx}>
            {cell.render ? cell.render(data, cell.name, t) : data[cell.name]}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </>
  );
};
