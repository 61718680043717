import { Popover } from "@mui/material";
import { BalanceInfo, configActions, convertCentToEuro, formatMoney, gameActions, selectBalanceInfo, selectConfigBackgroundMusic, selectConfigState, selectIsLoadedBalance, soundEffect } from "@quanticogames/gameclient-core";
import { Plus } from "assets/icons/Plus"
import { WalletIcon } from "assets/icons/WalletIcon"
import { useSelector } from "react-redux";
import ButtonComponent from "shared/components/button"
import { useState, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { formatLargeMoney } from "utils/formatCurrency";
import SoundVolume from "assets/icons/SoundVolumn";
import { Toggle } from 'shared/components/toggle';

const HeaderSound = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const soundStatus = useSelector(soundEffect)
    const musicBackground = useSelector(selectConfigBackgroundMusic)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const configState = useSelector(selectConfigState);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleChangeToggle = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        dispatch(configActions.updateSetting({ ...configState, [key]: !configState[key], notCallApi: false }));
    }

    return (
        <>
            <div className="header-sound-config" aria-describedby={id} onClick={(e: any) => handleClick(e)} >
                <SoundVolume />
            </div>
            <Popover
                open={open}
                id={id}
                onClose={handleClose}
                anchorEl={anchorEl}
                className='popover-balance '
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="px-1">
                    <div className="popup-sound-config-item ">
                        <span>{t('Music')}:</span>
                        <Toggle checked={musicBackground} onChange={(e) => {
                            handleChangeToggle(e, 'backgroundMusic');
                        }} sx={{ '.MuiSwitch-track': { opacity: '1!important' }, }} />
                    </div>
                    <div className="popup-sound-config-item">
                        <span>{t('Sound effect')}:</span>
                        <Toggle checked={soundStatus} onChange={(e) => {
                            handleChangeToggle(e, 'sound');
                        }} sx={{ '.MuiSwitch-track': { opacity: '1!important' } }} />
                    </div>

                </div>
            </Popover>
        </>
    )
}

export default memo(HeaderSound);