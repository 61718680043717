import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CashTable, CashTableConfig, Game, GameCode, Sound, SoundManager, cashTableActions, cashTableApi, selectActiveGame, selectOrientation, tournamentActions } from '@quanticogames/gameclient-core';
import { convertCentToEuro, formatMoney } from 'utils/formatCurrency';
import ButtonComponent from 'shared/components/button';
import { FlexBox } from 'shared/Flex';
import UserGroup from 'assets/icons/UserGroup';
import PinActiveIcon from 'assets/icons/PinActive';
import PinIcon from 'assets/icons/Pin';
import BadgesCash from 'shared/Badges/badges-cash';
type CashGameTableItemMobileProps = {
  data?: CashTable | null;
  onSelect?: (...agrs: any[]) => void | any;
  index: number;
  //   onAction: (action: string, id: number, index?: number) => void;
  favorite?: {
    id: number;
    favoriteId: number;
  };
};

export const CashGameTableItemMobile = ({ data, onSelect, index, favorite }: CashGameTableItemMobileProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const game: Game = useSelector(selectActiveGame);
  const isMobileLandscape = useSelector(selectOrientation) === 'landscape';
  const id = data?.id;

  const renderFavoriteIcon = useMemo(
    () => () => { 
      return favorite ? <PinActiveIcon /> : <PinIcon /> 
    },
    [favorite]
  );
  const handleActionFavorite = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if(favorite) {
      dispatch(cashTableActions.removeFavoriteTournament({
        id: favorite?.favoriteId,
      }));
      await cashTableApi.updateFavoriteTournament(favorite?.favoriteId, false);
      return;
    }
    const response: CashTableConfig = await cashTableApi.createFavoriteTournament(data?.id);

    dispatch(cashTableActions.addFavoriteTournament({
      config: response,
    }));
  };
  const handleTournamentsDetail = () => {
    SoundManager.play(Sound.clickButton);
    onSelect(data.id, index);
  };

  const handleOpenTable = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, id: number) => {
      e.stopPropagation();
      if (!game) return;
      dispatch(
        cashTableActions.openTable({
          id,
          gameCode: game.code,
        })
      );
    },
    [game]
  );

  return (
    <FlexBox alignItems="center" className="tournament-mobile-item" onClick={handleTournamentsDetail}>
        <div className="tournament-mobile-favorite" id={`tournament-favorite-${data?.id}`} onClick={(e: any) => handleActionFavorite(e)}>
        {renderFavoriteIcon()}
      </div>
      <FlexBox gap={isMobileLandscape ? 15 : 6}
        flexDirection={isMobileLandscape ? 'row' : 'column'}
        alignItems={isMobileLandscape ? 'center' : 'flex-start'}
        width="100%" className={`tournament-mobile-content flex-1 ${isMobileLandscape ? 'tournament-mobile-content-landscape' : ''}`}>
        {isMobileLandscape ? (
          <>
            <FlexBox flexDirection="column" className="flex-1" gap={3}>
              <div className="tournament-des">
                <div className="tournament-des-content">
                  <p className="tournament-des-title font-bold">
                    {data.title} - Cash
                  </p>
                </div>
              </div>

              <FlexBox gap={10} alignItems="center" className="tournament-mobile-line">
                <div className="tournament-mobile-badge">
                  <BadgesCash data={data} />
                </div>
                <FlexBox alignItems="center" gap={5} className="tournament-mobile-player">
                  <UserGroup />
                  {`${data.activeCount}/${data.numberOfSeats}`}
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox flexDirection="column" width="120px" className="tournament-mobile-line">
              <div className="tournament-mobile-cost">
                <span className="label">{t('Cost')}: </span>
                {`€ ${formatMoney(convertCentToEuro(data.cost || data.invitation))}`}
              </div>
            </FlexBox>

            <FlexBox flexDirection="column" width="120px">
              <ButtonComponent
                onClick={(e) => handleOpenTable(e, data.id)}
                variant="outlined"
                title={t('Play')}
                className="lobby-button"
                rouned="large"
                size="large"
                fullWidth
              />
            </FlexBox>
          </>
        ) : (
          <>
            <FlexBox
              alignItems="center"
              width="100%"
              gap={10}
              justifyContent="space-between"
              className="tournament-mobile-line"
            >
              <div className="tournament-des">
                <div className="tournament-des-content">
                  <p className="cash-mobile-des-title font-bold">
                    {data.title} - Cash
                  </p>
                </div>
              </div>

              <ButtonComponent
                onClick={(e) => handleOpenTable(e, data.id)}
                variant="outlined"
                title={t('Play')}
                className="lobby-button"
                rouned="large"
                size="large"
                fullWidth
              />
            </FlexBox>
            <FlexBox alignItems="center" width="100%" justifyContent="space-between" className="tournament-mobile-line">
              <div className="tournament-mobile-cost">
                <span className="label">{t('Cost')}: </span>
                {`€ ${formatMoney(convertCentToEuro(game.code === GameCode.SetteEMezzo ? data?.cost : data.invitation))}`}
              </div>
              <div className="tournament-mobile-badge">
                <BadgesCash data={data} />
              </div>
              <FlexBox alignItems="center" gap={5} className="tournament-mobile-player">
                <UserGroup />
                {`${data.activeCount}/${data.numberOfSeats}`}
              </FlexBox>
            </FlexBox>
          </>
        )}
      </FlexBox>
    </FlexBox>
  );
};
