
import { MenuItem, Select } from '@mui/material';
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';

const MenuProps = {
    PaperProps: {
        sx: {
            borderRadius: '5px',
            marginTop: '5px',
            width: '200px',

            '& li': {
                padding: '5px 10px',
            }
        },
    }
}

interface SortMenuProps {
    setFieldSort: (value: string) => void;
    setSortType: (value: any) => void;
    sortType: string;
    fieldSort: string;
    isCashTable: boolean;
}

export const SortMenu = (props: SortMenuProps) => {
    const { setFieldSort, setSortType, sortType, fieldSort, isCashTable } = props;
    const { t } = useTranslation();
    const [sortState, setSortState] = useState('cost desc');

    useEffect(() => {
        console.log('fieldSort', fieldSort, sortType)
        if (fieldSort === 'title' || fieldSort === 'status' || fieldSort === 'description') {
            setSortState(`${fieldSort} asc`)
        }
        else {
            setSortState(fieldSort + ' ' + sortType)
        }
    }, [sortType, fieldSort])
    const sortMenu = isCashTable ? [
        {
            value: 'title asc',
            label: t('Description')
        },
        {
            value: 'invitation asc',
            label: t('Invitation / Cost asc')
        },
        {
            value: 'invitation desc',
            label: t('Invitation / Cost desc')
        },
        {
            value: "players asc",
            label: t('Players asc')
        },
        {
            value: "players desc",
            label: t('Players desc')
        },

    ] :
        [
            {
                value: "description asc",
                label: t('Description')
            },
            {
                value: 'cost asc',
                label: t('Cost asc')
            },
            {
                value: 'cost desc',
                label: t('Cost desc')
            },
            {
                value: "players asc",
                label: t('Players asc')
            },
            {
                value: "players desc",
                label: t('Players desc')
            },
            {
                value: "status asc",
                label: t('Status')
            }
        ]

    function handleSortSelected(value: string) {
        setSortState(value);
        const field = value.split(' ')[0];
        setFieldSort(field);
        const sort = value.split(' ')[1] ? value.split(' ')[1] : 'asc';
        setSortType(sort);
    }
    return (
        <div className="flex justify-between gap-1">
            <div className="w-full">
                <p className="filter-mobile-label">{t('Sort by')}</p>
                <Select
                    MenuProps={MenuProps}
                    className='w-full h-[50px]'
                    IconComponent={() => (
                        <ExpandMoreIcon style={{
                            color: '#000',
                            fontSize: '25px',
                        }} />
                    )}
                    value={sortState}
                    onChange={(e) => {
                        handleSortSelected(e.target.value)
                    }}
                >
                    {sortMenu.map((option: any) => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </Select>
            </div>

            {/* <div className="w-1/2">
                <p className="filter-mobile-label">
                    {t('Order by')}
                </p>
                <Select
                    MenuProps={MenuProps}
                    className='w-full h-[50px]'
                    IconComponent={() => (
                        <ExpandMoreIcon style={{
                            color: '#000',
                            fontSize: '25px',
                        }} />
                    )}
                    value={sortType}
                    onChange={(e: any) => {
                        setSortType(e.target.value)
                    }}
                >
                    {orderMenu.map((option: any) => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </Select>
            </div> */}

        </div>
    )
}