import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { COLORS } from '../../../constants/colors';
import { useSelector } from 'react-redux';
import { Game, GameHelper, selectGames } from '@quanticogames/gameclient-core';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'shared/components/button';
import { MenuBook } from '@mui/icons-material';


import { useNavigate } from 'react-router';
import { tutorialWindowsKey } from 'constants/localStorage';

export const GameDialog = styled(Dialog)`
  z-index: 1310;

  .MuiDialog-paper {
    box-sizing: border-box;
    max-width: 1108px;
    width: 100%;
    padding: 24px 42px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 8px;
    }
  }
`;

export const TitleBox = styled(Box)`
  margin: 0 -18px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin: 0 8px;

    @media (orientation: landscape) {
      display: none;
    }
  }

  h4 {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 24px;

    ${({ theme }) => theme.breakpoints.down('md')} {
      font-size: 1.625rem !important;
      margin: 37px 0 13px;
    }
  }

  .MuiButtonBase-root {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const CardWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 48px 36px;
  padding: 24px 0;

  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: 16px;
    padding: 8px;
    @media (orientation: landscape) {
      justify-content: center;
    }
  }
`;

export const CardStyled = styled(Card)`
  box-sizing: border-box;
  border: 2px solid #f0f1f3;
  box-shadow: 0 3px 32px rgba(11, 14, 17, 0.12);
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(25% - 48px);
  max-width: calc(25% - 27px);
  border-radius: 12px;
  min-width: 120px;

  &:hover {
    border-color: ${COLORS.primary_main};
  }

  .MuiCardMedia-root {
    height: 206px;
    .mobile & {
      height: 160px;
      @media (orientation: landscape) {
        height: 135px;
        margin: 8px 8px 0;
      }
    }
  }

  .MuiCardActions-root > :not(:first-of-type) {
    margin-left: 8px;
  }

  .mobile & {
    @media (orientation: portrait) {
      max-width: calc(50% - 8px);
      flex-basis: calc(50% - 20px);
    }

    .MuiTypography-root {
      margin-bottom: 0;

      @media (orientation: landscape) {
        font-size: 1rem;
        text-align: center;
      }
    }
  }

  .MuiCardContent-root {
    padding-bottom: 0;

    .mobile & {
      padding: 16px !important;
      @media (orientation: landscape) {
        padding: 7px 5px 5px !important;
      }
    }
  }

  h4 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 24px;
    margin: 0;

    .mobile & {
      line-height: 22px;
    }
  }
`;

export const GameCardActions = styled(CardActions)`
  padding: 43px 10px 102px 10px;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('md')} {
    @media (orientation: landscape) {
      max-width: 644px;
      margin: 0 auto;
      padding: 16px 0 102px 0;
    }
  }
`;

const TutorialButton = styled(ButtonComponent)({
  maxWidth: '30px !important',
  minWidth: '30px !important',
  width: '30px !important',
  '.mobile &': {
    maxWidth: '40px !important',
    minWidth: '40px !important',
    width: '40px !important',
  }
});

type SelectGameType = {
  onClick?: React.MouseEventHandler<HTMLElement>;
  onClickButton?: (e) => void
};

export const SelectGame: React.FC<SelectGameType> = ({ onClick, onClickButton }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const games: Game[] = useSelector(selectGames);
  
  const isMobile = GameHelper.isMobile();

  const openTutorial = (gameCode: string) => {
    if (GameHelper.isMobile()) {
      navigate(`/tutorial/${gameCode}`);
      return;
    }
    window.open(
      `/tutorial/${gameCode}`,
      `tutorial${gameCode}`,
      "width=" + screen.availWidth + ",height=" + screen.availHeight
    );
    let tutorialWindows: string[] = localStorage['tutorialWindows'] && localStorage['tutorialWindows'].split(',') || [];

    if (tutorialWindows.includes(gameCode)) return;
    tutorialWindows.push(gameCode);
    localStorage.setItem(tutorialWindowsKey, tutorialWindows.join(','));
  }

  return (
 
      <CardWrapper className={isMobile ? 'mobile' : ''}>
        {games.map((game) => (
          <CardStyled key={`games-list-${game.id}`}>
            <CardMedia image={`/images/common/logo/logo-game-${game.code || 'Scopa'}.png`} title={game.name} />
            <CardContent >
              <Typography className='text-center min-h-[44px] break-words' gutterBottom variant="h5" component="h4">
                {t(game.name)}
              </Typography>
            </CardContent>
            <CardActions className='game-select-btn-grp' sx={{ p: '0px 12px 12px', gap: '6px' }}>
              <ButtonComponent
                className='!mx-auto !my-0'
                sx={{ maxWidth: 'unset !important', width: '100%' }}
                variant="contained"
                title={t('Play')}
                onClick={() => onClickButton(game)}
              />
              <TutorialButton
                className='tutorial-btn !mx-auto !my-0'
                variant="outlined"
                icon={
                  <Tooltip title={t('Tutorial')} placement='top'>
                    <MenuBook sx={{ color: '#0066FF' }} />
                  </Tooltip>
                }
                onClick={() => openTutorial(game.code)}
              />
            </CardActions>
          </CardStyled>
        ))}
      </CardWrapper>
   
  );
};
