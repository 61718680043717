
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { introStep, introStepMobile } from 'utils/intro';
import { getI18n } from 'react-i18next';
import Cookies from 'js-cookie'

const loadTimeout2 = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, 200);
    });
}

const loadTimeout3 = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, 300);
    });
}

const lockScreen = () => {

    const body = document.querySelector('body');
    body.scrollTo({ top: 0, behavior: 'smooth' })
    window.scrollTo({ top: 0, behavior: 'smooth' })
    body?.classList.add('overflow-hidden')

}
const unlockScreen = () => {
    const body = document.querySelector('body');
    body?.classList.remove('overflow-hidden')
}

const handleStep = (step: number, t: any) => {
    if (step === 0) {
        let skipButton = document.querySelector('.introjs-skipbutton');
        if (skipButton) {
            skipButton.setAttribute('style', 'display:none');
        }
        const dontShow = document.querySelector('.introjs-dontShowAgain'); {
            if (dontShow) {
                dontShow.setAttribute('style', 'display:block');
            }
        }
        const backButton = document.querySelector('.introjs-prevbutton');
        if (backButton) {
            backButton.innerHTML = t('Close')
        }
    }
    else {

        let skipButton = document.querySelector('.introjs-skipbutton');
        if (skipButton) {
            skipButton.setAttribute('style', 'display:block');
        }
        const dontShow = document.querySelector('.introjs-dontShowAgain'); {
            if (dontShow) {
                dontShow.setAttribute('style', 'display:none');
            }
        }
        const backButton = document.querySelector('.introjs-prevbutton');
        if (backButton) {
            backButton.innerHTML = t('Back')
        }
    }
    if (step === 17) {
        let skipButton = document.querySelector('.introjs-skipbutton');
        if (skipButton) {
            skipButton.setAttribute('style', 'display:none');
        }
    }
}

export function tour(cb: () => void) {
    const { t } = getI18n()
    const introStepTranslate = introStep.map((item) => {
        return {
            ...item,
            intro: t(item.intro),
        }
    })

    const intro = introJs();
    let step = 0;
    let back = false;

    setTimeout(() => {
        intro.addSteps(introStepTranslate);
        intro.setOptions({
            nextToDone: true,
            dontShowAgain: true,
            exitOnEsc: false,
            showBullets: false,
            exitOnOverlayClick: false,
            dontShowAgainLabel: t("Don't show again"),
            nextLabel: t('Next'),
            prevLabel: t('Close'),
            doneLabel: t('Close'),
            dontShowAgainCookieDays: 0,
            disableInteraction: false,
        });


        intro.start().then((res) => {
            lockScreen();
            let skipButton = document.querySelector('.introjs-skipbutton');
            if (skipButton) {
                skipButton.setAttribute('style', 'display:none');
            }
            const header = document.querySelector('.introjs-tooltip-header');
            if(step === 0) {
                header.classList.add('tour-header');
            } else {
                header.classList.remove('tour-header');
            }

            const prevButton = document.querySelector('.introjs-prevbutton');

            prevButton.addEventListener('click', async () => {
                if (step === 0) {
                    intro.exit(true)
                }

                if (step > 0) {
                    step--;
                    back = true;
                    if (step === 12) {
                        const closeButton = document.querySelector('.drawer-header-close') as HTMLElement;
                        if (closeButton) {
                            closeButton?.click()
                        }
                    }

                    if (step === 3) {
                        const closeBtn = document.querySelector('.modal-close') as HTMLElement;
                        
                        if (closeBtn) {
                            closeBtn?.click()
                        }
                    }
                }
            })
            const nextButton = document.querySelector('.introjs-nextbutton');
            nextButton.addEventListener('click', () => {
                step++;
                back = false;
            })
        });
        intro.oncomplete(() => {
            Cookies.remove('introjs-dontShowAgain')
            unlockScreen()
            cb()
        });
        intro.onexit(() => {
            Cookies.remove('introjs-dontShowAgain')
            unlockScreen()
            cb()
        });

        intro.onbeforechange(async function (targetElement: Element) {

            await loadTimeout2();
            handleStep(step, t);
            const header = document.querySelector('.introjs-tooltip-header');
            if(step === 0) {
                header.classList.add('tour-header');
            } else {
                header.classList.remove('tour-header');
            }
            
            if (step === 4) {
                const ele = document.querySelector('.change-game-button') as HTMLElement;
                if (ele) {
                    ele?.click()
                    await loadTimeout2();
                    intro._introItems[4].element = document.querySelector(introStepTranslate[4].element) as any;
                    intro._introItems[4].position = introStepTranslate[4].position;
                }
            }

            if (step === 8) {
                const ele = document.querySelector('.button-action-icon') as HTMLElement;
                await ele.click();
                await loadTimeout2();
                intro._introItems[8].element = document.querySelector(introStepTranslate[8].element) as any;
                intro._introItems[8].position = introStepTranslate[8].position;
            }

            if ((step === 10 && !back) || (step === 11 && back)) {
                const ele = document.querySelector('.chat-button') as HTMLElement;
                await ele.click();
                await loadTimeout3();
                for (let i = 10; i <= 11; i++) {
                    intro._introItems[i].element = document.querySelector(introStepTranslate[i].element) as any;
                    intro._introItems[i].position = introStepTranslate[i].position;
                }
            }
            
            if(step === 11){
                const classLobby = document.querySelector('.chat-room-Lobby') as HTMLElement;
                classLobby.classList.remove('Mui-selected');
                const classHelpDesk = document.querySelector('.chat-room-Helpdesk') as HTMLElement;
                classHelpDesk.classList.add('Mui-selected');
            } else {
                const classLobby = document.querySelector('.chat-room-Lobby') as HTMLElement;
                if(classLobby) {
                    classLobby.classList.add('Mui-selected');
                    const classHelpDesk = document.querySelector('.chat-room-Helpdesk') as HTMLElement;
                    classHelpDesk.classList.remove('Mui-selected');
                }
            }

            if (step === 13 && !back) {
                const ele = document.getElementById('header-user');
                await ele.click();
                await loadTimeout3();
                for (let i = 12; i <= 17; i++) {
                    intro._introItems[i].element = document.querySelector(introStepTranslate[i].element) as any;
                    intro._introItems[i].position = 'bottom';
                }
            }
            return true;
        });

        intro.onafterchange(async function () {
            const header = document.querySelector('.introjs-tooltip-header');
            if(step === 0) {
                header.classList.add('tour-header');
            } else {
                header.classList.remove('tour-header');
            }
            if (step === 5 && !back) {
                const closeBtn = document.querySelector('.modal-close') as HTMLElement;
                if (closeBtn) {
                    closeBtn?.click()
                }
            }
            if ((step === 9) || (step === 7 && back) || (step === 12 && !back)) {
                const closeBtn = document.querySelector('.button-close') as HTMLElement;
                if (closeBtn) {
                    closeBtn?.click()
                }
            }
        })

    }, 800);
}

export function tourMobile(cb: () => void) {
    const { t } = getI18n()
    const introStepMobileTranslate = introStepMobile.map((item) => {
        return {
            ...item,
            intro: t(item.intro),
        }
    })

    const intro = introJs();
    let step = 0;
    let back = false;

    window.addEventListener("orientationchange", function () {
        setTimeout(() => {
            let currentStep = intro._currentStep + 1;
            for (let i = step; i <= 17; i++) {
                let element = document.querySelector(introStepMobileTranslate[i]?.element) as HTMLElement;
                if (element) {
                    intro._introItems[i].element = element;
                    intro._introItems[i].position = introStepMobileTranslate[i].position;
                    if(i === 17) {
                        intro.goToStep(currentStep);
                    }
                }
            }
            intro.refresh();
        }, 1000);
    });
    
    setTimeout(() => {
       
        intro.setOptions({
            nextToDone: true,
            dontShowAgain: true,
            exitOnEsc: false,
            showBullets: false,
            exitOnOverlayClick: false,
            dontShowAgainLabel: t("Don't show again"),
            nextLabel: t('Next'),
            prevLabel: t('Close'),
            doneLabel: t('Close'),
            dontShowAgainCookieDays: 0,
            disableInteraction: false,
            scrollToElement: false,
            steps: introStepMobileTranslate,
        });

        intro.start().then((res) => {
            lockScreen();
            let skipButton = document.querySelector('.introjs-skipbutton');
            if (skipButton) {
                skipButton.setAttribute('style', 'display:none');
            }

            const header = document.querySelector('.introjs-tooltip-header');
            if(step === 0) {
                header.classList.add('tour-header');
            } else {
                header.classList.remove('tour-header');
            }
            
            const prevButton = document.querySelector('.introjs-prevbutton');

            prevButton.addEventListener('click', async () => {
                if (step === 0) {
                    intro.exit(true)
                }

                if (step > 0) {
                    if (step === 1) {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                        });
                    }

                    if (step > 0) {
                        step--;
                        back = true;
                        if (step === 12) {
                            const closeButton = document.querySelector('.drawer-header-close') as HTMLElement;
                            if (closeButton) {
                                closeButton?.click()
                            }
                        }

                        if (step === 3) {
                            const closeBtn = document.querySelector('.modal-close') as HTMLElement;
                            
                            if (closeBtn) {
                                closeBtn?.click()
                            }
                        }
                    }
                }
            })
            const nextButton = document.querySelector('.introjs-nextbutton');
            nextButton.addEventListener('click', () => {
                step++;
                back = false;
            })
        });
        intro.oncomplete(() => {
            Cookies.remove('introjs-dontShowAgain')
            unlockScreen()
            cb();
            window.removeEventListener("orientationchange", function () { })
        });
        intro.onexit(() => {
            Cookies.remove('introjs-dontShowAgain')
            unlockScreen()
            cb();
            window.removeEventListener("orientationchange", function () { })
        });
        intro.onbeforechange(async function (targetElement: Element) {
            await loadTimeout2();
            if (step === 4 || step === 5) {
                window.scrollTo({ top: 0 });
                await loadTimeout2();
            }

            handleStep(step, t)

            if (step === 4) {
                const ele = document.querySelector('.change-game-button') as HTMLElement;
                if (ele) {
                    ele?.click()

                    await loadTimeout2();

                    intro._introItems[4].element = document.querySelector(introStepMobileTranslate[4].element) as any;
                    intro._introItems[4].position = introStepMobileTranslate[4].position;
                }
            }

            if (step === 8) {
                const ele = document.querySelector('.button-action-icon') as HTMLElement;
                await ele.click();
                await loadTimeout2();
                intro._introItems[8].element = document.querySelector(introStepMobileTranslate[8].element) as any;
                intro._introItems[8].position = introStepMobileTranslate[8].position;
            }

            if ((step === 10 && !back) || (step === 11 && back)) {
                const ele = document.querySelector('.chat-button') as HTMLElement;
                await ele.click();
                await loadTimeout2();
                for (let i = 10; i <= 11; i++) {
                    intro._introItems[i].element = document.querySelector(introStepMobileTranslate[i].element) as any;
                    intro._introItems[i].position = introStepMobileTranslate[i].position;
                }
            }

            if(step === 11){
                const classLobby = document.querySelector('.chat-room-Lobby') as HTMLElement;
                classLobby.classList.remove('Mui-selected');
                const classHelpDesk = document.querySelector('.chat-room-Helpdesk') as HTMLElement;
                classHelpDesk.classList.add('Mui-selected');
            } else {
                const classLobby = document.querySelector('.chat-room-Lobby') as HTMLElement;
                if(classLobby) {
                    classLobby.classList.add('Mui-selected');
                    const classHelpDesk = document.querySelector('.chat-room-Helpdesk') as HTMLElement;
                    classHelpDesk.classList.remove('Mui-selected');
                }
            }

            if (step >= 14) {
                intro._options.scrollToElement = true;
            }
            else {
                intro._options.scrollToElement = false;
            }

            if (step === 13 && !back) {
                const ele = document.getElementById('header-user');
                await ele.click();

                await loadTimeout2();

                for (let i = 11; i <= 17; i++) {
                    intro._introItems[i].element = document.querySelector(introStepMobileTranslate[i].element) as any;
                    intro._introItems[i].position = introStepMobileTranslate[i].position;
                }

            }
            return true;
        });

        intro.onafterchange(async function () {
            const header = document.querySelector('.introjs-tooltip-header');
            if(step === 0) {
                header.classList.add('tour-header');
            } else {
                header.classList.remove('tour-header');
            }
            
            if (step === 5 && !back) {
                const closeBtn = document.querySelector('.modal-close') as HTMLElement;
                if (closeBtn) {
                    closeBtn?.click()
                }
            }
            if ((step === 9) || (step === 7 && back) || (step === 12 && !back)) {
                const closeBtn = document.querySelector('.drawer-header-close') as HTMLElement;
                if (closeBtn) {
                    closeBtn?.click()
                }
            }
        })
    }, 800);
}

export function forceCloseTour() {
    const intro = introJs();
    intro.exit(true);
    unlockScreen();
}

