import { Popover } from "@mui/material";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { CloseButton } from "shared/ButtonsCollection";
import { FlexBox } from "shared/Flex";
import { OngoingMatches } from "shared/components/match-switcher/component/match-switcher-table";

type MatchSwitcherDesktopProps = {
    onClose: () => void;
    isOpened: boolean;
}

const MatchSwitcherDesktop = ({ onClose, isOpened }: MatchSwitcherDesktopProps) => {
    const { t } = useTranslation();

    return (
        <Popover
          onClose={onClose}
          open={isOpened}
          className={`match-switch ${isMobile ? 'mobile' : 'desktop'}`}
          disableAutoFocus
          disableEnforceFocus
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className='popover-header'>
            <span className="flex-1 text-center">{t('Your tables')}</span>
            <FlexBox className='popover-header-action' alignItems={'center'}>
              <CloseButton onClick={onClose}/>
            </FlexBox>
          </div>
          <div className="match-switch-main">
            <OngoingMatches playCb={onClose} />
          </div>
        </Popover>
    )
}
export default MatchSwitcherDesktop