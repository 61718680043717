import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Container, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { SearchInput } from '../../shared/Filters';
import { chipFullType, cashAndSit, skillGames, tournamentAndSit, REGULATION_URL } from '../../constants/common';
import { SKIN } from 'constants/skin';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './index.scss';
import {
  SoundManager,
  Sound,
  selectActiveGame,
  Game,
  GameHelper,
  selectCasino,
  IClubSettings,
  selectUserClubs,
  gameActions,
  clubApi,
  tournamentActions,
  selectFilterOptions,
  selectCashFilterOptions,
  cashTableActions,
} from '@quanticogames/gameclient-core';
import { useDispatch } from 'react-redux';
import { NAV_LINKS_SKILL_GAMES, NAV_LINK_CASH_GAMES, SIT_AND_CASH, OTHER_GAMES, TOURNAMENT_AND_SIT } from 'constants/router';
import { FlexBox } from 'shared/Flex';
import { FilterIcon } from 'assets/icons/FilterIcon';
import { PATH_ROUTES } from 'constants/routes';
import FilterCashTable from 'layouts/control-panel/component/filter-cashtable';
import { InfoOutlined } from '@mui/icons-material';
import { ClubIcon } from 'assets/icons/ClubIcon';
import FilterTournament from './component/filter-tournament';
import { useLocation } from 'react-router-dom';
import { lobbyUrlKey } from 'constants/localStorage';

const Root = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  '.active': {
    svg: {
      path: {
        fill: '#fff',
      },
    },
  },
}));

const Text = styled('span')(() => ({
  marginLeft: '8px',
  fontSize: '0.875rem',
}));

export const ControlPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const skinType: string = useSelector(selectCasino) || 'quantico';
  const isMobile: boolean = GameHelper.isMobile();
  const theme = useTheme();
  const isMobileScreenWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const game: Game = useSelector(selectActiveGame);
  const userClubs: any = useSelector(selectUserClubs);
  const isCashTable = window.location.href.includes(PATH_ROUTES.CASH_TABLE);
  const filterOptions = useSelector(selectFilterOptions);
  const filterCashOptions = useSelector(selectCashFilterOptions);

  useEffect(() => {

    let numberOfFilters = 0;
    if (isCashTable) {
      for (let key in filterCashOptions) {
        if ((key === 'maxPlayers' || key === 'costRanges' || key == 'status' || key === 'timeSpeeds') && filterCashOptions[key].length > 0) {
          numberOfFilters += 1;
        }
        // @ts-ignore
        if (key === 'state' && filterCashOptions[key] !== false && filterCashOptions[key] !== undefined) {
          numberOfFilters += 1;
        }
      }
    }
    else {
      for (let key in filterOptions) {
        if ((key === 'maxPlayers' || key === 'costRanges' || key == 'status' || key === 'timeSpeeds' || key == 'objectiveValues' || key === 'matchTypes' || key === "timeSpeed") && filterOptions[key].length > 0) {
          console.log('run', filterOptions[key])
          numberOfFilters += 1;
        }
        // @ts-ignore
        if (key === 'state' && filterOptions[key] !== false && filterOptions[key] !== undefined) {
          numberOfFilters += 1;
        }
      }
      
    }
    setFilterApplies(numberOfFilters)

  }, [filterOptions, filterCashOptions, isCashTable]);
  const [showModalFilter, setShowModalFilter] = useState<boolean>(false);

  const location = useLocation();
  const isDisableFilter = location.pathname.includes(PATH_ROUTES.CRAZY_PRIZE) || location.pathname.includes(PATH_ROUTES.FILOTTO) || location.pathname.includes(PATH_ROUTES.CLUB)

  const [hideClub, setHideClub] = useState<boolean>(false)
  const [filterApplies, setFilterApplies] = useState<number>(0);

  let navLinkDataActive = [];

  if (skillGames.includes(game?.code)) {
    navLinkDataActive = NAV_LINKS_SKILL_GAMES;
  } else if (chipFullType.includes(game?.code)) {
    navLinkDataActive = NAV_LINK_CASH_GAMES;
  } else if (cashAndSit.includes(game?.code)) {
    navLinkDataActive = SIT_AND_CASH;
  } else if (tournamentAndSit.includes(game?.code)) {
    navLinkDataActive = TOURNAMENT_AND_SIT;
  }
  else {
    navLinkDataActive = OTHER_GAMES;
  }

  useEffect(() => {
    if (game?.code === 'Trentuno' || game?.code === 'GinRummy') {
      setHideClub(true)
    }
    else {
      setHideClub(false)
    }
  }, [game?.code]);

  useEffect(() => {
    const getUserClubs = async (id: number = null) => {
      try {
        const data: IClubSettings[] = await clubApi.getListClub(game?.id)
        dispatch(gameActions.updateClubsInfo(data.map((club, index) => {
          return {
            id: club.id,
            clubSetting: club,
            active: index === 0,
            tournamentAAMS: club?.tournamentAAMS,
            tournamentCode: club?.tournamentCode
          }
        })))
      }
      catch (error) {
        console.log(error)
      }
    }

    if (game?.id) {
      getUserClubs()
    }
  }, [game?.id]);


  return (
    <div className="header-tabs">
      <Container>
        <Root className="header-tabs-container">
          {(!isMobileScreenWidth && !isMobile) && (
            <div className="nav-link-game-mode items-center flex gap-[28px] header-menu">
              {navLinkDataActive.map((item, index) => (
                <NavLink
                  key={`nav-link-${index}`}
                  to={item.link}
                  className="header-menu-link"
                  onClick={() => {
                    localStorage.setItem(lobbyUrlKey, window.location.origin + item.link);
                  }}
                >
                  {item.icon}
                  <Text>{t(item.title)}</Text>
                </NavLink>
              ))}
              {!hideClub && <NavLink
                to={PATH_ROUTES.CLUB}
                className="header-menu-link club-menu-link"
                onClick={() => {
                  localStorage.setItem(lobbyUrlKey, window.location.origin + PATH_ROUTES.CLUB);
                }}
              >
                <ClubIcon />
                <Text>{t('Club')}</Text>
                {userClubs.length > 0 && <div className="club-count">
                  <p className='mr-[3px] text-[14px] font-bold'>
                    {userClubs.length}
                  </p></div>}
              </NavLink>}
            </div>
          )}
          <Stack
            spacing={(isMobile || isMobileScreenWidth) ? 3 : 6}
            direction='row'
            alignItems='center'
            justifyContent={(isMobile || isMobileScreenWidth) ? 'space-between' : 'flex-end'}
            sx={{ padding: '5px 0', width: '100%' }}
          >
            {(isMobile || isMobileScreenWidth) ? (
              <FlexBox alignItems='center' gap={10}>
                {isMobileScreenWidth && (
                  <a
                    onClick={() => SoundManager.play(Sound.clickButton)}
                    href={`${REGULATION_URL}/game?tabs=${game?.code}&casino=${skinType}`}
                    className="question-icon flex items-center text-[#fff]"
                  >
                    <InfoOutlined color={SKIN[skinType].colorNameUser} style={{ width: 26, height: 26 }} />
                  </a>
                )}
                <div className='filter-mobile'>
                  
                  <button onClick={() => {
                    if (isDisableFilter) return;
                    setShowModalFilter(true);
                  }} className={`filter-btn !min-w-[0] ${isDisableFilter ? 'filter-btn-disabled' : ''}`}>
                    {filterApplies > 0 &&
                      <>
                        <p className='symbol'>{filterApplies}</p>
                      </>
                    }
                    <FilterIcon />
                  </button>
                
                </div>

                {isCashTable ? (
                  <FilterCashTable open={showModalFilter} onClose={() => setShowModalFilter(false)} />
                ) : (
                  <FilterTournament open={showModalFilter} onClose={() => setShowModalFilter(false)} />
                )}
              </FlexBox>
            ) : (
              <div className='filter-desktop'>
                <button onClick={() => {
                  if (isDisableFilter) return;
                  setShowModalFilter(true);
                }} className={`filter-btn-desktop ${isDisableFilter ? 'filter-btn-desktop-disabled' : ''}`}>
                  <FilterIcon />
                </button>
                {filterApplies > 0 &&
                  <>
                    <p className='symbol'>{filterApplies}</p>
                  </>
                }
                {isCashTable ? (
                  <FilterCashTable open={showModalFilter} onClose={() => setShowModalFilter(false)} />
                ) : (
                  <FilterTournament open={showModalFilter} onClose={() => setShowModalFilter(false)} />
                )}
              </div>
            )}
            <SearchInput />
          </Stack>
        </Root>
      </Container>
    </div>
  );
};
