import React, { useEffect } from 'react';
import LayoutPage from 'layouts/layout-page';
import { TournamentTable } from 'pages/tournament/component/list/tournament-table';
import { TOURNAMENT_TYPE } from 'constants/common';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import {
  Game,
  selectActiveGame,
  selectOrientation,
  selectReloadTournaments,
  tournamentActions,
} from '@quanticogames/gameclient-core';

interface TournamentPageProps {
  type?: TOURNAMENT_TYPE;
  types?: string[];
}

export const TournamentPage: React.FC<TournamentPageProps> = ({ type, types }) => {
  const dispatch = useDispatch();
  const activeGame: Game = useSelector(selectActiveGame);
  const reloadTournaments: boolean = useSelector(selectReloadTournaments);
  const typeNew = type === TOURNAMENT_TYPE.CRAZY_PRIZE || type === TOURNAMENT_TYPE.TREBLE
  const orientation = useSelector(selectOrientation);
  
  useEffect(() => {
    if (activeGame?.id) {
      dispatch(
        tournamentActions.loadTournaments({
          gameId: activeGame?.id,
          isOnlyInSubcription: false,
          ...(type === TOURNAMENT_TYPE.SIT_AND_GO ? { types } : { type }),
        } as any)
      );
    }
  }, [activeGame?.id, type]);

  useEffect(() => {
    dispatch(tournamentActions.updateTournamentType(type))
  }, [type]);

  useEffect(() => {
    if (reloadTournaments && activeGame?.id) {
      dispatch(
        tournamentActions.loadTournaments({
          gameId: activeGame?.id,
          isOnlyInSubcription: false,
          ...(type === TOURNAMENT_TYPE.SIT_AND_GO ? { types } : { type }),
        } as any)
      );
      dispatch(tournamentActions.reloadTournaments({ state: false }));
    }
  }, [reloadTournaments]);

  const scaleScreenTournament = () => {
    setTimeout(() => {
      const wrapperEl = document.querySelector('.header-tabs-container') as HTMLElement;
      const tournamentContainer = document.querySelector('.tournament-container') as HTMLElement;
      const tournamentType = document.querySelector('.tournament-type-container') as HTMLElement;
      const clientWidth = wrapperEl?.clientWidth;
      const clientHeight = document.querySelector('.main-container')?.clientHeight;
      const elW = 1176;
      const elH = 580;
      let w;
      let h = 0;
      if (clientWidth > 900) {
        w = 0;
      } else {
        w = 8
      }
      if (clientHeight < 750) {
        h = 8;
      }
      if (tournamentType && clientWidth > 500) {
        const scaleW = (clientWidth - w) / elW;
        const scaleH = (clientHeight - h) / elH;
        const isUseHScale = orientation === 'desktop' && scaleW > scaleH;
        const usedScale = isUseHScale ? scaleH : scaleW;
        tournamentType.style.transform = `scale(${(usedScale).toFixed(3)})`;
        const nowWidth = tournamentType.clientWidth * usedScale;
        const layoutWidth = document.getElementById('layout-page').clientWidth;
        const transformWidth = (layoutWidth - nowWidth);
        tournamentType.style.transformOrigin = '0px 0px'
        tournamentType.style.marginLeft = `${transformWidth / 2}px`;
      } else if (tournamentContainer) {
        tournamentContainer.style.transform = '';
        tournamentContainer.style.transformOrigin = "top center";
        tournamentContainer.style.marginLeft = '';
      }
    }, 50);
  };

  useEffect(() => {
    scaleScreenTournament();
    const handleResize = () => {
      scaleScreenTournament();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.location.href, orientation]);

  return (
    <LayoutPage>
      <div className={`tournament-container tournament-${type} ${typeNew ? 'tournament-type-container' : ''}`}>
        <TournamentTable type={type} types={types} />
      </div>
    </LayoutPage>
  );
};
